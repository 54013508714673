.crs-hero {
    min-height: 390px;
    background-size: cover;
    position: relative;
    
    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1 {
            font-size: 54px;
        }
    }
}

.csr-contents {
    background-color: #F3F5F7;
}

.csr-card {
    border-radius: 20px;
    background: #FFFFFF;
    padding: 10px;
    width: 100%;
    height: 100%;

    img {
        border-radius: 10px;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2em;
        margin-bottom: 10px;
        color: black;
        text-wrap: balance;
    }

    .content-description {
        
    }

    .content-date {
        font-size: 14px;
        color: #606060;
    }
}
@media (max-width: 480px) {
    .crs-hero {
        min-height: 119px;
    }
    .individual-csr-content{
        text-align: center;
        .calendar{
            justify-content: center;
        }
    }
}