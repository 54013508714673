.mayo-health-library {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-bottom: 60px;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    &__title {
        font-size: 28px;
        font-weight: 700;
        color: var(--primary-color);
        text-align: center;
        margin-top: 40px;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    &__description {
        font-size: 16px;
        text-align: center;
        margin-bottom: 40px;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            font-size: 14px;
        }
    }

    .health-item {
        display: flex;
        align-items: flex-start;
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease-in-out;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
        }

        &:hover {
            transform: translateY(-5px);
        }

        .health-image {
            width: 300px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 15px;

            @media screen and (max-width: 768px) {
                width: 100%;
                height: 200px;
                margin-right: 0;
            }
        }

        .health-text {
            h3 {
                font-size: 24px;
                color: #003366;
                margin: 0;

                @media screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }

            p {
                font-size: 16px;
                color: #555;
                margin: 5px 0 0;

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
    }
}
