.individual-video-information-section {
    padding: 50px 0;
    .back_to_videos {
        margin-bottom: 30px;
    }
    .video-container {
        position: relative;
        width: 100%;
        /* 100% width for mobile and desktop */
        padding-bottom: 56.25%;
        /* Aspect ratio of 9:16 (height/width * 100) */
    }
    /* CSS for the embedded iframe */
    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .back_to_videos {
        a {
            color: #39B5B2;
            font-size: 18px;
            font-weight: 600;
        }
        svg {
            margin-right: 10px;
            color: #39B5B2;
        }
    }
    .doctor-group {
        display: flex;
        align-items: center;
        .doctor-name {
            font-style: italic;
            color: #666567;
        }
        .doctor-img {
            width: 40px;
            margin-right: 10px;
            img {
                border-radius: 50%;
            }
        }
    }
    .doctor-calender-date {
        display: flex;
        align-items: center;
        height: 100%;
        span.doctor-calender {
            margin-right: 10px;
        }
    }
    .video-content-2 {
        p {
            line-height: 20.28px;
            margin: 30px 0;
        }
        h3 {
            margin: 30px 0;
            color: black;
        }
        button {
            background: #D5C8B9;
            padding: 5px 20px;
            border-radius: 20px;
            border: 0;
            font-weight: 400;
        }
    }
}
.individual-health-package-other-section {
    background-color: #F3F5F7;

    .container {
        .title {
            display: flex;
            justify-content: space-between;
            position: relative;
            p {
                color: var(--primary-color);
                font-weight: 700;
            }
            a {
                position: relative;
            }

            .individual_video_health_package_slider_1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                h4 {
                    color: black;
                    font-size: 25px;
                    font-weight: 700;
                    margin-bottom: 30px;
                }
            }
        }

        .slider-container-packages {
            .slick-track {
                position: relative;
                top: 0;
                left: 0;
                display: block;
                margin-left: unset;
                margin-right: unset;
            }
        }
    
        .health-package-card {
            margin-left: 0;
            margin-right: 10px;
        }
       
        .health-package-card-group {
            display: flex;
            column-gap: 20px;
    
            overflow-x: auto;
            overflow-y: hidden;
            -ms-overflow-style: none; 
    
            cursor: -webkit-grabbing;
    
            &::-webkit-scrollbar {
                display: none;
            }  
        }

    }

}


.individual-doctor-other-section {
    background-color: #F3F5F7;
    padding: 50px 0;

    .title {
        display: flex;
        justify-content: space-between;
        position: relative;
        p {
            color: var(--primary-color);
            font-weight: 700;
        }
        a {
            position: relative;
        }
    }
    .doctor-group {
        display: flex;
        column-gap: 20px;
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        // cursor: -webkit-grabbing;

        &::-webkit-scrollbar {
            display: none;
        } 
    }

    .find-a-doctors-card {
        // max-width: fit-content;
        // margin-right: 10px;
    }
}

.individual-video-other-section {
    .slider-container-videos {
        .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin-left: unset;
            margin-right: unset;
        }
    }
    .health-package-card {
        margin-left: 0;
        margin-right: 10px;
    }
    .title {
        display: flex;
        justify-content: space-between;
        position: relative;
        // left: 8%;
        p {
            color: var(--primary-color);
            font-weight: 700;
        }
        a {
            position: relative;
            // right: 7%;
        }
    }
    .video-other-group {
        display: flex;
        flex-direction: row;
        column-gap: 20px;

        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none; 

        cursor: -webkit-grabbing;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    background-color: #EBEDEF;
    padding: 50px 0px;
    .title {
        display: flex;
        justify-content: space-between;
        p {
            color: var(--primary-color);
            font-weight: 700;
        }
        a {
            position: relative;
        }
    }
    .my_collections_videos_card {
        background-color: #fff;
        padding: 5px;
        border-radius: 20px;
        // width: 97%;
        // margin-right: 10px;
    }
}


@media screen and (max-width: 768px) {

    .individual-doctor-other-section {
        padding: 40px;
        .title,
        .doctor-group {
          left: 0;
          overflow: auto !important;
        }
        .find-a-doctors-card {
          width: 200px;
        }
    }

    .individual-video-information-section {
        .video-content-1 {
            padding-top: 4%;
        }
        .video-content-2 {
            button {
                margin-bottom: 10px;
            }
        }
    }

    .individual-health-package-other-section {
        padding: 40px;
        .title,
        .health-package-card-group {
            left: 0;
            overflow: auto !important;
        }
    }

    .individual-video-other-section {
        padding: 40px;
        .title,
        .video-other-group {
            left: 0;
            overflow: auto !important;
        }
    }
}

@media screen and (min-width: 481px) {
    .individual-video-information-section .individual_video_mobile {
        display: none;
    }

    .individual-health-package-other-section .individual_video_health_package_slider {
        display: none !important;
    }

    .individual-video-other-section .individual_video_video_slider {
        display: none;
    }

    .individual-doctor-other-section .learn_more_mob_view {
        display: none !important;
    }

    .individual-doctor-other-section .mobile_view {
        display: none !important;
    }
}

@media screen and (max-width: 480px) {
    .individual-video-information-section .individual_video_normal {
        display: none;
    }

    .individual-health-package-other-section .individual_video_health_package_slider_1 {
        display: none !important;
    }

    .individual-doctor-other-section .learn_more_web_view {
        display: none !important;
    }

    .individual-doctor-other-section .doctor-group {
        display: none !important;
    }

    .individual-video-information-section .back_to_videos {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .individual-health-package-other-section, .individual-video-other-section {
        padding: 40px 10px !important;
    }

    .individual-health-package-other-section .individual_video_health_package_slider {
        .slick-dots {
            position: absolute;
            bottom: -27px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }
    }

    .individual-video-other-section .my_collections_videos_card {
        width: 100%;
    }

    .individual-video-other-section .individual_video_video_slider {
        .slick-dots {
            position: absolute;
            bottom: -26px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }
    }

    .individual-doctor-other-section .find-a-doctors-card {
        width: fit-content !important;
        margin-top: 0;
    }
}

