.individual_specialist_hero_section{
    background: url("./../img/slider/slider-bg-1.jpg");
    background-repeat: no-repeat;
    min-height: 300px;
    background-size: 100%;

    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: inherit;

        #back_to_btn{
            border: 0;
            background-color: transparent;
            color: #967F65;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.content_sidebar_section_main {
    display: flex;

    .content_sidebar_section{
        flex: 1;
        width: 25%;
        // height: 100%;
        background-color: rgb(243, 245, 247);
    
        .container{
            padding: 50px 0;
            padding-left: 70px;
    
            ul{
                width: 100%;
                li{
                    font-size: 18px;
                    color: black;
                    cursor: pointer; 
    
                    // &.active::before {
                    //     content: "•"; /* Add the content you want before the active element */
                    //     color: #967F65;
                    // }
    
                    &.active {
                    font-size: 22px;
                    font-weight: 700;
                    color:#967F65;
                    margin-left: 30px;
                    }
    
                    .dropdown_admission{
                        display: block;
    
                        li{
                            color: black;
                            font-size: 14px;
                            cursor: pointer;
                            font-weight: 400;
                            margin-left: 0;
                            
                            &.active {
                                color:#967F65;
                                
                            }
                        }
                    }      
                }
            }
        }
    }
}


.content_content_section{
    flex: 2;
    width: 75%;
    float: right;
    height: 100%;

    .container{
        padding: 50px;
    }
}

@media (min-width: 481px) {
    
}


@media (max-width: 480px) {
    .individual_specialist_hero_section {
        min-height: 200px;
        background-size: cover;
        background-position: center;
    }

    .content_sidebar_section_main {
        display: flex;
        flex-direction: column;

        .content_sidebar_section {
            flex: 1 1;
            width: 100%;
            height: fit-content;
            background-color: #f3f5f7;
        }
    }

    .content_content_section {
        width: 100%;
    }
}