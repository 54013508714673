.kpj-footer {
  background-color: #967F65;
  padding-top: 50px;
  padding-bottom: 70px;
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;

  .container {
    display: flex;
    height: 100%;
    position: relative;

    .content-desktop-view{
      position: relative;
    }

    .content,
    .content-desktop-view {
      border-right: 1px solid white;
      margin-inline: auto;
      padding-right: 2%;  
      padding-left: 2%;

      &:nth-child(1){
        padding-left: 0;
      }

      &:nth-child(4) {
        border-right: 0px !important;
        padding-right: 0;
      }

      &:nth-child(5) {
        border-right: 0px !important;
        padding-right: 0;
        padding-left: 0;

      }

      ul li {
        font-size: 13px;
        color: white;
        margin-bottom: 5px;
        font-weight: 400;
        width: 180px;

        // &:hover {
        //   text-decoration: underline;
        // }
        
        cursor: pointer;

        i {
          padding-right: 10px;
        }

        a {
          color: white;
          font-size: 13px;
          font-weight: 400;
        }
      }

      ul li p {
        font-size: 13px;
        font-weight: 400;
        color: white;
        margin-bottom: 0;
        margin-left: 10px;
      }

      h1 {
        font-size: 16px;
        color: white;
        font-weight: 700;
        margin-bottom: 15px;
        line-height: 1.1;
      }
    }
  }
}
.navigation-bottom {
  height: 50px;
  bottom: -1px;
  background: white;
  width: 100vw;
  z-index:2;
  position: fixed;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transition: height 0.4s ease-out;

  .redirection_bottom {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    transition: justify-content 1s ease-out;

    .redirection_cube {
      display: flex;
      flex-direction: column;
      width: 50px;
      align-items: center;
      text-align: center;

      span {
        display: none ;
      }

      .redirection_cube_img {
        padding-bottom: 10px;

        img {
          height: 25px;
        }
      }
      
    }
  }
  &:hover {
    box-shadow: 5px 10px #888888;
    height: 80px;
    .redirection_bottom{
      justify-content: space-evenly;
      transition: justify-content 1s ease-out;
      .redirection_cube {
        padding-top: 5px;
        width: auto;

        span {
          display: block;
        }
        
        img {
          height: 30px;
        }
      }
    }
  }
}

.social-icons-container {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

#mobile_image_footer {
  position: relative;
  bottom: 0px;
  right: 0px;
  height: 100%;
  padding: 0px;
  scale: 1.3;
  // max-width: none;
}

#cares_footer_web {
  position: absolute;
  scale: 0.8;
  left: 37%;
  top: 19%;
  transform: translate(-50%, -50%);
}

#applestore_web {
  scale: 0.8;
}

#googleplay_web {
  scale: 0.8;
}

.appstores-container {
  display: flex;
  position: absolute;
  top: 40%;
  left: 49%;
  transform: translate(-50%, -50%);
  width: 100%;
  justify-content: center;
}

@media (max-width: 1280px) {
  .kpj-footer .container .content-desktop-view {
    // padding-right: 30px;
  }
}

@media (max-width: 1200px) {
  #mobile_image_footer {
    display: none;
  }

  .appstores-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
}


@media (max-width: 1024px) {
  .content,
  .content-desktop-view {
    margin-bottom: 30px;
    // padding-left: 20px !important;
    // padding-right: 20px !important; 

    &:nth-child(2) {
      border-right: 1px solid white !important;
    }

    &:nth-child(3) {
      border-right: 1px solid white !important;
    }
  }

  .kpj-footer .container .content-desktop-view ul li {
    font-size: 12px;
    color: white;
    margin-bottom: 5px;
    font-weight: 400;
    cursor: pointer;
    width: 148px;
  }

  .content-desktop-view {
    img{
      right: 10px;
    }
  }

  .kpj-footer .container .content, .kpj-footer .container .content-desktop-view{
    // padding-right: 0px
  }

  #mobile_image_footer {
    right: 21px;
  }

  // #cares_footer_web {
  //   left: -7%;
  // }
  
  #applestore_web {
    // position: absolute;
    right: 10%;
    top: 90px;
    scale: 0.7;
  }
  
  #googleplay_web {
    left: -3%;
    top: 90px;
    scale: 0.7;
  }
}

@media (max-width: 1024px) {
  .kpj-footer .container .content-desktop-view:nth-child(5) {
    padding-right: 0 !important;
  }
}

@media (min-width: 769px) {
  .kpj-footer {
    .content-mobile-view {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .kpj-footer {
    padding: 30px 0px;
    height: auto;
    position: relative;

    .container {
      flex-direction: column;
      // padding: 0 25px;

      .content {
        padding-left: 0px !important;
        &:nth-child(1) {
          border-right: 0px !important;
          width: 100% !important;
        }

        span,
        p,
        i {
          font-size: 15px !important;
        }
      }

      .content-mobile-view {
        margin: 10px 0px;

        h1 {
          font-size: 15px !important;
          border-bottom: 1px solid white;
          color: white;
          padding-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 1.1;
        }

        ul li a {
          color: white;
        }

        ul li {
          color: white;
        }
      }
    }

    .content-desktop-view, .mobile-image-desktop-view {
      display: none !important;
    }

    #cares_footer_mobile {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
    }

    .store_container {
      position: absolute;
      left: 50%;
      top: 44%;
      transform: translate(-50%, -50%);
      width: max-content;
    }

    #applestore_mobile {
      margin-left: 10px;
      width: 30%;
    }
    #googleplay_mobile{
      width: 30%;
    }
  
  }

  .navigation-bottom {
    height: 90px;
    bottom: 0;
    background: white;
    width: 100vw;
    position: fixed;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: 0.6s;

    &:hover {
      box-shadow: 5px 10px #888888;
      height: 90px;
      .redirection_bottom{
        justify-content: normal;
        .redirection_cube {
          padding-top: 5px;
          width: auto;
          img {
            height: 30px;
          }
        }
      }
    }
  }

  .navigation-bottom .redirection_bottom {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;

    -ms-overflow-style: none; 

    &::-webkit-scrollbar {
        display: none;
    }
  }

  .navigation-bottom .redirection_bottom .redirection_cube {
    display: flex;
    flex-direction: column;
    width: max-content;
    padding: 0;
    margin: 0 22px;
    align-items: center;
    text-align: center;

    span {
      display: block ;
    }
  }

  .navigation-bottom .redirection_bottom .redirection_cube span {
    width: max-content;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .vertical-line{
    display: none;
  }
}

