.profile_page_welcome_section {
    background-repeat: no-repeat;
    background-size: cover;
    height: 290px;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: inherit;
        position: relative;

        h1 {
            color: black;
        }

        h4 {
            color: black;
            font-weight: 400;
        }

        .notification_setting_button{
            display: flex;
            justify-content: flex-end;
            margin-top: 60px;

            #notification-profile-icon {
                width: 30px;
                padding: 5px;
                cursor: pointer;
            }

            a{
                background-color: transparent;
                padding: 5px 30px;
                margin-left: 15px;
                border: 1px solid black;
                border-radius: 20px;
                color: black;
            }
        }

        .notification_container{
            list-style: none;
            position: relative;
            padding: 20px;
            color: white;
            border-radius: 5px;
            position: absolute;
            width: 350px;
            height: 150px;  
            right: 153px;
            bottom: -120px;
            opacity: 1;
            z-index: 999;
            background-color: white;
            overflow-y: scroll;

            li{
                display: block;
                border-bottom: 1px solid lightgray;
                padding-bottom: 10px;
                padding-top: 10px;

                &:last-child{
                    border-bottom: none;
                }

                &:first-child{
                    padding-top: 0;
                }

                #noti_title{
                    margin-bottom: 0;
                    color: black;
                    font-weight: 600;
                    font-size: 16px;
                }

                #noti_doc_name{
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 1;
                }

                #noti_date{
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 1;
                }

                #noti_time{
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 1;
                }


            }

            // &:before {
            //     content: '';
            //     position: absolute;
            //     top: -10px;
            //     left: 25%;
            //     transform: translateX(-50%);
            //     border-style: solid;
            //     border-width: 0 10px 10px 10px;
            //     border-color: transparent transparent white transparent;
            // }
        }

        .notification_container::-webkit-scrollbar {
            width: 5px;
            
        }
        
        .notification_container::-webkit-scrollbar-thumb {
            background-color: lightgray;
            border-radius: 5px;
        }
        
        .notification_container::-webkit-scrollbar-track {
            background-color: white;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

        }

    }

}

.my_appointments_section{
    padding: 100px 0px;
    background-color: rgb(243, 245, 247);
    .container{
        .my_appointments_header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            h2{
                color: black;
            }

            a{
                color: black;
                text-decoration: underline;
            }
        }
        .my_appointments_card{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 30px;
            gap: 14px;
            // justify-content: center;
        }
    }
}

.appointment_card_section{
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    min-width: 420px;
    height: fit-content;
    

    .container{

        h4{
            color: black;
            font-size: 24px;
        }

        &.has_data{
            #image_name{
                text-decoration: none;
                cursor: pointer;
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                margin-top: 30px;
                align-items: center;

                img{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 15px;
                }

                #doctor_name{
                    font-size: 18px;
                    margin-bottom: 0;
                    font-weight: 700;
                    color: gray;
                }

                #doctor_dept{
                    font-size: 14px;
                    margin-bottom: 0;
                    font-weight: 700;
                    color: gray;
                    line-height: 1.2;
                }
            }

            .date_time_container{
                display: flex;
                margin-top: 30px;
                justify-content: space-between;

                .date_box{
                    background-color: #DEF3F3;
                    border-radius: 10px;
                    padding: 10px;
                    width: 48%;

                    #date_text{
                        margin-bottom: 0;
                        color: black;
                        font-weight: 600;
                    }

                    #date_text2{
                        margin-bottom: 0;
                        color: black;
                        font-weight: 700;
                        font-size: 18px;    
                    }
                }
            }

            #view_details{
                color: black;
                text-decoration: underline;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                margin-top: 30px;
            }

        }
        .no_data{
            .error_box{
                display: flex;
                align-items: center;
                color: lightpink;

                #error_text{
                    margin-bottom: 0;
                    font-size: 13px;
                    color: lightpink;
                }
            }

            #make_an_appointment_btn{
                border: 0;
                text-align: center;
                background-color: #967F65;
                border-radius: 20px;
                margin-top: 50px;
                width: 250px;
                color: white;
                font-size: 14px;
                font-weight: 600;
                padding: 8px;
            }

        }

    }

}

.my_collections_section{
    padding: 50px 0px;
    background-color: rgb(243, 245, 247);

    .container{

        h2{
            color: black;
            margin-bottom: 30px;
        }

        .tabs_box{
            display: flex;
            justify-content: space-between;

            .tabs{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                row-gap: 10px;
                list-style: none;
                padding: 0;
    
                li {
                    cursor: pointer;
                    padding: 2px;
                    background-color: pink;
                    border-radius: 20px;
                    margin-right: 10px;
                    font-size: 18px;
                    min-width: 130px;
                    text-align: center;
    
                    &:nth-child(1){
                        background-color: #ECF3FF;
                        border: 1px solid #3B88A2;
                        width: 180px;
                        color: black;
                    }
                    &:nth-child(2){
                        background-color: #F5E6E1;
                        border:1px solid #E98076;
                        color: black;
                    }
                    &:nth-child(3){
                        background-color: #FBF3DE;
                        border: 1px solid #E3CF9A;
                        color: black;
                    }
                    &:nth-child(4){
                        background-color: #DEF3F3;
                        border:1px solid #72C2C0;
                        color: black;
                    }
                    &:nth-child(5){
                        background-color: #F2E8F9;
                        border:1px solid #CACACA;
                        color: black;
                    }
    
                    &.active:nth-child(1){
                        background-color: #3B88A2;
                        color: #fff;
                    }
                    &.active:nth-child(2){
                        background-color: #E98076;
                        color: #fff;
                    }
                    &.active:nth-child(3){
                        background-color: #E3CF9A;
                        color: #fff;
                    }
                    &.active:nth-child(4){
                        background-color: #72C2C0;
                        color: #fff;
                    }
                    &.active:nth-child(5){
                        background-color: #AB90BE;
                        color: #fff;
                    }
                }
            }
        }
        
        .tab-content {
            margin-top: 30px;
        }

         .learn-more {
            a{
                color: #967F65;
                font-weight: 700;
                font-size: 18px;
            }
        }
    }
}

.cancel_appointment_modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;

    .container{
        padding: 5px;
        background-color: white;
        border-radius: 20px;
        max-width: 500px;
        text-align: center;

        #cancel_appointment_text{
            color: red;
            margin-bottom: 0;
            font-weight: 600;
            cursor: pointer;
        }

        #close_text{
            color: black;
            margin-bottom: 0;
            font-weight: 700;
    
        }
    }   
}

.view_details_modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;

    .container{
        padding: 50px;
        background-color: white;
        border-radius: 20px;
        max-width: 600px;
        overflow: auto;
        position: relative;

        #close_icon{
            position: absolute;
            top: 20px;
            right: 20px;
            color: gray;
            font-size: 24px;
            cursor: pointer;
        }

        #doctor_box{
            text-decoration: none;
            cursor: pointer;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            img{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-right: 15px;
            }

            .doctor_content{
                display: flex;
                flex-direction: column;
                justify-content: center;

                #doctor_name{
                    font-size: 22px;
                    font-weight: 700;
                    color: gray;
                }

                #doctor_dept{
                    font-size: 16px;
                    margin-bottom: 0;
                    font-weight: 700;
                    line-height: 1;
                    color: gray;
                }

                #doctor_hosp{
                    font-size: 14px;
                    margin-bottom: 0;
                    color: gray;
                    margin-top: 3px;
                }

            }
        }

        .appointment_date_box{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .preferred_date_box, .alternative_date_box{
                width: 100%;

                #preferred_date_text{
                    color: black;
                    font-weight: 700;
                    font-size: 16px;
                    margin-bottom: 0;
                }

                #consultation_text{
                    font-size: 14px;
                    margin-bottom: 0;
                    line-height: 1;
                    color: gray;
                }
            }

            #appointment_date_content{
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                // justify-content: space-between;

                #content_box{
                    background-color: #F7F9FF;
                    border-radius: 10px;
                    padding: 10px;
                    width: 30%;
                    margin-top: 12px;

                    #date_text{
                        margin-bottom: 0;
                        font-size: 14px;
                        color: black;
                        font-weight: 600;
                    }

                    #date_text_2{
                        margin-bottom: 0;
                        color: black;
                        font-weight: 700;
                        font-size: 18px;
                    }

                }
                
            }
        }

        .second_container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // justify-content: space-between;
            row-gap: 10px;
            margin-top: 24px;
            column-gap: 100px;

            .username_box{
                #username_text{
                    color: black;
                    font-weight: 700;
                    margin-bottom: 0;
                    font-size: 16px;
                }

                #username_text2{
                    color: gray;
                    font-weight: 700;
                    margin-bottom: 0;
                    font-size: 16px;
                }
            }

            .contact_information_box{
                #contact_info_text{
                    color: black;
                    font-weight: 700;
                    margin-bottom: 0;
                    font-size: 16px;
                }

                #phone_number{
                    margin-bottom: 0;
                    font-weight: 700;
                    font-size: 16px;
                    color: gray;
                }

                #email_text{
                    margin-bottom: 0;
                    font-weight: 700;
                    font-size: 16px;
                    color: gray;
                }
            }

        }
    }
}

@media screen and (max-width: 768px) {
    .my_collections_section {
        .container {
            .tabs {
                display: grid;
                grid-gap: 10px;
            }
        }
    }
}

@media (min-width: 481px) {
    .my_collections_section .collection_mobile {
        display: none !important;
    }

    .my_appointments_section .appointments_mob_view {
        display: none !important;
    }
}

@media screen and (max-width: 480px) {
    .my_collections_section .collection_web {
        display: none !important;
    }

    .my_appointments_section .appointments_web_view {
        display: none !important;
    }

    .profile_page_welcome_section {
        background-repeat: no-repeat;
        background-size: cover;
        height: 249px;
        background-position: center;
        position: relative;

        .container {
            .notification_setting_button {
                display: flex;
                justify-content: flex-end;
                margin-top: 0px;
                position: absolute;
                right: 20px;
                top: 20px;
            }

            .notification_container {
                list-style: none;
                position: relative;
                padding: 20px;
                color: white;
                border-radius: 5px;
                position: absolute;
                width: 350px;
                height: 150px;
                right: 14px;
                bottom: 28px;
                opacity: 1;
                z-index: 999;
                background-color: white;
                overflow-y: scroll;
            }
        }
    }

    //appointments
    .my_appointments_section {
        padding: 50px 0px;

        .container .my_appointments_header {
            margin-bottom: 28px;
        }
    }

    .appointment_card_section {
        padding: 10px;
        min-width: 100% !important;
        margin-bottom: 10px;
    }

    .view_details_modal {

        .container {
            padding: 20px;
            position: relative;

            #close_icon {
                position: absolute;
                top: 10px;
                right: 10px;
                left: unset;
            }

            #doctor_box {
                margin-top: 40px;

                img {
                    width: 79px;
                    height: 79px;
                    border-radius: 50%;
                    margin-right: 15px;
                }

                .doctor_content {
                    #doctor_name {
                        font-size: 17px;
                        font-weight: 700;
                        color: gray;
                    }

                }
            }

            .appointment_date_box {
                .preferred_date_box{
                    width: 100%;
                }

                .alternative_date_box {
                    display: none;
                }

            } 

            .second_container {
                display: flex;
                flex-direction: column;

                .parent_guardian {
                    display: none;
                }
            }
        }  
    }
    

    //colections section
    .my_collections_articles_card {
        height: 100% !important;
        width: 100%;
        margin: auto;
    }

    .my_collections_videos_card_profile {
        height: 100% !important;

        img {
            border-radius: 10px;
            margin-bottom: 18px;
            height: 150px;
            width: 100%;
        }
    }
    

    .my_collections_section {
        .slick_dots_hp {
            .slick-dots {
                position: absolute;
                bottom: -26px;
                display: block;
                width: 100%;
                padding: 0;
                margin: 0;
                list-style: none;
                text-align: center;
            }
        }
    } 

    .appointment_card_section .container {

        .has_data #image_name #doctor_name {
            font-size: 15px;
            margin-bottom: 0;
            font-weight: 700;
            color: gray;
        }

        h4 {
            color: black;
            font-size: 17px;
        }
    }
    
    
}

