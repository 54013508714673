/* 6. Health Tourism */
.health-tourism-area {
  background: rgb(243, 245, 247);
  padding-bottom: 110px;

  .container {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 60px;

    .title-subtitle-box {
      display: flex;
      padding-bottom: 30px;
      justify-content: space-between;

      .title {
        width: 300px;
        p {
          font-size: 39px;
          color: black;
          font-weight: 700;
        }
      }

      .subtitle {
        width: 70%;
        line-height: 1.5;

        p {
          font-size: 20px;
          line-height: 30px;
          text-align: justify;
        }
      }
    }
  }

  .health-tourism-content {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none; 

    &::-webkit-scrollbar {
      display: none;
    }

  }

  .health-tourism-content {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: space-between;
    padding-bottom: 60px;
    // cursor: -webkit-grabbing;

    .content-card {
      height: 500px;
      width: 455px;
      padding: 40px;
      border-radius: 10px;
      background-color: #fff;
      background-position: center;
      background-size: cover;

      h2 {
        line-height: 42px;
        font-size: 32px;
      }

      p {
        font-size: 36px;
        line-height: 1.2;
      }
    }
  }

  .ht_mobile_view{
    margin-bottom: 50px;

    .content-card {
      height: 500px;
      width: 455px;
      padding: 40px;
      border-radius: 10px;
      background-color: #fff;
      background-position: center;
      background-size: cover;

      h2 {
        line-height: 42px;
        font-size: 32px;
      }

      p {
        font-size: 36px;
        line-height: 1.2;
      }
    }
  }
}

@media (max-width: 768px) {
  .title-subtitle-box{
    flex-direction: column;

    .title {
      width: 100% !important;
      text-align: center;
    }
    
    .subtitle{
      width: 100% !important;
    }
  }
}

@media (max-width: 768px) {
  .health-tourism-content{
    display: flex !important;
    row-gap: 10px;
    padding: 10px;
    align-items: center;
  }
}

@media (max-width: 543px) {
  .content-card{
    max-width: 100% !important;

    p{
      font-size: 20px;
    }
  }
}

@media (min-width: 481px) {
  .health-tourism-area{
    .ht_mobile_view{
      display: none;
    }
  }
}

@media (max-width:480px) {
  .content-card p {
    font-size: 25px !important;
  }

  .health-tourism-area{
    margin: 0 20px;
    padding-bottom: 30px;

    .container{
      padding-bottom: 0px;

      .title-subtitle-box {  
        .title {
          p {
            font-size: 25px;
          }
        }
  
        .subtitle {
          p {
            font-size: 13px;
            text-align: center;
            line-height: 1.3;
          }
        }
      }
    }

    .ht_mobile_view {
      .content-card {
        height: 351px;
        border-radius: 30px;

        h2 {
          line-height: 39.65px;
          font-size: clamp(16px, 5vw, 24px);
        }
      }

      .slick-dots {
        position: absolute;
        bottom: -24px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }
    }

    .health-tourism-content{
      display: none !important;
    }
  }
}
