.insights_corner_kpj_section{
    background-color: rgb(243, 245, 247);
    padding: 50px 0px;
    overflow-x: hidden;

    .container{
        h1{
            font-size: 50px;
            color: black;
        }

    }

    .insights_corner_gallery{
        display: flex;
        margin-top: 30px;

        .panel{
            background-size: cover; 
            background-position: center top; 
            background-repeat: no-repeat; 
            height: 70vh; 
            flex: 0.4;
            position: relative;
            cursor: pointer;
            transition: all 0s;

            .overlay-insight-corner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #00000080;
                opacity: 0;
                // transition: opacity 0.3s;
              }
            
              &:not(.active) .overlay-insight-corner {
                opacity: 1;
              }

            &.active{
                flex: 1;

                .insights_corner_subs_0{
                    background-image: linear-gradient(0deg, #371450 5.14%, rgba(55, 20, 80, 0) 58.14%);
                    position: absolute;
                    bottom: 0;

                    p{
                        opacity: 1;
                    }

                    #number_title_subs{
                        position: absolute;
                        bottom: 100px;
                        h3{
                            color: white;
                        }
    
                    }

                    #arrow_circle_icon{
                        opacity: 1;
                    }
                }
                .insights_corner_subs_1{
                    background-image: linear-gradient(0deg, #39B5B2 5.14%, rgba(57, 181, 178, 0) 58.14%);
                    position: absolute;
                    bottom: 0;

                    p{
                        opacity: 1;
                    }

                    #number_title_subs{
                        position: absolute;
                        bottom: 100px;
                        h3{
                            color: white;
                        }
    
                    }

                    #arrow_circle_icon{
                        opacity: 1;
                    }
                }
                .insights_corner_subs_2{
                    background-image: linear-gradient(0deg, #FCB11D 5.14%, rgba(252, 177, 29, 0) 58.14%);
                    position: absolute;
                    bottom: 0;

                    p{
                        opacity: 1;
                    }

                    #number_title_subs{
                        position: absolute;
                        bottom: 100px;
                        h3{
                            color: white;
                        }
    
                    }

                    #arrow_circle_icon{
                        opacity: 1;
                    }
                }

            }

            .insights_corner_subs{
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 50%;
                padding: 0px 30px;
                

                #number_title_subs{
                    position: absolute;
                    bottom: 40px;
                    h3{
                        color: white;
                        text-transform: capitalize;
                    }

                }

                
                p{
                    position: absolute;
                    color: white;
                    opacity: 0;
                    width: 250px;   
                    bottom: 20px;             
                }

                #arrow_circle_icon{
                    position: absolute;
                    bottom: 15px;
                    right: 18px;
                    opacity: 0;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .insights_corner_kpj_section .insights_corner_gallery_mobile {
        display: none;
    }
}

@media (max-width: 768px) {
    .insights_corner_kpj_section .insights_corner_gallery {
        display: none;
    }
}