.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the alpha value for desired opacity */
    z-index: 999; /* Ensure the modal background appears above other content */
  }