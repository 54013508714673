.campaigns-banner-group {
    position: relative;
    background-repeat: no-repeat;
    min-height: 390px;
    background-position: center top;

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h1{
            color: black;
            width: 352px;
        }
    }
}
.campaign-group {
    .campaign-item {
        display: flex;
        padding: 10px;
        background: white;
        border-radius: 25px;
        align-items: center;
        height: 270px;
        justify-content: space-between;

        img {
            border-radius: 15px;
            max-width: 250px;
            max-height: 250px;
        }

        .campaign-details {
            width: 59%;
            padding: 4% 5%;
            display: flex;
            flex-direction: column;
            height: 250px;

            .desc-campro-text{
                p{
                    color: #606060;
                }
            }

            h5 {
                font-size: 18px;
                font-weight: 600;
                color: black;
            }

            button {
                border: 0;
                background: transparent;
                color: var(--primary-color)
            }

            p {
                text-align: left;
                margin: 0;
                color: black;
                
            }
            
            .valid_date {
                height: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-end;
            }
        }
    }
}

.individual-campro-hero-section{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 390px;

    .container{
        height: inherit;
        display: flex;
        align-items: center;

        .individual-campro-title{
            h1{
                color: black;
                width: 360px;
            }

            p{
                color: #39B5B2;
                font-weight: 600;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .campaigns-banner-group {
        min-height: 285px;
        margin-bottom: 50px;
    }

    .filter_campro_mobile {
        position: absolute;
        width: 100%;
        top: -70px;
    }
}

@media screen and (max-width: 768px) {
    .filter-table-group {
        display: none;
    }
    
    .campaigns-banner-group {
        min-height: 270px;

        .container {
            top: 40%;
        }
    }

    .campaign-group {
        .product-grid {
            grid-template-columns: repeat(1, 1fr);
            .product-item {
                img {
                    width: 40%;
                }
            }
        }
    }

    .filter_campro_mobile {
        position: absolute;
        width: 100%;
        // top: -14%;
    }
}

@media screen and (max-width: 425px) {
    .campaign-group {
        .campaign-grid {
            .campaign-item 
            {
                img {
                width: 100px;
                height: 100px;
            }
        }
        }
    }
}

@media (max-width: 480px) {

    .filter_campro_mobile {
        position: absolute;
        width: 100%;
        // top: -23%;
    }

    .campaign-group{
        padding-top: 10px;
        position: relative;
    }
    
    .campaigns-banner-group {
        min-height: 200px !important;
        position: relative;
        background-size: cover !important;

        .container {
            h1 {
                font-size: 25px !important;
                font-weight: 700;
                color: black;
                width: 160px;
                margin-left: 30px;
            }
        }
    }

    .campaign-group .campaign-item {
        height: 160px;
        border-radius: 15px;
        padding: 5px;

        .campaign-details{
            height: 150px;
            padding: 10px;

            .campro_desc_text {
                font-size: 12px !important;
                line-height: 14.32px !important;
            }

            .valid_date {
                p {
                    font-size: 10px !important;
                    line-height: 11.93px !important;
                }
            }
        }

        img {
            border-radius: 10px;
            max-width: 150px;
            max-height: 150px;
        }
    } 

    .campaign-group .campaign-item .campaign-details .campro_title_mobile {
        font-size: 17px !important;
        font-weight: 700 !important;
    }
}