.hero-area {
    @media screen and (max-width: 768px) {
        li.nav-item {
            width: 100%;
            text-align: center;
        }
        .nav-tabs {
            div {
                width: 100%;
            }
            button {
                width: 100%;
            }
        }
        .selection-dropdown-container {
            border-right: none;
        }
        section.speciality-centre {
            min-height: 1800px;
        }
        .blogposts {
            width: 100%;
            transform: translate(-50%);
            padding: 0 158px;

            h2.why-us-text {
                font-size: 18px;
            }
        }
    }
}

.hero-area .hero-slider-homepage {
    // min-height: 990px;

    .hero-banner {
        background-size: 100%;
        background-repeat: no-repeat;
        position: relative;
        top: -45px;
    
        .hero-banner-content {
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}


.hero-area .hero-slider-homepage #chat-icon-homepage{
    width: 130px;
    position: absolute;
    bottom: 20%;
    right: 0;
}

.hero-area .hero-slider-homepage #chat-icon-homepage-text{
    position: absolute;
    font-weight: 700;
    font-size: 13px;
    bottom: 27%;
    right: -1%;
    color: white;
    width: 135px;
    line-height: 1.2;
}

.hero-slider {
    min-height: 950px;
    position: relative;
}

.hero-slider-homepage {
    // min-height:1250px;
    position: relative;
}

.slider-height {
    min-height: 455px;
    background-position: center top;
}

.slider-height-homepage {
    min-height: 690px;
    background-position: center;
}

.hero-slider-caption {
    display: flex;
    align-items: center;
    min-height: 355px;
    padding-top: 4% !important;
    
    h1 {
        color: black;
        width: 524px;
        line-height: 1.1;
        font-size: 56px;
        font-weight: 700;
    }
}

.hero-slider-caption-homepage {
    display: flex;
    align-items: center;
    height: 577px;
    
    
    h1 {
        white-space: pre-wrap;
        max-width: 800px;
    }
}


.column-container.column-0 {
    color: black;
    background: transparent;
    cursor: default;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    padding: 0;
    margin-right: 40px;
}



.column-container.column-1 {
    color: white;
    width: 480px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 0;
    padding: 0;
    box-shadow: 0px 4px 10px 0px #B7BBCA5C;
    height: 650px;
}

.container-color-overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.container-color-overlay::before {
    content: "";
    position: absolute;
    top: -100%;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 51.37%, rgba(144, 120, 161, 0.649) 66.57%, #371450 85.48%);
    pointer-events: none;
    z-index: 1;
    border-radius: 20px;

}

.container-color-overlay-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.container-color-overlay-2::before {
    content: "";
    position: absolute;
    top: -130%;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 34.92%, rgba(255, 255, 255, 0) 44.81%, rgba(58, 95, 108, 0.7641) 62.31%, #003C50 79%);
    pointer-events: none;
    z-index: 1;
    border-radius: 20px;

}

.column-container.column-2 {
    width: 290px;
    margin-left: 30px;
    border-radius: 20px;
    color: #fff;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    box-shadow: 0px 4px 10px 0px #B7BBCA5C;

}
.column-container.column-3 {
    width: 300px;
    margin-left: 40px;
    border-radius: 20px;
    color: var(--primary-color);
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
}
.column-container.column-4 {
    width: 300px;
    margin-left: 40px;
    border-radius: 20px;
    color: var(--primary-color);
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
}
.column-container.column-5 {
    width: 300px;
    margin-left: 40px;
    border-radius: 20px;
    color: var(--primary-color);
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
}
.learn_more_button {
    border: 1px solid white;
    width: 40%;
    text-align: center;
    padding: 10px;
    margin-top: 30px;
    color: white;
}
.column1-title,.column2-title {
    font-size: 2rem;
    font-weight: 500;
    // padding-bottom: 30%;
}

.column3-title {
    font-size: x-large;
    font-weight: 500;
}
.column3-sub-title {
    font-size: large;
}

.column2-sub-title {
    font-size: large;
}

.column2-sub-sub-title {
    font-size: medium;
    width: 350px;
}
.column1-sub-title{
    text-align: right;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary-color);
}

.column1-sub-sub-title {
    font-size: medium;
    text-align: end;
    font-weight: 400;
    padding: 2%;
}

.speciality-centre hr {
    width: 50px;
    padding: 0 10px;
    display: inline-block;
    margin: 0;
    border: none;
    height: 4px !important;
    background-color: var(--primary-color);
    margin: 7px;
}

.subtitle_sc_active{
    color: #967F65;
    font-size: 20px;
    font-weight: 400;
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;
}

.subtitle_sc_active::before {
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    width: 70px;
    height: 5px;
    background: #967F65;
    margin-right: 15px;
}

.speciality-centre-bg {
    width: 100%; 
    height: 400px; 
    background-size: 100%; 
    background-repeat: no-repeat;
    position: relative;

    h2 {
        width: 450px;
        padding: 49px;
        background: rgb(243, 245, 247);
        position: absolute;
        bottom: -4%;
    }

    .sec_image_container{
        position: absolute;
        bottom: 210px;
        right: 5%;
        height: 365px;
        width: 22%;
        border-radius: 32px;
        display: flex;
        align-items: flex-end;
        box-shadow: 0 4px 10px 0 rgba(183, 188, 202, 0.36);
        background-size: cover;
        background-position: center;
        
        .content{
            // background: linear-gradient(180deg, #FBF7EC 0%, #FBF7EC 65.94%, #FBF5E6 76.41%, #FBF3DE 100%);
            padding: 30px;

            .overview-text{
                font-weight: 400;
                font-size: 21px;
                color: #967F65;
            }

            .title-text{
                font-size: 26px;
                font-weight: 700;
                color: #967F65;
            }
        }
    }
}





// application_section_mobile_view
// -------------- 1024px --------------
@media (min-width: 1025px) {
    .appointment_section_mobile_view {
        display: none;
    }

}

@media (max-width: 1024px) {
    .appointment-section {
        display: none;
    }

    .hero-slider-caption-homepage {
        height: 420px;
    }

    .hero-area .hero-slider-homepage {
        min-height: 800px;

        #chat-icon-homepage {
            top: 61%;
        }

        #chat-icon-homepage-text {
            top: 260px;
        }

    }
    

    .appointment_section_mobile_view {
        .grid-container {
            display: flex;
            justify-content: space-around;
            width: -webkit-fill-available;
            position: absolute;
            top: 374px;

            .grid-item {
                width: 24%;
            }
        }
    }

    // h2.why-us-text {
    //     width: 900px;
    // }

    section.speciality-centre {
        min-height: 670px;
    }

    .speciality-centre {
        .speciality-centre-bg {
            height: 300px;
    
            h2 {
                width: 300px;
                padding: 30px;
            }
        }

        .flex-container {
            left: -4%;
            top: 21%;

            .column-container.column-0 {
                padding: 40px;
            }

            .column-container.column-1 {
                width: 370px;
                height: 500px;
            }

            .column-container.column-2 {
                width: 228px;
                height: 300px;    
                background-position: center;
            }

            .column2-sub-sub-title {
                width: unset;
            }
        }
    }

    .health-package-area .title-subtitle-box .subtitle p {
        width: 73% !important;
    }

    .insights-corner-area .container .insights-corner-contents img {
        width: 90%;
    }

}



@media (min-width: 481px) {
    // .appointment_section_mobile_view, 
    .hp_mobile_view {
        display: none;
    }

}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-gap: 10px;
    margin: 10px;
    position: absolute;
    top: 414px;

    .grid-item {
        padding: 20px;
        border-radius: 10px;
        height: 115px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.1;
        position: relative;

        .icon_style{
            position: absolute;
            right: 20px;
            bottom: 20px;
            font-size: 30px;
            font-weight: 500;
        }
    }

    .grid-item:nth-child(1) {
        background-color: #967F65;
    }
        
    .grid-item:nth-child(2) {
        background-color: #967F65;
    }
        
    .grid-item:nth-child(3) {
        background-color: #967F65;
    }
        
    .grid-item:nth-child(4) {
        background-color: #967F65;
    }
}
  
.modal-overlay {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: lightgray;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.modal-content {
    background-color: #F3F5F7;
    padding: 20px;
    border-radius: 0;
    height: 100%;
    gap: 10px;
    overflow-y: auto;

    //animation
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    

    button{
        background-color: transparent;
        border: none;
        align-items: center;
        display: flex;
        flex-direction: row;

        i{
            margin-right: 10px;
        }
    }

    h1{
        margin-top: 20px;
    }

    .white-background{
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
    }

    input[type="text"] {
        width: -webkit-fill-available;
        border: none;
    }

    .search-btn{
        border-radius: 30px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .filter-input{
        display: flex;
        border-radius: 10px;
        background-color: #fff;
        align-items: center;
        padding: 0px 12px;
        font-size: 13px;
        max-height: calc(1.2em + 24px);
        height: calc(1.2em + 24px);
        input{
            border: none;
            background-color: #fff;
        }
        .search-icon{
            color: #cfcfcf;
        }
    }
}

.modal-content.active {
    opacity: 1;
    transform: translateX(0);
}

.modal-content-settings.active {
    opacity: 1;
    transform: translateX(0);
}

.modal-content-settings {
    background-color: #fff;
    padding: 20px;
    border-radius: 0;
    height: 100%;
    gap: 10px;
    overflow-y: auto;
    width: -webkit-fill-available;

    //animation
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
    

    button{
        background-color: transparent;
        border: none;
        align-items: center;
        display: flex;
        flex-direction: row;

        i{
            margin-right: 10px;
        }
    }

    h1{
        margin-top: 20px;
    }

    .white-background{
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
    }

    input[type="text"] {
        width: -webkit-fill-available;
        border: none;
    }

    .search-btn{
        border-radius: 30px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}

// ---------------------------- 1028 ----------------------------
@media (max-width: 1024px) {
    .hero-slider-caption-homepage h1 {
        font-size: 50px;
        // width: 49%;
    }

    .hero-area .hero-slider-homepage .hero-banner {    
        .hero-banner-content {
            background-position: center;
        }
    }

    .health_packages_card_home {
        height: 100%;
    }
}
  
/* Tablet desktop :768px. */

@media (min-width: 769px) {
    .speciality-centre-mobile-view {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hero-slider-caption h1 {
        font-size: 52px;
   }  
}

@media (max-width: 768px) {
    section.speciality-centre {
        display: none;
    }

    .hero-area .hero-slider-homepage #chat-icon-homepage-text {
        right: 1%;
    }

    h2.why-us-text {
        width: 673px;
        font-size: 30px;
    }

    .speciality-centre-mobile-view{
        position: relative;
        min-height: 550px;
        padding-top: 20px;

        .speciality-centre-bg {
            height: 150px !important;

            #section2_title {
                display: flex;
                position: absolute;
                padding: 19px 37px;
                top: 11%;
                left: 6%;
                width: 282px;
                background: #F3F5F7;
                line-height: 1.1;
            }
        }

        .slider_mobile{
            // margin-top: 50px;
            .slider_mobile_item{
                width: 100%;
                display: flex !important;
                justify-content: center;

                img{
                    border-radius: 20px;
                    height: 337px;
                }
            }
        }

        .slider_mobile_hp_home{
            display: flex;
            column-gap: 20px;
            margin-top: 20px;
            overflow-x: auto;
            overflow-y: hidden;
            -ms-overflow-style: none; 
            position: relative;
            padding-left: 35px;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .health-package-area .title-subtitle-box .subtitle p {
        width: 97% !important;
        font-size: 16px;
    }

    .health-tourism-area .container .title-subtitle-box .subtitle p {
        font-size: 15px;
        text-align: center;
        line-height: 1.3;
    }
}

@media (min-width: 481px) {
    .hero-area .home_mobile {
        display: none !important;
    }

    .appointment-popup-section .appointment_header_mob_view {
        display: none !important;
    }
}



// --------------------- MOBILE VIEW (480px) ----------------
@media (max-width: 480px) {
    .appointment-section, section.speciality-centre, .hp_normal_view {
        display: none;
    }

    .hero-area .home_web {
        display: none !important;
    }

    .appointment-popup-section .appointment_header_web_view {
        display: none !important;
    }

    .hero-slider {
        min-height: 810px;
    }

    .slider-height {
        min-height: 400px;
        background-position: center top;
    }

    .slider-height-homepage {
        min-height: 400px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }

    .hero-slider-homepage {
        min-height: 570px !important;
        position: relative;
    }

    .hero-area .blogposts {
        width: 100%;
        padding: 0 ;
    }

    .hero-slider-caption {
        display: flex;
        justify-content: center;
        min-height: 400px;
        text-align: center;
        margin-bottom: 0px;
        
        h1 {
            width: 259px !important;
        }
    }

    .hero-slider-caption-homepage {
        display: flex;
        align-items: flex-start;
        height: 155px;
        padding-top: 5%;
        // justify-content: center;
    }

    .hero-slider-caption-homepage h1 {
        font-size: 25px !important;
        padding-inline: 10px;
        font-weight: 700;
        text-align: left;
        width: 72% !important;
    }

    .banner-content {
        text-align: center;
        top: 25%;
        left: 43%;
        width: 300px!important;
    }

    .hero-area .hero-slider-homepage .hero-banner {
        background-size: 100%;
        background-repeat: no-repeat;
        position: relative;
        top: -12px;
    }

    .hero-area .hero-slider-homepage #chat-icon-homepage{
        position: absolute;
        top: 38%;
        right: 5%;
        width: 27%;
    }

    .hero-area .hero-slider-homepage #chat-icon-homepage-text{
        font-weight: 700;
        font-size: 10px;
        position: absolute;
        top: 38.6%;
        right: 21px;
        color: white;
        width: 90px;
        line-height: 1.1;
    }

    .appointment_section_mobile_view .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        margin: 10px;
        position: absolute;
        top: 414px;

        .grid-item {
            width: 100%;
        }
    }

    .why-us-text-container {
        margin-top: 280px !important;

        .why-us-text-title{
            font-size: 21px !important;
        }
    }

    .blogposts {
        position: absolute;
        bottom: 40px;
        left: 170px;
    }

    .why-us-mv{
        width: 85%;
    }

    h2.why-us-text {
        font-size: 15px;
        font-weight: 400;
        padding: 10px 0;
        line-height: 1.4;
        width: 327px;
    }

    .appointment-section {
        position: absolute;
        top: 62%;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%); 
    }

    .appointment-popup-section {
        .MuiDialogTitle-root, .MuiDialogContent-root{
            padding: 6px 0 !important;
        }

        .MuiDialogContent-root{
            overflow-x: hidden;
        }
       
        #formContainer {
            height: 89% !important;
        }
        form {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .appointment-popup {
            .MuiTypography-h6 {
                font-size: 2rem;
                font-weight: 800;
            }
        }
        .MuiDialog-paperWidthSm {
            max-height: 100% !important;
            min-width: 100% !important;
            border-radius: 0 !important;
            height: 100%;
        }
        h3 {
            font-size: 18px;
        }
        .select_date_time {
            padding: 3% 0;
            border-bottom: 1px solid #9E9E9E;
        }

        .selection-dropdown-date-time {
            width: 47% !important;
        }

        .patients_details {
            padding: 3% 0;
            .patients_details_input {
                div:first-child {
                    background: #F5F5F5;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                div:last-child {
                    background: #F5F5F5;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

        .selection-dropdown {
            margin-bottom: 10px !important;
        }
        select {
            background: var(--second-color);
            border-radius: 5px;
        }
        input {
            border: 0;
        }
        .MuiDialogActions-root {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            button {
                justify-content: center;
                border-radius: 20px;
                width: 100%;
            }
            .make_an_appointment {
                background: var(--primary-color) !important;
                color: white !important;
            }
            button:first-child {
                border: 1px solid var(--second-color);
                background: var(--second-color);
            }
            button:last-child {
                border: 1px solid var(--third-color);
                background: var(--third-color);
            }
        }
        .MuiButton-textPrimary {
            color: black;
            font-weight: 700;
        }
        form {
            height: 100%;
            display: flex;
            width: 100%;
            fieldset {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
            }
        }
        .confirm_appointment_details {
            .row {
                padding: 3% 0;
            }
            span {
                font-weight: 600;
            }
        }
        .swal2-container {
            .swal2-center {
                .swal2-backdrop-show {
                    z-index: 99999;
                }
            }
        }
    }

    .teleconsult-popup-section {
        .MuiDialogTitle-root, .MuiDialogContent-root{
            padding: 0;
        }

        .MuiDialogContent-root{
            overflow-x: hidden;
            height: 100%;  
            margin-top: 30px;
        }

        .MuiButton-textPrimary {
            color: black;
            font-weight: 700;
            width: 100%;
            background: #F5F5F5 !important;
            margin: 1%;
            border-radius: 40px !important;
            padding: 20px;
            background: #F5F5F5;
        }
        
        .MuiTypography-h6 {
            font-size: 1rem !important;
            margin-top: 20px;
        }
        
        .MuiDialog-paperWidthSm {
            max-width: 600px;
            min-width: 600px;
            padding: 10px;
            border-radius: 20px;
            min-height: 600px;
            max-height: 700px;
            height: 100%;
        }

        .MuiButton-textPrimary:hover {
            background-color: var(--primary-color) !important;
            color: white;
        }
    }

    .teleconsult-button-group{
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    .doctor-selection {
        .selection-dropdown{
            width: 100%;
            border: 0;
            font-size: 16px;
            font-weight: 500;
            outline: 0;
            background: white;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 10px;
        }        
    }

    .speciality-centre-mobile-view{
        position: relative;
        min-height: 550px;
        padding-top: 20px;

        .speciality-centre-bg {
            height: 150px !important;

            #section2_title {
                display: flex;
                position: absolute;
                padding: 19px 37px;
                top: 11%;
                left: 6%;
                width: 282px;
                background: #F3F5F7;
                line-height: 1.1;
            }
        }

        .slider_mobile{
            // margin-top: 50px;
            .slider_mobile_item{
                width: 100%;
                display: flex !important;
                justify-content: center;

                img{
                    border-radius: 20px;
                    height: 337px;
                }
            }
        }

        .slider_mobile_hp_home{
            display: flex;
            column-gap: 20px;
            margin-top: 20px;
            overflow-x: auto;
            overflow-y: hidden;
            -ms-overflow-style: none; 
            position: relative;
            padding-left: 35px;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .health-package-area .title-subtitle-box .subtitle p {
        margin: 20px auto !important;
    }

    .health-package-area{
        padding-top: 40px;

        .subtitle p{
            font-size: 15px !important;
            width: 322px !important;
        }
        .title p{
            font-size: 25px !important;
        }
    }

    .health-package-area .health-package-button-container {
        text-align: center;
        margin-bottom: 40px;
        font-size: 16px;
        margin-top: 25px;
    }

    .hp_mobile_view{
        .slider_mobile_item{
            margin: 0 10px;
            width: 100%;
            display: flex !important;
            justify-content: center;

            img{
                border-radius: 20px;
                height: 300px;
            }
        }
    }

    .health-package-area{
        .slick-dots {
            position: absolute;
            bottom: -35px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }
    }

    .health_packages_card #book_package {
        border: 1px solid #967F65;
        color: #967F65;
        width: full-width;
        border-radius: 30px;
        text-align: center;
        padding: 10px;
        // margin: 8px 15px;
        font-size: 17px !important;
        font-weight: 700;
        cursor: pointer;
    }

    .health_packages_card_home {
        margin-inline: auto;
        width: 95%;

        img {
            border-radius: 15px;
            margin-bottom: 18px;
        }

        #book_package {
            border: 1px solid #967F65;
            color: #967F65;
            width: full-width;
            padding: 15px;
            border-radius: 30px;
            text-align: center;
            padding: 10px 5px;
            margin: 8px 15px 14px 15px;
            font-size: 12px;
            font-weight: 700;
            cursor: pointer;
            height: 36px;
            line-height: normal;
        }

        #package_name {
            font-size: 15px;
            color: #070707;
            font-weight: 700;
            margin-bottom: 0px;
            line-height: 17px;
            padding: 5px 15px;
            text-overflow: unset;
            white-space: normal;
            overflow: unset;
        }
    }

    .homepage.insights-corner-area .container .title-subtitle .subtitle p{
        font-size: 15px !important;
    }
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .hero-slider-caption h1 {
        font-size: 40px;
   }
}


