.setting_kpj_section {
  min-height: 100vh;
  display: flex;
  .css-1xc3v61-indicatorContainer {
    padding: 0;
  }
  select {
    font-weight: bold;
  }

  .css-1dimb5e-singleValue {
    font-weight: bold;
  }
  .css-gkkvb-control {
    background-color: #f5f5f5;
  }
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0;
    font-size: 12px;
    font-weight: bold;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-style: none !important;
  }

  .setting_navigation {
    top: 0;
    left: 0;
    bottom: 0;
    padding: 5% 7%;
    background-color: #f1f1f1;

    h2 {
      color: black;
      margin-bottom: 50px;
    }

    .nav_item {
      cursor: pointer;
      margin: 15px 10px 15px 0px;
      font-weight: 500;
      color: black;
      width: 170px;
      display: flex;
      align-items: center;

      &.active {
        color: #967F65;
        font-weight: 600;

        img {
          &.active-icon {
            fill: #967F65;
          }

          &.inactive-icon {
            fill: black;
          }
        }
      }
    }
  }

  .setting_content {
    padding: 20px;
    width: 100%;
  }
}

#react-select-3-listbox{
  background-color: #eaedf8;
}
/* Mobile Responsive */

.edit_profile_section {
  padding-top: 14%;
  padding-left: 9%;

  .container {
    padding-bottom: 100px;

    h3 {
      color: black;
      margin-bottom: 20px;
    }
    .personal_information {
      display: flex;
    }
    .image_container {
      background-color: #967F65;
      border-radius: 50%;
      width: fit-content;
      position: relative;
      margin-bottom: 30px;

      img {
        border-radius: 50%;
        padding: 5px;
        max-width: 150px;
        max-height: 150px;
      }

      .camera_icon {
        position: absolute;
        bottom: 20px;
        right: 1px;
        background-color: #967F65;
        width: fit-content;
        border-radius: 50%;
      }
    }

    h4 {
      color: black;
      margin-bottom: 20px;
    }

    #form_password {
      display: flex;
      flex-direction: column;
      width: 400px;
      justify-content: center;
      height: 80px;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 15px;
      margin-top: 10px;
      position: relative;
      margin-right: 15px;

      #eye_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        border: 0;
        background-color: transparent;
        color: #967F65;
      }
    }

    #change_password_button,
    #change_email_button {
      background-color: #967F65;
      width: 182px;
      padding: 10px 30px;
      color: white;
      border: 0;
      border-radius: 30px;
      margin-top: 15px;
      font-weight: 600;
    }

    #change_password_cancel_btn,
    #change_email_cancel_btn {
      background-color: white;
      width: 180px;
      padding: 10px 30px;
      color: black;
      border: 1px solid black;
      border-radius: 30px;
      margin-top: 15px;
      font-weight: 600;
      margin-left: 15px;
    }

    #form_email {
      display: flex;
      flex-direction: column;
      width: 400px;
      justify-content: center;
      height: 80px;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 15px;
      margin-top: 10px;
    }

    #form_patientnric {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 80px;
      justify-content: center;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
    }

    #form_firstName {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 80px;
      justify-content: center;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
    }

    #form_lastName {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 80px;
      justify-content: center;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
    }

    #form_gender {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 80px;
      justify-content: center;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
    }

    #form_dob {
      display: flex;
      background-color: #f5f5f5;
      flex-direction: column;
      width: 400px;
      height: 80px;
      justify-content: center;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
    }

    #form_country {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 80px;
      justify-content: center;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
    }

    #form_phoneNum {
      display: flex;
      flex-direction: column;
      width: 400px;
      height: 80px;
      justify-content: center;
      background-color: #f5f5f5;
      //background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
    }

    #save_btn {
      background-color: #967F65;
      width: 250px;
      padding: 10px 30px;
      color: white;
      border: 0;
      border-radius: 30px;
      margin-top: 30px;
      font-weight: 600;
    }
  }
}

.change_password_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  .container {
    padding: 100px;
    width: max-content;
    background-color: white;
    border-radius: 20px;
    position: relative;

    .criteria_subs {
      margin-bottom: 50px;
      p {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 1.1;
        color: gray;
      }
    }
    #form_ConfirmPassword {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 20px;
      margin-top: 10px;
      position: relative;
      height: 80px;
      justify-content: center;

      #eye_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        border: 0;
        background-color: transparent;
        color: #967F65;
      }
    }

    #form_newPassword {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 20px;
      margin-top: 10px;
      position: relative;
      height: 80px;
      justify-content: center;

      #eye_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        border: 0;
        background-color: transparent;
        color: #967F65;
      }
    }

    #change_password_btn {
      background-color: #967F65;
      width: 50%;
      padding: 10px 30px;
      color: white;
      border: 0;
      border-radius: 30px;
      margin-top: 30px;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .tooltip-container {
    position: relative;
  }

  .custom-tooltip {
    position: absolute;
    background-color: white;
    color: #333;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    z-index: 999;
    bottom: 100%; /* Position above the hover text */
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: white transparent transparent transparent;
    top: 100%;
    left: 10%;
    transform: translateX(-50%);
  }
}

.member_section {
  padding-top: 14%;
  padding-left: 9%;

  .container {
    h3 {
      color: black;
      margin-bottom: 3px;
    }

    p {
      color: gray;
      font-size: 14px;
    }

    .members_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.member_card {
  min-height: 200px;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 20px;
  position: relative;
  display: inline-block; /* Display cards in a row */

  &.placeholder_card {
    height: 205px; /* Set the height for the placeholder card */
    border: 3px dashed #967F65;
    background-color: #E2E5EA;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.option_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  .option_container {
    width: 50%;
    background-color: white;
    border-radius: 20px;
    text-align: center;

    p {
      margin-bottom: 0;
      padding: 10px;
      font-weight: 700;
      color: black;
    }
  }
}

.edit_member_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    position: absolute;
    background-color: #fff;
    padding: 50px;
    border-radius: 20px;
    width: fit-content;
    text-align: left;

    #form_name , #form_relationship {
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      height: fit-content;
      width: 300px;
    }

    #form_gender, 
    #form_dateOfBirth, 
    #form_email {
      display: flex;
      flex-direction: column;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      height: 100%;
      width: 300px;
    }

    #form_countryOfResident,
    #form_phoneNumber  {
      display: flex;
      flex-direction: column;
      background-color: #eaedf8;
      border-radius: 5px;
      padding: 10px;
      width: 300px;
    }
  }

  .save_btn {
    margin-top: 50px;
    border: 0px;
    background-color: #967F65;
    padding: 10px;
    color: white;
    font-weight: 600;
    width: 280px;
    border-radius: 30px;
  }
}

.bank_account_section {
  padding-top: 120px;
  padding-left: 70px;
  .container {
    h3 {
      color: black;
      margin-bottom: 40px;
    }
  }
}

.bank_account_card {
  width: 400px;

  height: 320px;
  padding: 10px;
  background-color: #f5f5f5;
  margin: 0px 20px 20px 0px;
  border-radius: 20px;
  position: relative;
  display: inline-block; /* Display cards in a row */

  &.placeholder_card {
    border: 3px dashed #e1e7f8;
    background-color: #ecf3ff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  img {
    border-radius: 15px;
  }
}

.edit_bank_account_modal,
.add_card_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  .container {
    position: fixed;
    background-color: #fff;
    padding: 70px;
    border-radius: 20px;
    width: fit-content;
    text-align: left;

    #close_btn {
      position: absolute;
      top: 10px;
      right: 10px;
      color: gray;
      font-size: 24px;
      cursor: pointer;
    }

    .form_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 10px;

      #label_tag {
        color: black;
        font-size: 12px;
      }

      #input_tag {
        border: none;
        background-color: transparent;
        font-size: 12px;
      }

      #form_cardNumber {
        display: flex;
        flex-direction: column;
        width: 360px;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-right: 20px;
      }

      #form_cardHolderName {
        display: flex;
        flex-direction: column;
        width: 360px;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
      }

      #form_expirationMonth {
        display: flex;
        flex-direction: column;
        width: 175px;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-right: 10px;
        margin-top: 20px;
      }

      #form_expirationYear {
        display: flex;
        flex-direction: column;
        width: 175px;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-right: 20px;
        margin-top: 20px;
      }

      #form_securityCode {
        display: flex;
        flex-direction: column;
        width: 175px;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-right: 20px;
        margin-top: 20px;
        position: relative;
      }

      #form_issuingBankCountry {
        display: flex;
        flex-direction: column;
        width: 360px;
        background-color: #eaedf8;
        border-radius: 5px;
        padding: 10px;
        margin-right: 20px;
        margin-top: 20px;
        justify-content: center;
      }

      #form_switch_btn {
        display: flex;
        flex-direction: column;
        width: 360px;
        background-color: transparent;
        padding: 10px;
        margin-right: 20px;
        margin-top: 20px;
      }
    }

    #save_btn {
      margin-top: 70px;
      border: 0;
      background-color: #967F65;
      padding: 10px;
      color: white;
      font-weight: 600;
      width: 280px;
      border-radius: 30px;
    }
  }
}

.billing_address_info_section {
  padding-top: 14%;
  padding-left: 9%;
  
  .container {
    h3 {
      color: black;
      margin-bottom: 40px;
    }

    .grid-container-billing-address {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
}

.billing_address_card {
  width: 100%;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 20px;
  position: relative;
  display: inline-block; /* Display cards in a row */

  &.placeholder_billing_address_card {
    width: 100%;
    height: 205px; /* Set the height for the placeholder card */
    border: 3px dashed #967F65;
    background-color: #E2E5EA;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #three_dots_btn {
    position: absolute;
    top: 34px;
    right: 38px;
    font-size: 16px;
    cursor: pointer;
    color: gray;
  }

  #invoice_name {
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin-bottom: 0;
  }

  #tax_id {
    font-size: 18px;
    font-weight: 700;
    color: black;
  }

  #billing_address {
    font-size: 18px;
    color: black;
    line-height: 1.2;
    margin-bottom: 50px;
  }

  #is_default {
    border: 1px dashed #967F65;
    border-radius: 20px;
    width: 90px;
    padding: 1px;
    text-align: center;
    color: #967F65;
    position: absolute;
    bottom: 0;
    left: 25px;
  }
}

.edit_billing_address_modal,
.add_billing_address_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  .container {
    position: fixed;
    background-color: #fff;
    padding: 3%;
    border-radius: 20px;
    width: fit-content;
    text-align: left;

    #close_btn {
      position: absolute;
      top: 10px;
      right: 10px;
      color: gray;
      font-size: 24px;
      cursor: pointer;
    }

    .form_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 10px;

      #label_tag {
        color: black;
        font-size: 12px;
      }

      #input_tag {
        border: none;
        background-color: transparent;
        font-size: 12px;
      }

      #form_type {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        .personal_receipt_radio {
          width: 370px;
        }
      }

      #form_issuingName {
        display: flex;
        flex-direction: column;
        width: 360px;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-right: 20px;
      }

      #form_taxID {
        display: flex;
        flex-direction: column;
        width: 360px;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
      }

      #form_address {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-top: 20px;
      }

      #form_addressLine2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-top: 20px;
      }

      #form_postalCode {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-top: 20px;
      }
    }

    #label_tag {
      color: black;
      font-size: 12px;
    }

    #save_btn {
      margin-top: 70px;
      border: 0;
      background-color: #967F65;
      padding: 10px;
      color: white;
      font-weight: 600;
      width: 280px;
      border-radius: 30px;
    }
  }

  #form_container {
    label {
      color: black;
      font-size: 16px;
      font-weight: 500;
      margin-right: 240px;
    }
  }
}

.notification_section {
  padding-top: 14%;
  padding-left: 9%;

  .container {
    h3 {
      color: black;
      margin-bottom: 40px;
    }

    .remainder_emails {
      display: flex;
      align-items: center;

      #notification_title {
        color: black;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 0;
      }

      #notification_subs {
        font-size: 12px;
        line-height: 1;
      }
    }
  }
}

@media (max-width: 1280px) {
  .edit_profile_section, 
  .member_section, 
  .billing_address_info_section, 
  .notification_section {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .edit_profile_section .container .personal_information {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .edit_billing_address_modal,
  .add_billing_address_modal,
  .add_card_modal {
    .container {
      padding: 30px;
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }

  .edit_profile_section,
  .member_section,
  .bank_account_section,
  .notification_section,
  .billing_address_info_section {
    padding-top: 0;
    padding-left: 0;
  }

  .setting_kpj_section {
    .setting_navigation {
      padding: 30px 20px;
    }
  }

  .edit_profile_section {
    .container {
      .personal_information {
        display: flex;
      }
    }
  }

  .setting_kpj_section {
    display: flex;
    flex-direction: column;

    .setting_navigation {
      .nav_item {
        cursor: pointer;
        padding: 10px 30px 10px 0px;
        font-weight: bold;
        color: black;
      }
    }
  }

  .nav_group {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    /* width: 600px; */
  }

  .nav_item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .edit_profile_section {
    .container {
      #form_password {
        display: flex;
        flex-direction: column;
        width: 400px;
        justify-content: center;
        height: 80px;
        background-color: #eaedf8;
        border-radius: 5px;
        padding: 15px;
        margin-top: 10px;
        position: relative;
      }

      #change_password_cancel_btn {
        margin-left: 0;
      }
    }
  }

  .change_password_modal {
    .container {
      height: 100%;
      width: 100%;
      padding: 50px;
      #change_password_btn {
        font-size: 10px;
      }
    }
  }

  .edit_member_modal {
    .container {
      width: 100%;
      margin: auto;
      height: 100%;
      padding: 30px;
      border-radius: 0;

      #form_name,
      #form_relationship,
      #form_phoneNumber,
      #form_countryOfResident,
      #form_dateOfBirth,
      #form_gender,
      #form_issuingName {
        width: 100%;
        margin: 0;
      }

      .save_btn {
        margin-top: 30px;
        width: 100%;
      }
    }
  }

  .edit_bank_account_modal {
    .container {
      padding: 30px;
      width: 100%;
      height: 100%;
      border-radius: 0;
      .form_container {
        #form_cardNumber,
        #form_expirationMonth,
        #form_securityCode,
        #form_expirationYear,
        #form_issuingBankCountry {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  .edit_billing_address_modal {
    .container {
      .form_container {
        #form_issuingName {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .edit_billing_address_modal,
  .add_billing_address_modal {
    #form_container {
      label {
        margin: 0;
      }
    }
  }
}

#form_country select option{
  background-color: #eaedf8
}

@media screen and (min-width: 481px) {
  .setting_mob_view {
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .setting_web_view {
    display: none !important;
  }

  .edit_profile_section {
    .container {
      #form_password,
      #form_phoneNum,
      #form_country,
      #form_dob,
      #form_gender,
      #form_lastName,
      #form_firstName,
      #form_patientnric,
      #form_email {
        width: 100%;
      }
      .personal_information {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .details_container {
    width: 100%;
    flex-direction: column;
  }

  .form_group {
    width: 100%;
  }

  .member_card {
    width: 100%;
    &.placeholder_card {
      width: 100%;
    }
  }

  .bank_account_card {
    width: 100%;
  }

  .billing_address_card {
    width: 100%;
    &.placeholder_billing_address_card {
      width: 100%;
    }
  }

  .change_password_modal {
    .container {
      padding: 30px;
    }
  }

  .setting_kpj_section {
    min-height: fit-content;

    .setting_navigation {
      padding: 30px 20px;
      background-color: #fff;
    }
  }
  
  .setting_mob_view {
    .nav_group {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      .nav_item_mob {
        display: flex;
        align-items: center;
        background: #EBEDEF;
        padding: 12px;
        border-radius: 6px;

        p {
          margin-bottom: 0;
          font-size: 17px;
          color: black;
          font-weight: 400;
        }
      }
    }
  }

  .modal-content {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .member_section .container .members_grid {
    display: flex;
    flex-direction: column;
  }

  .edit_profile_section .container #save_btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .billing_address_info_section .container .grid-container-billing-address {
    grid-template-columns: 1fr;
  }
}
