.ms {
    width: 100%;
    
      &__question {
        max-width: 600px;
        width: 100%;
        border-bottom: 1px solid white
      }
    
      &__question-button {
        width: 100%;
        display: flex;
        border: none;
        background-color: transparent;
        padding: 0px;
        cursor: pointer;
        color: white;
        
    
        padding-top: 10px;
        padding-bottom: 10px;

        font-weight: bold;
    
        &::before {
          content: "+";
          margin-right: 17px;
        }
    
        
      }
    
      &__desc {
        margin-left: 28px;
        padding-right: 17px;
        margin-top: 0;
        margin-bottom: 0;
        height: 0;
        overflow: hidden;
        transition: all 0s ease-out;
      }
}

.ms__desc.show-description {
    height: auto;
    margin-bottom: 15px;
    color: white;
    overflow-wrap: break-word
  }
  
  /* Arrows */
  
  /* closed */
  .ms__question-button::before {
    transition: transform 300ms ease-out;
  }
  
  /* open */
  .font-weight-bold::after {
    transform: rotate(720deg);
  }