.individual-podcast-information-section {
    background-color: rgb(243, 245, 247);
    padding: 50px 0;

    .back_to_podcasts {
        margin-bottom: 50px;
        width: fit-content;

        p {
            color: #39B5B2;
            font-weight: 600;
        }

        svg {
            margin-right: 10px;
        }
    }

    .podcast-content-1-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .doctor-group  { 
            display: flex;
            align-items: center;
            margin-right: 20px;
            color: gray;
    
            .doctor-name {
                font-style: italic;
            }
    
            .doctor-img {
                width: 40px;
                margin-right: 10px;
    
                img {
                    border-radius: 50%;
                }
            }
        }

        .doctor-calender-date {
            display: flex;
            align-items: center;
            height: 100%;
            color: gray;

            span.doctor-calender {
                margin-right: 10px;
            }
        }

        .share-podcast-btn{
            height: 30px;
            color: black;
            border-color: black;
            text-transform: capitalize;
            border-radius: 20px;
            width: fit-content;
        }
    }

    .podcast-content-2 {
        p {
            font-size: 13px;
            color: black;
            line-height: inherit;
            margin: 30px 0;
        }
        h3 {
            margin: 30px 0;
        }
        button {
            background: var(--button);
            padding: 5px 20px;
            border-radius: 20px;
            border: 0;
            font-weight: 500;
            margin-right: 15px;
            cursor: default;
        }
    }
}


.individual-podcast-other-section {
    background-color: #EBEDEF;
    padding: 50px 0;
    .health-package-card {
        margin-left: 0;
        margin-right: 10px;
    }
    .title {
        display: flex;
        justify-content: space-between;
        p {
            color: var(--primary-color);
            font-weight: 700;
        }
        a {
            position: relative;
            right: 7%;
        }
    }
    .podcast-other-group {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        gap:20px;
    }

    .podcast_play_icon {
        display: none;
    }
}

.individual-podcast-allespisode-section {
    background: #EBEDEF;
    padding: 25px;
    border-radius: 20px;
    .my_collections_podcasts_card {
        margin: 10px;
    }
    p#podcast_espisode {
        display: none;
    }
    p#podcast_writer {
        margin: 0;
        line-height: initial;
    }
    img {
        border-radius: 15px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .individual-podcast-other-section {
        padding: 20px;
    }

    .individual-podcast-allespisode-section {
        margin-top: 20px;
    }
}

