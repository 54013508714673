.forgot_password_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  .tooltip-container {
    position: relative;
  }

  .custom-tooltip {
    position: absolute;
    background-color: white;
    color: #333;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    z-index: 999;
    bottom: 100%; /* Position above the hover text */
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: white transparent transparent transparent;
    top: 100%;
    left: 10%;
    transform: translateX(-50%);
  }

  .container {
    background-color: #f2f4f6;
    border-radius: 20px;
    width: 40vw;
    height: fit-content;
    padding: 80px;

    h3 {
      color: black;
      margin: 30px 0px;
    }

    .form_email {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 8px;
      padding: 10px;
      width: 100%;
    }

    .form_phoneNumber {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 8px;
      padding: 10px;
      width: 100%;
    }

    #send_btn {
      background-color: #967F65;
      padding: 10px;
      color: #fff;
      width: 100%;
      border: 0;
      font-weight: 600;
      border-radius: 30px;
      margin-top: 20px;
    }

    .phone_number_code {
      margin: 5px;
      text-align: center;

      span:last-child {
        color: #967F65;
      }
    }

    .otp_number {
      display: flex;
      justify-content: center;
      margin-top: 5px;

      #otp {
        width: 50px;
        height: 50px;
        margin: 5px;
        font-size: 14px;
        text-align: center;
        border: 0;
        border-radius: 5px;
      }
    }
  }

  .css-1xc3v61-indicatorContainer {
    padding: 0;
  }
}

@media (max-width: "1002px") {
  .forgot_password_modal {
    .container {
      padding: 50px;
    }
  }
}

@media (max-width: "768px") {
  .forgot_password_modal {
    .container {
      padding: 50px;
    }
  }
}

@media (max-width: "425px") {
  .forgot_password_modal {
    .container {
      padding: 20px;
    }
  }
}
