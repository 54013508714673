.mayo-faq {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    h2 {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 32px;
        color: var(--primary-color);

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    .faq-item {
        margin-bottom: 20px;

        h3 {
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 5px;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

        p {
            font-size: 16px;
            color: #555;
            line-height: 1.6;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }
}
