.news-container{
  background-color: #F2F4F6;
  .filter-table{
    #filter-category{
      width: 300px;
      background-size: auto !important;
      background-position: right !important;
    }
  }
  .news-list{
    .news-item{
      
      .news-thumbnail-container{
        cursor: pointer;
        width: 100%;
        height: 220px;
        background-color: white;

        img{
          display: block;
          // margin: auto;
        }
      }

      .title {
        color: black;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* Limit to three lines */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        height: 60px;
      }
    }
  }

  .pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .news-container-2{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 15px;
  }

  .news-detail-container{
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: #000000ba;
    .news-detail{
      width: 80%;
      float: right;
      height: 100%;
      overflow-y: auto;
      background-color: #f3f5f7;
      right: -100%;
      transition: right 0.5s ease;
      &.active{
        right: 0;
      }
      .news-header{
        background-color: #fff; 
      }
      .news-body, .news-header{
        padding: .5em 8em .5em 5em;
        .close-btn{
          cursor: pointer;
        }
      }
      .news-body img{
        height: auto;
      }
      .news-footer{
        span{
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .news-container {
    .news-container-2 {
      display: flex;
      flex-direction: column;
  
      .col-3 {
        width: 100%;
      }
    }

    .news-list .news-item .news-thumbnail-container {
      margin: auto;
    }

    .news-detail-container .news-detail {
      .news-header {
        padding: 0.5em;
      }

      .news-body {
        padding: 0.5em;
      }
    } 
  }
}