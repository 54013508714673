.video-section{
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;

    .container {
        display: flex;
        flex-direction: column;
        height: inherit;
        justify-content: center;

        h1 {
            color: white;
        }

        p {
            color: #39B5B2;
            font-weight: 600;
        }
    }
}

.filter_table_video_mv{
    position: absolute;
    bottom: -12%;
    margin: 0px;
    width: 100%;
    text-align: center;
}

@media (max-width: 1024px) {
    .video-section {
        background-size: cover;
        height: 60vh;
    }

    .filter-table-group {
        display: none;
    }

    .video_listing_section {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .video_page_card img {
        width: 100%;
    }
}

@media (min-width: 481px) {
    .filter_table_video_mv{
        display: none !important;
    }

    .video-section .mob_view {
        display: none !important;
    }
}

@media (max-width: 480px) {

    .video-section .web_view {
        display: none !important;
    }

    .video-section {
        height: 198px;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-bottom: 50px
    }

    .video_page_card img {
        border-radius: 10px;
        margin-bottom: 18px;
        width: 100%;
    }
}