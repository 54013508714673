.mayo-actions {
    &__divider {
        margin: 40px 0;
        background: #967f65;
        height: 2px;
        width: 100%;

        @media screen and (max-width: 768px) {
            margin: 20px 0;
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        align-items: center;
        padding: 0 20px;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 6px;
            button {
                width: 100%;
            }
        }
    }
}
