.my_collections_articles_section{
    background-color: rgb(243, 245, 247);
    padding-top: 100px;
    padding-bottom: 50px;

    .container{
        h2{
            color: black;
            margin-bottom: 50px;
        }
    }
}

.my_collections_articles_card{
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    height: 100%;

    .article_image {
        height: 200px;
        max-width: 170px;
        border-radius: 10px;
    }

    #article_name{
        color: black;
        font-size: 16px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.4em;
        line-height: 1.2em;
        margin-bottom: 10px;
    }

    #article_content{
        line-height: 1.2em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.4em;
        margin-bottom: 15px;

        span span span{
            font-size: 13px;
            line-height: 1.1;
            color: #606060;
            font-weight: 400;
            // display: inline-block;
        }

        span span span span{
            font-size: 13px;
            line-height: 1.1;
            color: #606060;
            font-weight: 400;
            // display: inline-block;
        }

        span span span span span{
            font-size: 13px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
            // display: inline-block;
            cursor: pointer;
        }

        }
    
        #article_writer{
            font-size: 14px;
            font-weight: 700;
            color: #666567;
            margin-bottom: 10px;
            line-height: 15px;
            height: 30px;
        }
    
        #article_date{
            font-size: 14px;
            margin-bottom: 0px;
            color: #666567;
        }

}

.article-other-group{
    .my_collections_articles_card{
        background-color: #fff;
        padding: 5px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        height: 200px;
        width: 450px;
    
        .container{
            padding: 5px;
            display: flex;
            justify-content: normal;
            align-items: center;
            
            img{
                border-radius: 15px;
                height: 100%;
                aspect-ratio: 1/1;
            }
        
            #article_name{
                font-size: 20px;
                font-weight: 600;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 2.4em;
                line-height: 1.2em;
                margin-bottom: 10px;
            }
        
            #article_content{
                line-height: 1.2em;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 2.4em;
                margin-bottom: 15px;
        
                span span span{
                    font-size: 13px;
                    line-height: 1.1;
                    color: #606060;
                    font-weight: 400;
                    // display: inline-block;
                }

                span span span span{
                    font-size: 13px;
                    line-height: 1.1;
                    color: #606060;
                    font-weight: 400;
                    // display: inline-block;
                }

                span span span span span{
                    font-size: 13px;
                    line-height: 1.1;
                    color: #967F65;
                    font-weight: 700;
                    // display: inline-block;
                    cursor: pointer;
                }
        
            }
        
            #article_writer{
                font-size: 14px;
                font-weight: 700;
                color: #666567;
                margin-bottom: 10px;
                line-height: 1.1;
            }
        
            #article_date{
                font-size: 14px;
                margin-bottom: 0px;
                color: #666567;
            }
    
        }
    }
}

.articles_page_card{
    background-color: #fff;
    padding: 10px;
    border-radius: 25px;
    height: 100%;

    img{
        border-radius: 15px;
        margin-bottom: 18px;
        width: 100%;
        aspect-ratio: 1/1;
    }

    #article_name{
        color: black;
        font-size: 16px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.4em;
        line-height: 1.2em;
        margin-bottom: 10px;
    }

    #article_content{
        line-height: 1.2em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.4em;
        margin-bottom: 20px;
 
        span span span{
            font-size: 13px;
            line-height: 1.1;
            color: #606060;
            font-weight: 400;
            display: inline-block;
        }

        span span span span{
            font-size: 13px;
            line-height: 1.1;
            color: #606060;
            font-weight: 400;
            display: inline-block;
        }

        span span span span span{
            font-size: 13px;
            line-height: 1.1;
            color: #39B5B2;
            font-weight: 700;
            display: inline-block;
            cursor: pointer;
        }

    }

    #article_writer{
        font-size: 14px;
        font-weight: 700;
        color: #666567;
        height: 3em;
        line-height: 15px;
    }

    #article_date{
        font-size: 14px;
        margin-bottom: 0px;
        color: #666567;
    }

}

@media (max-width: 1280px) {
    
}

@media (max-width: 480px) {
    .article-other-group .my_collections_articles_card .container {
        padding: 0;
        // margin: auto;

        #article_name {
            font-size: 17px;
        }

        #article_content span span span {
            font-size: 12px;
        }

    }    
}