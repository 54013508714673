.my_collections_podcast_section{
    background-color: rgb(243, 245, 247);
    padding-top: 100px;
    padding-bottom: 50px;

    .container{
        h2{
            color: black;
            margin-bottom: 50px;
        }
    }
}

.my_collections_podcast_card{
    background-color: #fff;
    padding: 15px;
    border-radius: 20px;

    img{
        border-radius: 5px;
        margin-bottom: 18px;
        width: 100%;
    }

    #podcast_name{
        font-size: 16px;
        color: black;
        font-weight: 700;
        line-height: 1.1;
    }

    #podcast_writer{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0px;
    }

    #podcast_episodes{
        font-size: 14px;
        font-weight: 700;
        color:#967F65;
        line-height: 0.5;
    }

    #podcast_date{
        font-size: 14px;
        margin-bottom: 0px;
    }
}

@media (max-width: 480px) {
    .my_collections_podcast_card {
        padding: 10px;
        border-radius: 20px;
        width: 90%;
        margin: auto;

        img {
            border-radius: 10px;
        }
    }
}