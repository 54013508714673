.eye_specialist_hero_section{
    background-repeat: no-repeat;
    height: 390px;
    width: 100%;
    background-size: cover;
    position: relative;

    .banner-content {
        position: absolute;
        top: 50%;
        left: 24%;
        transform: translate(-50%, -50%);
        text-align: left;
        width: 550px!important;
    }
    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: inherit;

        h1{
            margin-bottom: 20px;
            width: max-content;
        }

    }
}

.eye_specialist_contact_section .osc-border-right{
    border-right: 1px solid lightgray;
}

.eye_specialist_contact_section, .eye_specialist_contact_section_mv {
    padding: 70px 0;
    background-color: white;

    .container {

        #ambulance-logo{
            background-color: #E98076;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 15px 17px;
            text-align: center;
        }

        #contact{
            display: flex;
            flex-direction: row;

            p {
                margin-bottom: 0px;
            }

            h3 {
                // color: #9D9C9C;
            }
        }

        #our-doctors, #services, #patient-care  {
            display: flex;
            flex-direction: column;

            a{
                color: #967F65;
                
            }

            // img {
            //     position: relative;
            //     width: 35px;
            //     height: 100%;
            //     margin-top: 20px;
            //     left: 60%;
            // }

            .icon_container {
                width: 35px;
                height: 100%;
                margin-top: 20px;
                height: 100%;
                left: 60%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
              }

        }
    }
}

.eye_specialist_contact_section_mv{
    padding-top: 70px;
    padding-bottom: 20px;
    background-color: white;

    .container{
        #our-doctors, #services, #patient-care  {
            display: flex;
            flex-direction: column;
            align-items: center;
    
            a{
                color:#967F65;
                
            }
    
            img {
                position: relative;
                width: 50px;
                margin-top: 20px;
                left: 0 !important;
            }
        }
    }
}

@media (max-width:"767px") {
    #our-doctors, #services {
        border-right: 0 !important;
        border-bottom: 1px solid gray;
    }
    
}

.eye_specialist_bid_section{
    padding: 50px 0px;
    background-color: rgb(243, 245, 247);

    .container{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        .bid_content{
            width: 40%;
            display: flex;
            flex-direction: column;

            h3{
                color: black;
            }
    
            #bid_subs{
                line-height: 1.5;
                margin-top: 40px;
                text-align: justify;
            }
    
            #who_we_are_btn{
                border: 1px solid #39B5B2;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-radius: 30px;
                padding: 15px;
                text-transform: capitalize;
    
                #arrow_up_right{
                    color: #39B5B2;
                }
    
                &:hover{
                    background-color: #39B5B2;
    
                    p, #arrow_up_right{
                        color: white;
                    }
                }
    
                p{
                    color: #39B5B2;
                    font-size: 25px;
                    margin-bottom: 0px;
                    margin-left: 20px;
    
                }
            }
    
            img{
                border-radius: 20px;
                // max-height: 400px;
                width: 200px;
            }
        }
    }
}

.eye_specialist_our_services_section{
    padding: 50px 0px;
    min-height: 450px;
    background-color: rgb(243, 245, 247);
    
    .container{
        padding: 40px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 20px;
        min-height: 640px;
        position: relative;
        width: auto;
   } 
}

.eye_specialist_hospital_map{
    min-height: fit-content;

    .container{
        height: inherit;
        position: relative;

        .map-informations {
            z-index: 0;
            background-color: white;
            max-width: 450px;
            max-height: 450px;
            padding: 50px;
            position: absolute;
            top: 45px;
            left: 10px;
            border-radius: 2rem;

            h4{
                color: #967F65;
                font-size: 25px;
                font-weight: 700;
            }

            p{
                color: black;
                line-height: 1.1;
                margin-bottom: 0px;
                font-size: 15px;
            }

            .address_box{
                display: flex;
                align-items: flex-start;
                margin-top: 24px;
            }

            .contact_box{
                display: flex;
                align-items: center;
                margin-top: 12px;
            }

            .email_box{
                display: flex;
                align-items: center;
                margin-top: 12px;
            }

            .direction_box{
                display: flex;
                margin-top: 30px;

                .waze_box{
                    margin-right: 18px;
                    text-align: center;

                    img{
                        max-width: 50px;
                        background-color: #ECEEF0;
                        border-radius: 50%;
                        padding: 10px;
                    }
                }

                .google_box{
                    text-align: center;

                    img{
                        max-width: 50px;
                        background-color: #ECEEF0;
                        border-radius: 50%;
                        padding: 10px;
                    }
                }
            }

        }
    }

}

.custom-select {
    position: relative;
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px 0px #B7BBCA5C;
    width: 100%;
    cursor: pointer;

    .selected-option {
        font-size: 18px;
        font-weight: 700;
        color: black;
    }

    .options {
        position: absolute;
        background: white;
        box-shadow: 0px 4px 10px 0px #B7BBCA5C;
        width: 100%;
        left: 0;
        top: 60px;
        border-radius: 15px;
        z-index: 2;
        max-height: 250px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .options-list {
            border-bottom: 1px solid lightgray;
            padding: 15px;
            cursor: pointer;
            color: black;


            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                color: white;
                background: #5775D3;
            }
        }
    }
}

.joined-hospital {
    background: white;
    position: relative;
    max-width: 500px;
    padding: 20px;
    border-radius: 20px;
    margin: 10px;

    .joined-hospital-title {
        font-size: 20px;
        color: black;
        margin-bottom: 50px;
    }

    .joined-hospital-container-list {
        height: 250px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

    }

    .joined-hospital-list {
        background: rgb(245, 245, 245);
        border-radius: 100px;
        text-align: center;
        padding: 10px 0px;
        // width: 500px;
        color: black;

        &:hover {
            color: white;
            background: #5775D3;
        }
    }
}

.disable-scroll {
    overflow: hidden;
}

#dropdown_menu_select_hospital{
    border: 0;
    width: 400px;
    padding: 15px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0px 4px 10px 0px #B7BBCA5C;

    #option_text{
        font-size: 14px;
        font-weight: 600;
    }
}

@media (max-width: 1024px) {
    .eye_specialist_hero_section .banner-content {
        left: 30%;
    }
}

@media (max-width: 768px) {
    .eye_specialist_hero_section {
        height: 250px;

        .banner-content {
            left: 40%;
        }
    }
}

@media (min-width: 481px) {
    .eye_specialist_hero_section #spec-centre-hosp-button, .eye_specialist_contact_section_mv{
        display: none;
    }  
    
    .speciality_mob {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .eye_specialist_hero_section #dropdown_menu_select_hospital, .eye_specialist_contact_section{
        display: none;
    }

    .speciality_web {
        display: none !important;
    }

    .eye_specialist_hospital_map{
        display: none !important;
    }

    .eye_specialist_hero_section {
        height: 200px;
        background-size: cover;
        background-position: center;
    }

    .eye_specialist_hero_section .banner-content {
        text-align: left;
        width: fit-content !important;
        transform: none;
        display: flex;
        align-items: center;
        height: inherit;
        position: unset;
    }

    .eye_specialist_hero_section #spec-centre-hosp-button{
        position: absolute;
        box-shadow: 0 4px 10px 0 rgba(183, 188, 202, 0.36);
        background: white;
        width: 90vw;
        bottom: -26px;
        height: 50px;
        border-radius: 32px;
        border: 0;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
    }

    #our-doctors, #services {
        border-right: 0 !important;
        border-bottom: 0 !important;
    }

    .eye_specialist_bid_section .container {
        display: flex;
        flex-direction: column;
        flex-flow: column-reverse;
    }

    .eye_specialist_bid_section .container {
        .bid_content{
            width: 100%;
            text-align: center;
            margin-top: 20px;

            img {
                width: 50% !important;
                position: relative;
                left: 25%;
            }
    
        }
        
        img {
            width: 100% !important;
        }
    }
    

    .eye_specialist_bid_section .container .bid_content #who_we_are_btn {
        width: 300px;
    }

    .eye_specialist_bid_section .container .bid_content .who-we-are-container-mv {
        display: flex;
        justify-content: center;
    }

    .eye_specialist_our_services_section .container {
        margin: 10px;
        padding: 20px;

        .esoss_box{
            width: 307px !important;
        }

        h2 {
            color: white;
            margin-bottom: 20px;
            font-size: 25px !important;
            font-weight: 700;
            line-height: 31px;
            width: 176px;
        }

        p {
            line-height: 15px !important;
            font-size: 13px !important;
            width: 302px;
        }
    }

    .individual-hospital-insight-corner-section .container .insight-corner-title-subs {
        text-align: center;
    }

    .custom-select {
        margin-inline: auto;
    }
}