.individual-csr-content {
    background-color: #f3f5f7;

    .container {
        // display: grid;
        // grid-template-columns: 1fr 3fr 1fr; /* Three columns with equal width */
        // gap: 50px; /* Gap between grid items */
        padding: 50px 0;

        .content-thumbnail {
            border-radius: 20px;
            max-width: 300px;
        }
    }
}