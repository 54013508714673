.event_upcoming_listing_section {
    padding-top: 50px;

    .container{
        display: flex;

        .event_upcoming_list_content {
            width: 260px;
            margin-top: 40px;
        }
    }  
}

.my_collections_events_card {
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    height: 100%;
    position: relative;

    img {
        border-radius: 15px;
        margin-bottom: 18px;
        width: 100%;
        aspect-ratio: 1/1;
    }
    #event_name {
        font-size: 20px;
        font-weight: 600;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // height: 2.4em;
        line-height: 1.2em;
        margin-bottom: 10px;
        color: black;
    }

    p#event_espisode {
        font-weight: 700;
        color: var(--primary-color);
    }
    p#event_writer {
        margin: 0;
        line-height: 0;
    }
    #event_content {
        margin-bottom: 48px;
        height: 42px;

        p{

            font-size: 14px;
            line-height: 1.1em;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 2.2em;
            overflow: hidden;
            color: #606060;
        }

        a {
            font-size: 10px;
            line-height: 1.1;
            color: #39B5B2;
            font-weight: 700;
            text-decoration: none; /* Optional: To remove underline */
            //pointer-events: none;
            :hover {
                color: #39B5B2;
            }
        }
        
        a:hover {
            color: #39B5B2 !important;
        }
    }

    #event_content-2 {
        p{

            font-size: 14px;
            line-height: 1.1em;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            height: 3.3em;
            overflow: hidden;
            color: #606060;
        }

        a {
            font-size: 10px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
        }
    }


    #event_writer {
        font-size: 14px;
        font-weight: 700;
    }
    #event_date {
        font-size: 14px;
        color: #606060;
        margin-bottom: 0;
        position: absolute;
        bottom: 30px;
    }
    .event_image {
        position: relative;
        svg {
            color: white;
        }
    }
    .event_play_icon {
        position: absolute;
        background: #E98076;
        bottom: 10px;
        right: 10px;
        border-radius: 50%;
    }
    img {
        margin-bottom: 0;
    }
    button {
        border: 0;
        background: transparent;
        color: var(--primary-color)
    }
    .event_details {
        display: flex;
        flex-direction: column;
        // height: 100%;
        padding: 25px 15px;
        // width: 250px;

    }
    .event_image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        /* Optional: Adjust the height if needed */
    }
    .event_date_group {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: flex-end;
    }
}

.my_collections_events_card_listing {
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    height: fit-content;

    img {
        border-radius: 15px;
        margin-bottom: 18px;
        height: 100%;
        aspect-ratio: 1/1;
    }
    #event_name {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2em;
        margin-bottom: 10px;
        color: black;
        text-wrap: balance;
    }

    p#event_espisode {
        font-weight: 700;
        color: var(--primary-color);
    }
    p#event_writer {
        margin: 0;
        line-height: 0;
    }
    #event_content {
        p{

            font-size: 14px;
            line-height: 1.1em;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 2.2em;
            overflow: hidden;
            color: #606060;
        }

        a {
            font-size: 10px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
        }
    }

    #event_content-2 {
        p{

            font-size: 14px;
            line-height: 1.1em;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            height: 3.3em;
            overflow: hidden;
            color: #606060;
        }

        a {
            font-size: 10px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
        }
    }


    #event_writer {
        font-size: 14px;
        font-weight: 700;
    }
    #event_date {
        font-size: 14px;
        color: #606060;
        // margin-bottom: 0px;
        // line-height: normal;

        // height: 100%;
        // display: flex;
        // align-items: flex-start;
        // flex-direction: column;
        // justify-content: flex-end;
    }
    .event_image {
        position: relative;
        svg {
            color: white;
        }
    }
    .event_play_icon {
        position: absolute;
        background: #E98076;
        bottom: 10px;
        right: 10px;
        border-radius: 50%;
    }
    img {
        margin-bottom: 0;
    }
    button {
        border: 0;
        background: transparent;
        color: var(--primary-color)
    }
    .event_details {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10%;

        a {
            //font-size: 10px;
            line-height: 1.1;
            color: #39B5B2;
            font-weight: 700;
        }

        a:hover {
            color: #39B5B2 !important;
        }
    }
    .event_image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        /* Optional: Adjust the height if needed */
    }
    .event_date_group {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: flex-end;
    }
}

@media (max-width: 1024px) {
    .my_collections_events_card_listing .event_details {
        padding: 15px;
    }

    .event_upcoming_listing_section {
        padding-top: 0;
        margin-bottom: 50px;
    }

    .event_listing_section{
        margin-top: 50px;
    }

    .event_upcoming_listing_section .container {
        display: flex;
        flex-direction: column;
    }

    .event_upcoming_listing_section .container .event_upcoming_list_content {
        width: 100%;
        margin-top: 0px;
    }
}

@media (max-width: 768px) {
    .event_upcoming_listing_section {
        padding-top: 0;

        .container {
            flex-direction: column;
            margin-bottom: 30px;

            .event_upcoming_list_content {
                width: 100%;
                margin-top: 0;
            }
        }
    }

    .event_listing_section {
        margin-top: 30px;
    }
    

}

@media (min-width: 481px) {
    .event_upcoming_listing_section .event_listing_mv{
        display: none !important;
    }
}


@media (max-width: 480px) {
    .event_upcoming_listing_section .event_listing_dv{
        display: none !important;
    }

    .event_upcoming_listing_section .event_upcoming_listing {
        text-align: center;
        padding: 0px 50px !important;
        margin-bottom: 20px;
    }

    .event_upcoming_listing_section .container {
        display: flex;
        flex-direction: column;
        padding: 0 0 70px 0;
        margin-bottom: 0;

        .euls_mobile {
            width: 100% !important;
        }

        h2 {
            font-size: 28px !important;
            font-weight: 700;
            line-height: 32px;
            text-align: center;
        }

        p {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            color: #606060;
            padding: 0 10px;
        }
    }

    .my_collections_events_card {
        margin: auto;
        width: 351px;
        height: 590px;

        img {
            margin-bottom: 27px;
            height: 312px;
            width: 321px;
            margin-inline: auto;
        }

        #event_name {
            font-size: 21px;
            font-weight: 700;
            text-align: left;
            padding: 0;
            height: 2.4em;
            margin-bottom: 48px;
        }

        #event_content {
            margin-bottom: 48px;
        }

        .event_description {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #606060;
        }

        #event_date {
            font-size: 15px ;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #666567;
            padding: 0;
        }

    }


    .my_collections_events_card_listing {
        background-color: #fff;
        padding: 10px;
        border-radius: 20px;
        height: 100%;
        width: 100%;
        margin-inline: auto;

        img {
            border-radius: 15px;
            height: 150px;
            width: 150px;
        }

        .event_details {
            display: flex;
            flex-direction: column;
            height: 100% !important;
            padding: 10px !important;
            width: 180px;
        }

        #event_name{
            font-size: 17px !important;
            display: contents;
        }

        #event_date{
            font-size: 10px !important;
        }
    
    }
    

    .my_collections_events_card .event_details {
        display: flex;
        flex-direction: column;
        height: 100% !important;
        padding: 10px !important;
    }

    .event_listing_section{
        margin-top: 10px;
    }

    .slick-dots {
        position: absolute;
        bottom: -30px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
    }

    .slick-dots li button:before {
        font-family: "slick";
        font-size: 10px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
