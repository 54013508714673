.individual-health-package-other-section {
    .slider-container-articles {
        .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin-left: unset;
            margin-right: unset;
        }
    }
}

.individual-doctor-other-section {
    .slider-container-articles-doctors {
        .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin-left: unset;
            margin-right: unset;
        }
    }
}

.individual-article-other-section {
    background-color: #EBEDEF;
    padding: 50px 0;

    .container {
        p {
            color: var(--primary-color);
            font-weight: 700;
        }
        a {
            position: relative;
        }

        h4 {
            color: black;
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        .slider-container-articles {
            .slick-track {
                position: relative;
                top: 0;
                left: 0;
                display: block;
                margin-left: unset;
                margin-right: unset;
            }
        }
    }

    .health-package-card {
        margin-left: 0;
        margin-right: 10px;
    }
    .title {
        display: flex;
        justify-content: space-between;
        position: relative;
        p {
            color: var(--primary-color);
            font-weight: 700;
        }
        a {
            position: relative;
        }
    }
    .article-other-group {
        display: flex;
        column-gap: 20px;
        
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none; 

        cursor: -webkit-grabbing;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    button {
        border: 0;
        background: 0;
        color: var(--primary-color);
        font-weight: 600;
    }
}

@media screen and (max-width: 768px) {

    .individual-doctor-other-section {
        padding: 20px 20px 40px 20px;
        .title,
        .doctor-group {
            left: 0;
            overflow: auto !important;
        }
        .find-a-doctors-card {
            width: 100%;
        } 
    }

    // .article-other-group {
    //     left: 0 !important;
    //     overflow: auto !important;
    // }

    .individual-article-other-section {
        background-color: #EBEDEF;
        padding: 40px;
    }
}

@media screen and (min-width: 481px) {
    
    .individual-article-information-section .individual_article_mobile {
        display: none;
    }

    .individual-health-package-other-section .individual_article_health_package_slider {
        display: none;
    }

    .individual-article-other-section .individual_article_article_slider {
        display: none;
    }
    
}

@media screen and (max-width: 480px) {

    .individual-article-information-section .individual_article_normal {
        display: none;
    }

    .individual-article-other-section .individual_article_article_slider_normal {
        display: none !important;
    }

    .individual-health-package-other-section .individual_article_health_package_slider {
        .slick-dots {
            position: absolute;
            bottom: -26px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }
    }

    .individual-article-other-section {
        background-color: #EBEDEF;
        padding: 50px 0;
    }

    .article-other-group .my_collections_articles_card .container img {
        border-radius: 15px;
        height: 100%;
        aspect-ratio: 1/1;
    }

    .article-other-group .my_collections_articles_card {
        background-color: #fff;
        padding: 5px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .article-other-group .my_collections_articles_card .container #article_date {
        font-size: 10px;
        margin-bottom: 0px;
        color: #666567;
        display: flex;
    }

    .article-other-group .my_collections_articles_card .container #article_writer {
        font-size: 13px;
        font-weight: 700;
        color: #666567;
        margin-bottom: 0px;
    }

    // .individual-article-other-section .article-other-group {
    //     display: unset; 
    //     column-gap: unset; 
    //     position: relative; 
    //     padding-left: 0; 
    //     overflow-x: unset; 
    //     padding: 10px;
    //     overflow-y: unset; 
    //     -ms-overflow-style: none;
    // }
}