.career-container{
  background-color: #f3f5f7;
  padding-bottom: 50px;
  .sort-by{
    .pointer{
      cursor: pointer;
      user-select: none;
    }
  }
  .banner{
    button.drop-resume{
      //background-image: linear-gradient(97.06deg, #967F65 2.38%, #F6E6D4 116.41%);
      background-color: #FCB11D;
      margin: 0 10px 0 0;
      padding: 15px;
      border: 0;
      border-radius: 15px;
      color: white;
      font-size: 20px;
      width: 250px;
    }
  }
  .filter-table{
    #filter-hospital{
      width: 500px;
      background-size: auto !important;
      background-position: right !important;
    }
  }
  .career-list{
    height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 4px;
      background: #d3d3d3;
    }
    &::-webkit-scrollbar-thumb{
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover{
      background: #555;
    }
    .career-item{
      color: #000;
      background-color: #fff;
      padding: 30px;
      border-radius: 20px;
      border: none;
      margin: 15px 0px;
      width: 90%;
      cursor: pointer;
      .new-label{
        background-color: #e78076;
        width: fit-content;
        border-radius: 5px;
        padding: 0px 15px;
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .title{
        margin-bottom: 1.8rem;
        color: #967F65;
      }
      p{
        display: inline;
        margin: 0px;
        color: #000;
      }
      span.date{
        font-weight: 700;
        color: #967F65;
        font-size: 13px;
      }
      &.active{
        border: 2px solid #967F65;
        width: 100%;
      }
    }
  }
  .career-detail{
    // margin: 10px 0px;
    padding: 30px;
    color: #000;
    border-radius: 20px;
    border: none;
    height: 500px;

    .career_description {
      overflow: auto;

      &::-webkit-scrollbar{
        width: 4px;
        background: #d3d3d3;
      }
      &::-webkit-scrollbar-thumb{
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover{
        background: #555;
      }
    }

    .title{
      font-weight: 600;
      color: #000;
    }
    .salary-title{
      font-size:20px;
      font-weight: 600;
    }
    p{
      margin: 0px;
      color: #000;
      font-size: 15px;
    }
    .career-detail-info{
      // span{
      //   display: block;
      // }
      p{
        display: inline;
        margin: 0px;
      }
      span.date{
        font-weight: 700;
        color: #967F65;
        font-size: 13px;
      }
    }
  }
  .career-form{
    ._card{
      color: #000;
      border-radius: 20px;
      border: none;
      background-color: #fff;
      .form-container{
        padding: 30px;
        .input-field{
          display: flex;
          flex-direction: column;
          background-color: #F5F5F5;
          border-radius: 5px;
          padding: 10px;
          margin-top: 10px;
          &.input-file-field{
            background-color: transparent;
          }
          label{
            color: black;
            font-size: 15px;
            line-height: 18px;
          }
          input, select{
            border: none;
            background-color: transparent;
            font-size: 15px;
            line-height: 18px;
            &[type="file"]{
              border: transparent;
            }
          }
        }
      }
    }
  }
  .career-form-popup{
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    background: #000000ba;

    ._card{
      color: #000;
      border-radius: .5rem;
      border: none;
      background-color: #fff;
      margin: auto;
      // height: fit-content;
      max-height: 98%;
      width: 500px;
      overflow: auto;

      &::-webkit-scrollbar{
        display: none;
      }
      // &::-webkit-scrollbar-thumb{
      //   background: #5675d3;
      // }
      // &::-webkit-scrollbar-thumb:hover{
      //   background: #3561e3;
      // }

      // width: 42em;

      .form-container{
        padding: 5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .title-container{
          background-color: #5675d3;
          color: #fff;
          padding: 2%;

          h5{
            margin: 0px;
            color: #fff !important; 
            // font-size: 1.3vw;
          }

          .close-btn{
            cursor: pointer;
          }
        }

        .career-form-container {
          // display: flex;
          // flex-direction: column;
          // justify-content: space-evenly;
          
        }

        .job-title{
          font-size: 18px;
          font-weight: 700;
          color: rgb(86, 117, 211);
        }

        // .input-field {
        //   margin-bottom: 3%;
        // }

        // label {
        //   font-size: 1.2vw;
        // }

        // input {
        //   font-size: 1.2vw;
        //   padding: 2%;

        //   &::placeholder{
        //     font-size: 1.2vw;
        //   }
        // }

        // select {
        //   font-size: 1.2vw;
        //   padding: 2%;
        // }
      }
    }
  }
}

@media (min-width: 481px) {
  .career_form_mob {
    display: none !important;
  }
} 

@media (max-width: 480px) {
  .career_form_web {
    display: none !important;
  }

  .career-container {
    .career-form{
      ._card{
        .form-container{
          .input-field{
            &.input-file-field{
              flex-direction: column !important;
            }
          }
        }
      }
    }
    .career-list {
      height: 230px;
      margin-bottom: 10px;
      .career-item{
        width: 100%;
        p{
          display: block;
        }
      }
    }
  }

  .career-detail-info{
    margin-top: .5rem;
  }
} 