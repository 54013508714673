.symptom-checker-hero-section{
    background-size: 100%;
    position: relative;
    height: 390px;

    .container{
        height: inherit;
        display: flex;
        align-items: center;

        h1{
            color: black;
        }
    }
}

.aggressive-tab {
    margin-top: 30px;
    .css-heg063-MuiTabs-flexContainer {
        justify-content: flex-end;
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: #967F65;
    }
    .tab-content {
        h3 {
            margin-top: 30px;
        }
    }

    .css-1aquho2-MuiTabs-indicator {
        background-color: #967F65;
    }
}

.sympton-page {
    .sympton_check_outer {
        top: -120px;
        position: relative;
        padding: 25px 0;

        .sympton_check_section {
            background: white;
            padding: 90px 80px;
            border-radius: 40px;

            .search_group {
                width: 300px;
                margin-left: auto;
                border: 1px solid var(--primary-color);
                border-radius: 20px;
                padding: 5px;

                input {
                    width: 250px;
                    border: 0;
                    height: 100%;
                    background: transparent;
                }

                .search-icon {
                    border: 1px solid var(--primary-color);
                    background: var(--primary-color);
                    color: white;
                    display: flex;
                    padding: 4px 10px;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                }

                svg {
                    font-size: 19px;
                }
            }

            .body {
                .row {
                    display: flex;
                    align-items: flex-end;
                    .male {
                        background: black;
                        width: 120px;
                        height: 400px;
                    }
                    .female {
                        background: black;
                        width: 120px;
                        height: 300px;
                    }
                    .child {
                        background: black;
                        width: 120px;
                        height: 200px;
                    }
                }
            }

            .organ_group {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-gap: 10px;
                &.active {
                    background-color: red;
                }
                .organ_cube {
                    background-color: #F3F5F7;
                    padding: 10px;
                    border-radius: 10px;

                    input {
                        display: none
                    }

                    &.checked {
                        background-color: var(--primary-color);
                    }
                    
                    .organ_label {
                        color: black;
                        label {
                            color: black;
                        }
                        &.checked {
                            color: white;
                        }
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                    }
                    .center-image {
                        margin-left: auto;
                        margin-right: auto;
                      }
                }
            }

            .sympton_group {
                display: grid;
                grid-template-columns: repeat(8, 1fr);
                grid-gap: 10px;
                &.active {
                    background-color: red;
                }
                .sympton_cube {
                    background-color: white;
                    padding: 5px;
                    border-radius: 20px;
                    border: 1px solid;
                    text-align: center;
                    input {
                        display: none
                    }
                    &.checked {
                        background-color: black;
                    }
                    .sympton_label {
                        color: black;
                        &.checked {
                            color: white;
                        }
                        text-align: center;
                    }
                }
            }
            .select_sympton_side {
                padding-left: 50px;
            }
        }
    }
    .search-sympton {
        position: absolute;
        bottom: 0;
        right: 10%;
    }
}

.aggressive-sympton-section {
    img {
        width: 100%;
        border-radius: 20px;
    }
    .aggressive-image {
        position: relative;
    }
    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        background: #80808061;
        font-size: 3rem;
        span {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
        }
    }
    h2 {
        margin: 30px 0;
    }
}
.sympton-page {
    .sympton_check_outer {
        .sympton_check_section {
            h4 {
                margin-top: 30px;
            }
            .select_sympton_side {
                padding-left: 0px;
            }
        }
        .sympton_group {
            grid-template-columns: repeat(7, 1fr) !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .sympton-page {
        .sympton_check_outer {
            .sympton_check_section {
                h4 {
                    margin-top: 30px;
                }
                .select_sympton_side {
                    padding-left: 0px;
                }
            }
            .sympton_group {
                grid-template-columns: repeat(5, 1fr) !important;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .sympton-page {

        .sympton_check_outer {
            .sympton_check_section {
                .body {
                    .row {
                        .male {
                            width: 80px;
                        }
                        .female {
                            width: 80px;
                        }
                        .child {
                            width: 80px;
                        }
                    }
                }
                .organ_group {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 10px;
                }
                .sympton_group {
                    grid-template-columns: repeat(3, 1fr) !important;
                }
                .search_group
                {
                    width: 250px;
                    .search-icon {
                        border: 1px solid var(--primary-color);
                        background: var(--primary-color);
                        color: white;
                        display: flex;
                        padding: 0;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .aggressive-sympton-section {
        .aggressive-image {
            position: relative;
            margin-top: 30px;
        }
    }
}
