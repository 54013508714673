.dental_specialist_hero_section{
    background: url("./../img/slider/slider-bg-1.jpg");
    background-repeat: no-repeat;
    min-height: 400px;
    background-position: center top;

    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: inherit;

        h1{
            font-size: 50px;
            color: black;
            margin-bottom: 20px;
        }

    }
}

.dental_specialist_contact_section {
    padding: 70px 0;
    background-color: white;

    .container {

        #ambulance-logo{
            background-color: #E98076;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 15px 17px;
            text-align: center;
        }

        #contact{
            display: flex;
            flex-direction: row;

            p {
                margin-bottom: 0px;
            }

            h4 {
                color: #9D9C9C;
            }
        }

        #our-doctors, #services, #patient-care  {
            display: flex;
            flex-direction: column;

            a{
                color: #967F65;
                
            }

            img {
                position: relative;
                width: 50px;
                margin-top: 20px;
                left: 60%;
            }

        }

        #our-doctors, #services {
            border-right: 1px solid gray;
        }
    }
}

@media (max-width:"767px") {
    #our-doctors, #services {
        border-right: 0 !important;
        border-bottom: 1px solid gray;
    }
    
}

.dental_specialist_bid_section{
    padding: 50px 0px;
    background-color: rgb(243, 245, 247);

    .container{
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
        flex-wrap: wrap;

        h3{
            color: black;
        }

        #bid_subs{
            line-height: 1.1;
            margin-top: 40px;
        }

        #who_we_are_btn{
            border: 1px solid #967F65;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-radius: 25px;
            padding: 10px;

            #arrow_up_right{
                color: #967F65;
            }

            &:hover{
                background-color: #967F65;

                p, #arrow_up_right{
                    color: white;
                }
            }

            p{
                color: #967F65;
                font-size: 18px;
                margin-bottom: 0px;
                margin-left: 20px;

            }
        }

        img{
            border-radius: 20px;
            max-height: 400px;
        }
    }
}

.dental_specialist_our_services_section{
    padding: 50px 0px;
    background-color: rgb(243, 245, 247);

   .container{
    background-image: url("./../img/slider/slider-bg-1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    min-height: 450px;
    padding: 40px;
   } 
}