.teleconsult_hero_section {
    background-repeat: no-repeat;
    min-height: 400px;
    background-position: center top;
    background-size: cover;

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: inherit;

        h1 {
            color: black;
        }

        #make_an_appointment_btn {
            text-align: center;
            // background-image: linear-gradient(97.06deg, #967F65 2.38%, #F6E6D4 116.41%);
            background-color: #FCB11D;
            border-radius: 15px;
            margin-top: 10px;
            padding: 15px;
        }
    }
}

.telemedicine_why_section {
    background-color: rgb(243, 245, 247);
    padding: 50px 0px;
    position: relative;

    @media screen and (max-width: 768px) {
        padding-bottom: 0;
    }

    .tele_why_image_box {
        height: 600px;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .telemedicine_why_wrapper {
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;

            .tele_why_image_box {
                height: auto;
                width: 250px;
                margin: 0 auto;
            }

            .telemedicine_why_contents {
                margin-top: 40px;
            }

            .telemedicine_why_sections {
                flex-direction: column;

                .tele_why {
                    margin-top: 24px !important;
                }

                #content_title {
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .telemedicine_works_section {
        @media screen and (max-width: 768px) {
            padding: 32px 0;
            .telemedicine_works_arrow {
                display: none;
            }
        }
    }

    .container {
        .telemedicine_why_contents {
            width: 60%;
            .tele_why {
                max-width: 250px;
                margin-top: 100px;
                margin-right: 30px;

                #content_no {
                    color: #967F65;
                    margin-bottom: 10px;
                }

                #content_title {
                    font-size: 22px;
                    font-weight: 700;
                    min-height: 50px;
                }

                #content_subs {
                    line-height: 1.2;
                    color: gray;
                }
            }
        }
    }
}

.telemedicine_works_section {
    background-color: #ebedef;
    padding: 100px 0px;

    .container {
        h2 {
            color: black;
        }
    }
}

.teleconsult_faq_section {
    background-color: rgb(243, 245, 247);
    padding: 90px 0px;

    .container {
        display: flex;
        h2 {
            color: black;
        }

        .accordion-content {
            border-bottom: 1px solid black;
        }

        .accordion-header {
            cursor: pointer;
            padding: 12px;
            font-size: 18px;
            font-weight: bold;
            color: #967F65;

            .symbol {
                position: relative;
                font-size: 18px;
                margin-left: -22px;
                margin-right: 40px;
            }
        }

        .accordion-content {
            padding: 12px;
        }
    }
}

@media screen and (max-width: 768px) {
    .teleconsult_faq_section {
        padding: 32px 0px;
    }

    .teleconsult_faq_wrapper {
        flex-direction: column;
    }
}
