.insights-corner-area {
  background: rgb(243, 245, 247);
  padding-top: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 760px;
  position: relative;

  .container {
    .title-subtitle {
      text-align: center;
      position: relative;
      z-index: 1;
      top: -90px;

      .title {
        padding-bottom: 10px;
        p {
          font-size: 40px;
          color: black;
          font-weight: bold;
        }
      }

      .subtitle {
        padding-bottom: 25px;

        p{
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .insights-corner-contents {
      display: flex;
      padding-top: 60px;
      justify-content: space-around;
      img {
        width: 100%;
        position: relative;
        z-index: 1;
      }
    }
  }
}

.insights-corner-area::after {
  content: "";
  position: absolute;
  top: -110px;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, #f3f5f7 25.12%, rgba(228, 232, 233, 0) 97.96%);
}

@media (max-width: 768px) {
  .insights-corner-area {
    height: 630px;
  }

  .insights-corner-contents {
    display: flex !important;
    flex-direction: row !important;
    row-gap: 10px;
    align-items: center;

    img {
      width: 100% !important;
    }
  }
}

@media (min-width: 481px) {
  .insights-corner-area{
    .insights-corner-contents-mv{
      display: none;
    }
  }
}

@media (max-width: 480px) {

  .insights-corner-area::after {
    top: -25px;
  }

  .insights-corner-area{
    .insights-corner-contents{
      display: none !important;
    }
  }

  .insights-corner-area {
    background: #f3f5f7;
    padding-top: 0px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 708px;
  }

  .insights-corner-area .container .title-subtitle .title p {
    font-size: 25px;
    color: black;
    font-weight: 700;
  }

  .insights-corner-area .container .title-subtitle .subtitle p {
    font-size: 12px;
    color: #606060;
  }

  .insights-corner-area .container .title-subtitle {
    top: 0px; 
}

}

