.kpj-hero-area {
  background-repeat: no-repeat;
  padding: 69px;
  background-size: cover;

  .hospital-banner-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container {
    display: flex;
    height: 100%;
    align-items: center;

    #our-hospitals {
      padding-bottom: 20px;
      h1 {
        color: black;
        font-size: 56px;
        font-weight: 700;
      }
    }

    #hospitals-state {
      border: 0;
      padding: 10px 30px;
      border-radius: 15px;
      width: 350px;
      height: 60px;
      font-size: 20px;
      font-weight: 600;
      box-shadow: 0 4px 10px 0 rgba(183, 187, 202, 0.36);
      margin-bottom: 20px;

      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-size: 7%;
      background-position-x: 85%;
      background-position-y: 18px;
    }

    select option:focus {
      background-color: #fff;
    }
  }
}



.hospitals-listing-area {
  padding-top: 100px;
  background-color: rgb(243, 245, 247);

  .container {
    text-align: center;
    // display: flex;
    // flex-wrap: wrap;
    // gap: 15px;

    .hospital-listing-pagination {
      display: inline-block;
      background-color: white;
      border-radius: 10px;
      padding: 15px;
      margin: 7%;
      box-shadow: 0px 4px 10px 0px #B7BBCA5C;


      a {
        color: black;
        padding: 5px 8px;
        margin-right: 5px;

        &:hover {
          background-color: rgb(243, 245, 247);
          border-radius: 5px;
        }

        &.active {
          background-color: #FCB11D;
          color: white !important;
          border-radius: 5px;
        }

      }
    }
  }
}

.single-hospital-area {
  background-color: white;
  border-radius: 30px;
  padding: 10px;
  // margin-bottom: 20px;
  max-width: 310px;
  height: 282px;

  .hospital-image {
    position: relative;
    // margin-bottom: 15px;

    img {
      width: 100%;
      // aspect-ratio: 1/1;
      // height: 300px;
      height: 180px;
      border-radius: 20px;
    }
  }

  .hospital_name_p{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0em;
    line-height: 1.1;
    text-align: left;
    padding: 15px;
  }

  &:hover {
    border: 1.04px solid #967F65;
    box-shadow: 0px 4px 10px 0px #B7BBCA80;
  }


}

@media (max-width: 1280px) {
  // .single-hospital-area {
  //   height: 335px;
  // }
}

@media (max-width: 1024px) {
  .kpj-hero-area {
    background-size: cover;
  }

  .single-hospital-area {
    // height: 307px;

    .hospital_name_p {
      font-size: 16px;
    }
  }
}


@media (min-width: 481px) {
  .kpj-hero-area .hospital_select_mob_view {
    display: none !important;
  }
}

@media (max-width: 480px) {

.single-hospital-area .hospital_name_p {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.1;
  text-align: left;
  padding: 10px;
}

  .kpj-hero-area .hospital_select_web_view {
    display: none !important;
  }

  .kpj-hero-area .hospital-banner-content {
    position: relative;
    width: -webkit-fill-available;
    
    h1{
      margin-bottom: 0 !important;
      font-size: 25px !important;
    }
  }

  .kpj-hero-area{
    height: 200px;
    background-size: cover !important;
    background-position: center;
    padding: 0;

    .container{
      padding: 0px 20px;
      height: 200px;
      position: relative;
      display: flex;

      #our-hospitals{
        padding-bottom: 0px;
        display: flex;
        align-items: center;
      }

      #hospitals-state{
        position: absolute;
        bottom: -130px;
        border-radius: 30px;
        font-size: 15px;
        padding: 10px 20px;
        height: 48px;
        width: 100%;
        box-shadow: 0px 4px 10px 0px #B7BBCA5C;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-size: 7%;
        background-position-x: 90%;
        background-position-y: 13px;
        background-color: #ffffff;

        // option:checked{
        //   background: white !important;
        //   color: black;
        // }

        // option:checked {
        //   background: black;
        // }
      }

      #search-hospital-btn{
        display: none;
      }
    }
  }

  .hospitals-listing-area{
    padding-top: 48px;
  }

  .single-hospital-area {
    width: 100%;
    height: 100%;
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0px 1.6700586080551147px 4.175146579742432px 0px #B7BBCA80;

    .hospital-image {
      img {
        border-radius: 10px;
      }
    }

    .hospital-name {
      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        text-align: left;        
      }
    }
  }

  .hospitals-listing-area .container {
    gap: 13px;
  }
}

@media (max-width: 375px) {
  .single-hospital-area {
    // height: 220px;

    .hospital_name_p {
      font-size: 12px;
    }
  }
}
