.investor-home-hero-section {
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    display: flex;

    .title_container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }

    h1 {
        color: white;
        padding-left: 8rem; 
        text-align: left; 
        width: 50%;
    }
}

.investor-hospital-container{
    //margin-top: 130px;
    position: relative;
    width: 85vw;
    display: flex;
    align-items: center;
    //justify-content: space-between;

    .content-container{
        // position: absolute;
        // top: 250px;
        // right: 0;
        max-width: 475px;
        margin-inline: 10px;

        h2.who-we-are{
            margin-left: 80px;
        }

        p {
            margin-top: 30px;
            margin-left: 80px;
            line-height: 1.5;
            text-align: justify;
        }

        h2 {
            font-size: 40px;
            margin-left: 80px;
            text-align: justify;
        }
    }

    .learn-more {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        color: #967F65;
        margin-left: 80px;
        display: flex;
        align-items: center; /* Ensure items are aligned in the row */

        .learn-more-img {
            height: 14px;
            margin-left: 15px;
        }
    }
}  

.investor-home-section {
    background-color: white;
    overflow-x: hidden;

    .invest-home-info-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .info-card {
            background-size: cover;
            background-repeat: no-repeat;
            height: 500px;
            width: 25%; /* Adjust the percentage as needed to fit your layout */
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            text-align: start; /* Center text inside the card */
            transition: background-color 0.3s, color 0.3s;
        }

        .overlay-info-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: red;
            opacity: 0;
            transition: opacity 0.3s;
        }

        .overlay-info-card-0 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #9747FF;
            opacity: 0;
            transition: opacity 0.3s;
        }

        .overlay-info-card-1 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #003C50E5;
            opacity: 0;
            transition: opacity 0.3s;
        }

        .overlay-info-card-2 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #39B5B2E5;
            opacity: 0;
            transition: opacity 0.3s;
        }

        .overlay-info-card-3 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #F06661E5;
            opacity: 0;
            transition: opacity 0.3s;
        }

        .info-card:hover .overlay-info-card-0 {
            opacity: 0.4;
        }

        .info-card:hover .overlay-info-card-1 {
            opacity: 0.4;
        }

        .info-card:hover .overlay-info-card-2 {
            opacity: 0.4;
        }

        .info-card:hover .overlay-info-card-3 {
            opacity: 0.4;
        }

        .info_card_subs {
            height: 100%; /* Ensure it takes the full height of the parent */
            width: 100%; /* Ensure it takes the full width of the parent */
            display: flex;
            flex-direction: column;
            align-items: center; /* Center content vertically */
            justify-content: center; /* Center content horizontally */
            position: relative; /* Positioning relative to the parent */
            text-align: start;
        }

        .info-card:hover .info_card_subs {
            margin-bottom: 60px;
        }

        .title-hover {
            opacity: 1;
            color: white;
            transition: color 0.3s; /* Transition for color change */

            p {
                color: white;
                font-weight: 400;
                font-size: 32px;
                align-items: center;
                text-align: start;
                margin: 0;
            }
        }

        .info-card:hover .title-hover {
            color: red; /* Change text color on hover */
        }

        .info-card:hover .title-hover p {
            color: red; /* Change paragraph color on hover */
        }

        .icon-hover {
            opacity: 0;
            transition: opacity 0.3s;
            position: absolute;
            left: 40%;
            top: 40%;
            color: #967F65;
            background-color: white;
            border-radius: 50%;
        }

        .info-card:hover .icon-hover {
            opacity: 1;
        }

        .number_title_subs {
            height: 80%; /* Take the full height of the parent */
        }

        .number_title_subs_hover {
            height: 80%;
        }
    }

    .invest-home-info-gallery-mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .info-card {
            background-size: cover;
            background-repeat: no-repeat;
            height: 300px;
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: start;
        }

        .overlay-info-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #00000080;
            opacity: 0;
        }

        .info_card_subs {
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            position: relative;
            text-align: start;
        }

        #number_title_subs {
            height: 80%;
        }

        .panel {
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            height: 70vh;
            flex: 0.4;
            position: relative;
            cursor: pointer;
            transition: all 0s;

            &:not(.active) .overlay-info-card {
                opacity: 1;
            }

            &.active {
                flex: 1;

                .info_card_subs_0 {
                    background-image: linear-gradient(0deg, #371450 5.14%, rgba(55, 20, 80, 0) 58.14%);
                    position: absolute;
                    bottom: 0;
                    justify-content: center;
                    height: 100%;

                    p {
                        opacity: 1;
                    }

                    #number_title_subs {
                        position: absolute;
                        bottom: 100px;
                        height: 100%;
                        h3 {
                            color: white;
                        }
                    }

                    #arrow_circle_icon {
                        opacity: 1;
                    }
                }

                .info_card_subs_1 {
                    background-image: linear-gradient(0deg, #39B5B2 5.14%, rgba(57, 181, 178, 0) 58.14%);
                    position: absolute;
                    bottom: 0;
                    height: 100%;

                    p {
                        opacity: 1;
                    }

                    #number_title_subs {
                        position: absolute;
                        bottom: 100px;
                        height: 100%;
                        h3 {
                            color: white;
                        }
                    }

                    #arrow_circle_icon {
                        opacity: 1;
                    }
                }

                .info_card_subs_2 {
                    background-image: linear-gradient(0deg, #FCB11D 5.14%, rgba(252, 177, 29, 0) 58.14%);
                    position: absolute;
                    bottom: 0;
                    height: 100%;

                    p {
                        opacity: 1;
                    }

                    #number_title_subs {
                        position: absolute;
                        bottom: 100px;
                        height: 100%;
                        h3 {
                            color: white;
                        }
                    }

                    #arrow_circle_icon {
                        opacity: 1;
                    }
                }

                .info_card_subs_3 {
                    background-image: linear-gradient(0deg, #FCB11D 5.14%, rgba(252, 177, 29, 0) 58.14%);
                    position: absolute;
                    bottom: 0;
                    height: 100%;

                    p {
                        opacity: 1;
                    }

                    #number_title_subs {
                        position: absolute;
                        bottom: 100px;
                        height: 100%;
                        h3 {
                            color: white;
                        }
                    }

                    #arrow_circle_icon {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.investor-home-bottom-section{
    background-color: white;
    overflow-x: hidden;
    margin-bottom: 10px;

    @keyframes hoverBackground {
        0% {
          background-position: 100% 0;
        }
        100% {
          background-position: 0 0;
        }
    }

    .invest-home-bottom-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        margin-top: 100px;
      }
      
      .bottom-card {
        background-size: cover;
        background-repeat: no-repeat;
        height: 500px;
        width: 30%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        text-align: start;
        transition: color 0.3s ease-in-out;
      }
      
        &:hover {
          color: red; /* Set the color you want on hover */
        }
      
        .overlay-bottom-card {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to right, #003C508A 50%, transparent 50%);
          background-size: 200% 100%;
          background-position: 100% 0;
          transition: background-position 0.5s ease-in-out;
        }
      
        .bottom-card:hover .overlay-bottom-card {
          animation: hoverBackground 0.5s forwards;
        }
      
        .bottom_card_subs {
          height: 100%; /* Ensure it takes the full height of the parent */
          width: 100%; /* Ensure it takes the full width of the parent */
          display: flex;
          align-items: center; /* Center content vertically */
          justify-content: center; /* Center content horizontally */
          position: relative; /* Positioning relative to the parent */
          text-align: start;
        }
      
      #number_title_subs {
        height: 100%;
        padding: 150px 50px 50px 50px;
      }
      
      #number_title_subs h2 {
        margin-top: 250px;
        color: white;
        transition: color 0.3s ease-in-out;
      }
      
      .bottom-card:hover #number_title_subs h2 {
        color: purple; /* Set the text color you want on hover */
      }

    .invest-home-bottom-gallery-mobile{
        display: flex;
        flex-wrap: wrap;
        justify-content: center; 

        .bottom-card {
            background-size: cover;
            background-repeat: no-repeat;
            height: 300px;
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: start;
        }

        .overlay-bottom-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #00000080;
            opacity: 0;
        }

        .bottom_card_subs {
            height: 100%;
            width: 100%; 
            align-items: center;
            justify-content: center;
            position: relative;
            text-align: start;
          }
          
          #number_title_subs {
            height: 80%;
          }
    }
}

@media (min-width: 1025px) {
    .ih_mob_web {
      display: none !important;
    }

    // .investor-home-hero-section {
    //     //height: 450px;
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     display: flex;
    //     justify-content: flex-end;
    // }

    .investor-home-section .invest-home-info-gallery-mobile {
        display: none;
    }

    .investor-home-bottom-section .invest-home-bottom-gallery-mobile {
        display: none;
    }
    
}
  
  @media (max-width: 1024px) {
    .ih_hero_web {
      display: none !important;
    }
  
    // .investor-home-hero-section {
    //   //height: 450px;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   display: flex;
    //   justify-content: flex-end;
    // }
  
    .ih_mob_web {
        .ih_mob_title{
            font-weight: 700;
            font-size: 40px;
            line-height: 50px;
            color: white;
            padding-top: 6rem;
            padding-left: 8rem;
            text-align: left; /* Aligns text inside h1 to the right */
            //width: 50%;
        }
    }

    .investor-hospital-container .content-container {
        top: 110px;
        width: 580px;

        .p{
            margin-top: 30px;
            margin-left: 80px;
            line-height: 1.5;
            text-align: justify;
        }

        h2 {
            font-size: 40px;
            margin-left: 80px;
            text-align: justify;
        }
    }

    .investor-home-section .invest-home-info-gallery {
        display: none;
    }

    .investor-home-bottom-section .invest-home-bottom-gallery {
        display: none;
    }
  }
  
  @media (max-width: 768px) {
    .investor-home-hero-section {
      //height: 400px;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-end;
    }

    .investor-hospital-container .content-container {
        top: 110px;
        width: 580px;

        .p{
            margin-top: 30px;
            margin-left: 80px;
            line-height: 1.5;
            text-align: justify;
        }

        h2 {
            font-size: 40px;
            margin-left: 80px;
            text-align: justify;
        }
    }

    .investor-home-section .invest-home-info-gallery {
        display: none;
    }

    .investor-home-bottom-section .invest-home-bottom-gallery {
        display: none;
    }
  }
  
  @media (max-width: 480px) {
  
    .investor-home-hero-section {
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
      background-position: center;
      display: flex;
      justify-content: flex-end;
    }
  
    .ih_mob_web {
        .ih_mob_title{
            font-weight: 700;
            font-size: 20px;
            line-height: 28.75px;
            color: white;
            padding-left: 0rem; /* Adjust padding from the right */
            padding-bottom: 5rem; /* Adjust padding from the right */
            text-align: left; /* Align text inside h1 to the left */
            width: 45%;
        }
    }

    .investor-hospital-container {
        width: 100vw !important;
        display: flex;
        flex-direction: column;

        img {
            width: 100% !important;
            // padding: 10px 10px 0px 10px;
        }

        .content-container {
            position: unset;
            width: unset !important;
            h2.who-we-are{
                margin-left: 0px;
                padding-left: 10px;
            }
            p {
                font-size: 13px !important;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: justify;
                color: #606060;
                margin-top: 0px;
                margin-left: 0px;
                padding: 0px 10px 0px 10px;

            }

            h2 {
                font-size: 20px;
                margin-left:25%;
                text-align: justify;
                align-items: center; /* Ensure items are aligned in the row */
                justify-content: center;
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }

        .learn-more {
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            color: #967F65;
            display: flex;
            align-items: center; /* Ensure items are aligned in the row */
            justify-content: center;
            padding-bottom: 10px;
            margin-left: 0px;

            .learn-more-img {
                height: 8px;
                margin-left: 15px;
            }
        }
    }   

    .investor-home-section .invest-home-info-gallery {
        display: none;
    }

    .investor-home-bottom-section .invest-home-bottom-gallery {
        display: none;
    }
  }