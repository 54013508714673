.service_hero_kpj_section{
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;

    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: inherit;

        h1{
            margin-bottom: 20px;
        }

        span{
            color: #967F65;
            font-size: 18px;
            font-weight: 600;
        }

        .service-back-to{
            color: #39B5B2;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
}

.service_content_kpj_section{
    padding: 70px 0;
    background-color: rgb(243, 245, 247);

    .grid-container-service {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
        gap: 20px;
        padding: 20px 100px;

        .grid-item {
            background-color: #fff;
            border-radius: 15px;
            padding: 30px;
            min-height: 430px;
            text-align: center;

            img {
                max-width: 100%;
                height: 50px;
                width: auto;
            }

            h5{
                color: black;
                margin-top: 15px;
                font-weight: 700;
                font-size: 20px;
            }

            p{
                line-height: 1.5;
                margin-top: 10px;
                font-size: 15px;
                text-align: justify;
            }
        }
    }
}

@media (max-width: 1200px) {
    .service_content_kpj_section{
        .grid-container {
            grid-template-columns: repeat(3, 1fr); /* Change to 3 columns for smaller screens */
            padding: 20px;
        }
    }
}

@media (max-width: 1024px) {
    .service_hero_kpj_section {
        background-size: cover;
    }

    .service_content_kpj_section .grid-container-service {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .service_content_kpj_section{
        .grid-container {
            grid-template-columns: repeat(2, 1fr); /* Change to 2 columns for smaller screens */
            padding: 20px;
        }
    }
}

@media (max-width: 768px) {
    .service_content_kpj_section .grid-container-service {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .service_content_kpj_section{
        .grid-container {
            grid-template-columns: repeat(1, 1fr); /* Change to 1 columns for smaller screens */
            padding: 20px;
        }
    }

    .service_hero_kpj_section {
        height: 200px;
        background-size: cover;
        background-position: center;

        .container {
            h1 {
                font-size: 25px;
                font-weight: 700;
                line-height: 29px;
                color: black;
            }

            .service-back-to {
                color: #39B5B2;
                font-size: 10px;
                font-weight: 700;
                line-height: 11px;
            }
        }
    }

    .service_content_kpj_section {
        padding: 24px 11px;
    }

    .service_content_kpj_section .grid-container-service {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px !important;
        padding: 0;

        .grid-item {
            padding: 10px;
            min-height: 221px;

            h5 {
                font-size: 11px;
                font-weight: 700;
                line-height: 13px;
                color: black;
            }

            p {
                font-size: 7px;
                font-weight: 400;
                line-height: 9px;
                color: #606060;
            }
        }
    }
}