.international_patient_centre_header_section{
    background-color: rgb(243, 245, 247);
    padding-top: 50px;
    padding-bottom: 30px;

    .container{

        h1{
            color: black;
            margin-bottom: 20px;
            font-size: 60px;
        }

        p{
            color: black;
            line-height: 1.5;
            width: 60%;
        }

        .make_an_appointment_btn{
            border: 0;
            color: white;
            text-align: center;
            //background-image: linear-gradient(97.06deg, #967F65 2.38%, #F6E6D4 116.41%);
            background-color: #FCB11D;
            border-radius: 15px;
            margin-top: 10px;
            // max-width: 250px;
            padding: 10px 20px;
            font-weight: 600;
            position:relative;
            z-index:1;
            font-size: 18px;
        }

        .ipc_contents{
            background-color: #ACABA9;
            margin-top: 50px;
            padding: 30px;
            border-radius: 15px;
            padding: 100px 20px 100px 50px;
            position: relative;
            display: flex;
            flex-direction: row;
            width: -webkit-fill-available;


            h5{
                color: white;
                font-size: 25px;
                font-weight: 700;
                margin-bottom: 30px;
            }

            p{
                color: white;
                width: 100%;
            }

            #ipc_content_image{
                position: absolute;
                width: 35%;
                bottom: 0%;
                right: 6%;
            }
        }

    }
}

.international_patient_centre_carousel_section{
    background-color: rgb(243, 245, 247);
    padding-top: 50px;
    padding-bottom: 30px;

    .container{
        p{
            line-height: 1.2;
            margin-left: 70px;
            width: 88%;
        }
    }

}

.international_patient_centre_services_section{
    background-color: rgb(243, 245, 247);
    padding-top: 0px;
    padding-bottom: 30px;

    .container{
        border-radius: 20px;
        padding: 60px;
        background-position: top;
        background-size: cover;

        h3{
            color: white;
            margin-bottom: 30px;
        }

        p{
            color: white;
            line-height: 1.2;
        }
    }
}

.international_patient_application_section{
    background-color: rgb(243, 245, 247);
    padding-top: 50px;
    padding-bottom: 30px;
    
    .container{
        background-color: #fff;
        display: flex;
        flex-direction: row;
        width: 83%;
        padding-right: 0px;
        height: 100%;

        .ipc_application_form{
            padding: 47px;
            // width: inherit;

            h5{
                font-weight: 600;
                color: black;
                margin-bottom: 40px;
                font-size: 25px;
            }

            p{
                font-size: 15px;
                line-height: 1.2;
                color: black;

                span{
                    color: #967F65;
                    font-weight: 600;
                }
            }

            #form_name{
                display: flex;
                flex-direction: column;
                width: 290px;
                background-color: #F5F5F5;
                border-radius: 5px;
                padding: 10px;
                margin-right: 8px;

                input::placeholder {
                    padding-left: 0.5rem;
                }
            }

            #form_age{
                display: flex;
                flex-direction: column;
                width: 290px;
                background-color: #F5F5F5;
                border-radius: 5px;
                padding: 10px;

                input::placeholder {
                    padding-left: 0.5rem;
                }
            }

            #form_contactNo{
                display: flex;
                flex-direction: column;
                width: 290px;
                background-color: #F5F5F5;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;

                input::placeholder {
                    padding-left: 0.5rem;
                }
            }

            #form_email{
                display: flex;
                flex-direction: column;
                width: 290px;
                background-color: #F5F5F5;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;
                margin-right: 8px;

                input::placeholder {
                    padding-left: 0.5rem;
                }
            }

            #form_country{
                display: flex;
                flex-direction: column;
                width: 290px;
                background-color: #dbdde4;
                border-radius: 5px;
                padding: 10px;
                // margin-top: 10px;
                margin-right: 8px;
                //styling for country
                height: 100%;
                .css-fwk1hx-control{
                    top: -10px;
                    left: -8px;
                }
                //
            }

            #form_option{
                display: flex;
                flex-direction: column;
                max-width: 300px;
                background-color: transparent;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;
            }

            #form_other{
                display: flex;
                flex-direction: row;
                max-width: 300px;
                background-color: transparent;
                border-radius: 5px;
                padding: 10px 0 10px 0;
                margin-top: 10px;
            }

            #form_details{
                display: flex;
                flex-direction: column;
                max-width: 550px;
                background-color: transparent;
                border-radius: 5px;
                padding: 10px 0 10px 0;
                margin-top: 10px;
            }

            #form_submit{
                background-color: #967F65;
                padding: 10px;
                width: 200px;
                color: white;
                font-weight: 600;
                border: 0;
                border-radius: 20px;
                margin-top: 20px;
            }
        }
    }
}

.ipc_form_mob_view{
    #form_email{
        margin-right: 0px !important;
        width: 100% !important;
    }
    #form_contactNo, #form_email{
        width: 100% !important;
    }
}

/* Styling for the carousel container */
.carousel-container {
    width: 100%; 
    overflow: hidden;
    margin-top: 35px;
  }
  
  /* Styling for the carousel */
  .carousel {
    display: flex;
    transition: transform 1s ease;
  }
  
  /* Styling for carousel items */
  .services-item {
    height: 135px; 
    // width: 275px !important;
    margin: 20px;
    background-color: white;
    border-radius: 21px;
    padding: 20px;
    font-size: 14px;
    font-weight: 600;
    color: black;
    box-shadow: 0px 4px 10px 0px #B7BBCA5C;

  }

  .slider .slick-slide{
    margin: 0 10px;
  }

  .slider .slick-dots {
    position: absolute;
    bottom: 60px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}


  .accordion {
    width: 100%;
    margin: 0 auto;
  }
  
.accordion-item {
    margin-bottom: 5px;
    background-color: transparent;
    color: white;
    border: 0px;
    border-bottom: 1px solid white;


    .accordion-header {
        display: flex;
        width: 100%;
        padding: 10px;
        text-align: left;
        cursor: pointer;

        p {
            font-size: 20px;
            font-weight: 700;
            margin-left: 10px;
            margin-bottom: 0;
            color: #ffffff;
        }
    }

    .accordion-content {
        background-color: transparent;
        color: white;
        padding: 10px;

        p{
            color: white;
        }

        a {
            color: white;
        }
    }
}

.carousel_slider_container_web_view {
    .slick-slide{
        margin: 0 10px;
    }

    .slick-dots {
        position: absolute;
        bottom: 50px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
    }
}

@media (max-width: 1024px) {
    .international_patient_centre_header_section .container .ipc_contents {
        padding: 100px 20px 50px 50px;

        h5 {
            font-size: 22px;
        }

        p {
            font-size: 15px;
        }
    } 
}

@media (min-width: 769px) {
    .international_patient_application_section .ipc_form_mob_view {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .international_patient_application_section .ipc_form_web_view {
        display: none !important;
    }
    
    .international_patient_centre_header_section .container .ipc_contents {
        padding: 40px 20px 40px 50px;

        h5 {
            font-size: 18px;
        }

        .ipc_doctor_image {
            display: none;
        }
    }

    .international_patient_application_section .container .ipc_application_form {
        padding: 20px;
    }

    .international_patient_application_section {
        .container {
            padding: 0;
            flex-direction: column-reverse;
            width: 100%;
            height: fit-content;

            .ipc_application_form {
                padding: 47px 20px;

                #form_name {
                    width: 100%;
                    margin-right: 0;
                }

                #form_age {
                    width: 100%;
                }

                #form_country {
                    max-width: 100%;
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (min-width: 481px) {
    .international_patient_centre_header_section .mob_view {
        display: none !important;
    }

    // .international_patient_application_section .ipc_form_mob_view {
    //     display: none !important;
    // }

    .international_patient_centre_carousel_section .carousel_slider_container_mob_view {
        display: none !important;
    }

}

@media (max-width: 480px) {
    .international_patient_centre_header_section .web_view {
        display: none !important;
    }

    // .international_patient_application_section .ipc_form_web_view {
    //     display: none !important;
    // }

    .international_patient_centre_carousel_section .carousel_slider_container_web_view {
        display: none !important;
    }

    .international_patient_centre_header_section .container {
        h2 {
            color: black;
            margin-bottom: 17px;
            font-size: 32px;
            width: 218px;
            font-weight: 700;
            line-height: 36.8px;
        }

        p {
            color: black;
            line-height: 15.51px;
            width: 326px;
            font-size: 13px;
            font-weight: 400;
        }
    }

    .international_patient_centre_carousel_section {
        padding-top: 30px;
        padding-bottom: 60px;

        .container p {
            font-size: 13px;
            width: 327px;
            font-weight: 400;
            line-height: 15.51px;
            color: #606060;
            margin: auto;
            text-align: center;
            margin-bottom: 37px;
        }
    }

    .international_patient_centre_header_section .truncate_text {
        h5 , p {
            color: #fff;
        }
    }

    // .international_patient_application_section {
    //     .container {
    //         padding: 0;
    //         flex-direction: column-reverse;
    //         width: 100%;
    //         height: fit-content;

    //         .ipc_application_form {
    //             padding: 47px 20px;

    //             #form_name {
    //                 width: 100%;
    //                 margin-right: 0;
    //             }

    //             #form_age {
    //                 width: 100%;
    //             }

    //             #form_country {
    //                 max-width: 100%;
    //             }
    //         }
    //     }
    // }

    .services-item {
        width: 252px !important;
    }

    .international_patient_centre_services_section{
        .container{
            padding: 20px;
        }
    }

    .accordion-item {
        margin-bottom: 5px;
        background-color: transparent;
        color: white;
        border: 0px;
        border-bottom: 1px solid white;
    
    
        .accordion-header {
            p {
                font-size: 17px;
            }
        }
    
        .accordion-content {
            p{
                font-size: 15px;
            }
        }
    }

    .international_patient_application_section {
        .container {
            .ipc_application_form {
                h5 {
                    font-size: 18px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    } 
}
    
    
  
  