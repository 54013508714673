.individual-campaignspromotions-information-section, .individual-campaignspromotions-information-section-mv  {
    background-color: rgb(243, 245, 247);
    padding: 50px 0;
    .container {
        .campaignspromotions-content-2 {
            margin-top: 50px;
        }

        .campaignspromotions-image {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }

            #book-package {
                border: 1px solid #967F65;
                background-color: #967F65;
                width: 270px;
                border-radius: 30px;
                text-align: center;
                margin-top: 12px;
                cursor: pointer;
                p {
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 13px;
                    color: white;
                }
            }

            #add-to-cart {
                border: 1px solid #967F65;
                width: 270px;
                border-radius: 30px;
                text-align: center;
                margin-top: 12px;
                cursor: pointer;
                p {
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 13px;
                    color: #967F65;
                }
            }

            #download-full-broc {
                margin-top: 12px;
                cursor: pointer;
                p {
                    color: black;
                }
            }
        }

        .campaignspromotions-content-1 {
            .campaignspromotions-content-1-box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            h2 {
                padding-top: 5px;
                max-width: 600px;
            }
        }

        .campaignspromotions-content-2 {
            h3 {
                color: black;
                margin-bottom: 10px;
            }
            p {
                font-size: 18px;
                margin-bottom: 40px;
                line-height: 1.5;
                color: #666567;
                text-align: justify;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}


.individual-campaignspromotions-other-section{
    background-color: #EBEDEF;
    padding: 50px 80px;

    .container {
        h4 {
            color: black;
            margin-bottom: 30px;
            font-size: 25px;
            font-weight: 700;
        }

        .slider-container-events {
            .slick-track {
                position: relative;
                top: 0;
                left: 0;
                display: block;
                margin-left: unset;
                margin-right: unset;
            }
        }

        .grabbing-container {
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            overflow: auto hidden;
            cursor: -webkit-grabbing;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .campaignspromotions-card {
            background-color: white;
            padding: 10px;
            border-radius: 20px;
            // width: 97%;
            
            .container {
                .campaignspromotions-image {
                    width: 180px;
                    img {
                        border-radius: 10px;
                        max-width: 180px;
                        max-height: 180px;
                    }
                }
                .campaignspromotions-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    padding: 10px;
                    height: 180px;
                    width: 200px;
    
                    .desc-campro-text{
                        p{
                            color: #606060;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            line-height: 1.2em;
                            height: 3.6em;
                        }
                    }
                    
                    h3 {
                        color: #967F65;
                    }
                    p {
                        font-size: 12px;
                        margin-bottom: 15px;
                        line-height: 1.1;
                        display: contents;
                    }
                    h5 {
                        font-weight: 600;
                        color: black;
                    }
                    button {
                        border: 0;
                        background: transparent;
                        color: var(--primary-color);
                    }
    
                    .valid_date {
                        height: 20%;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: flex-end;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 768px) {
    .individual-campaignspromotions-information-section {
        padding: 50px 0;
    }

    .individual-campaignspromotions-other-section {
        h5 {
            font-weight: 600;
            font-size: 13px;
            color: black;
        }
        padding: 50px 0;
    }
}

@media (min-width: 481px) {
    .individual-campaignspromotions-information-section-mv, .recomm-campro-slider-mv {
        display: none;
    }

    .individual-campro-hero-section .back_to_mob {
        display: none !important;
    }

    .share-this-campaignspromotions-modal .campro_share_mob_view {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .individual-campaignspromotions-information-section, .recomm-campro{
        display: none;
    }

    .individual-campro-hero-section .back_to_web {
        display: none !important;
    }

    .share-this-campaignspromotions-modal .campro_share_web_view {
        display: none !important;
    }

    .individual-campaignspromotions-information-section-mv {
        background-color: #f3f5f7;
        padding: 20px 0px;
    }

    .individual-campaignspromotions-information-section-mv .container .campaignspromotions-image img {
        width: 100%;
        border-radius: 20px;
        height: auto;
    }

    .individual-campaignspromotions-other-section .campaignspromotions-card {
        background-color: white;
        // padding: 17px 0px;
        border-radius: 20px;
        // margin: 10px;
    }

    .individual-campro-hero-section {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        height: 200px;
        background-position: center !important;

        .container {
            .individual-campro-title {
                h1 {
                    color: black;
                    width: 160px;
                    font-size: 25px !important;
                }

                p {
                    color: #39B5B2;
                    font-weight: 600;
                    font-size: 10px;
                }
            }
        }
    }

    .individual-campaignspromotions-other-section {
        .container {
            h4 {
                color: black;
                margin-bottom: 30px;
                font-size: 21px;
                font-weight: 700;
                line-height: 24.15px;
                width: 257px;
            }
        }

        .campaignspromotions-card .container {
            .campaignspromotions-image img {
                border-radius: 10px;
                width: 150px;
                height: 150px;
                // margin: 10px;
            }
    
            .campaignspromotions-content {
                padding: 10px;
                height: 150px;
                width: 200px;
                
                .campro_desc_text {
                    font-size: 12px !important;
                    line-height: 14.32px !important;
                }

                p {
                    font-size: 17px !important;
                }
    
                .desc-campro-text {
                    p {
                        font-size: 12px !important;
                    }
                }
    
                .valid_date {
                    p {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }
    

    .individual-campaignspromotions-information-section-mv {
        .container {
            .campaignspromotions-content-1 {
                h2 {
                    padding-top: 13px;
                    max-width: 500px;
                    font-size: 21px;
                    color: black;
                }

                .campaignspromotions-content-1-box {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: space-between;
                    // align-items: flex-end;
                }
            }

            .campaignspromotions-content-2 {
                p {
                    font-size: 13px;
                    margin-bottom: 40px;
                    line-height: 15.51px;
                    color: #606060;
                }
            } 
        }
    }
    
    .individual-campaignspromotions-other-section .recomm-campro-slider-mv {
        .slick-dots {
            position: absolute;
            bottom: -16px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }
    }

    // share modal container
    .share-this-campaignspromotions-modal .container {
        width: 100% !important;
        height: 100%;
        border-radius: 0 !important;
        overflow-y: scroll;

        .social-media-profile {
            display: grid !important;
            grid-template-columns: 1fr 1fr;
            margin: auto;
            column-gap: 10px;

            #social-media-container {
                width: 100% !important;
                justify-content: center;
                height: 50px;
            }
        }

        .image-content {
            flex-direction: column-reverse !important;

            #campaignspromotions-title {
                font-size: 21px !important;
                margin-bottom: 10px !important;
                line-height: 24.15px;
                color: #070707 !important;
            }

            #campaignspromotions-validedate {
                font-size: 17px !important;
                line-height: 20.28px;
                color: #666567;
            }

            img {
                width: 100%;
                height: 100% !important;
                margin: auto;
            }
        }
    }
}