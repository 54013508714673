.my_purchases_kpj_section{
    background: rgb(243, 245, 247);
    padding-top: 40px;
    padding-bottom: 80px;

    .container {
        h2{
            color: black;
            margin-bottom: 50px;
        }   
        
        .book_again_total{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0px 50px 0px;

            #book_again_btn{
                border: 0;
                background-color: #967F65;
                border-radius: 30px;
                color: white;
                font-size: 16px;
                font-weight: 600;
                padding: 10px 0;
                width: 200px;
            }

            #total_word{
                font-size: 20px;
                font-weight: 500;
                margin-right: 30px;

                span{
                    font-size: 28px;
                    font-weight: 700;
                    color: #E98076;
                    margin-left: 15px;
                }
            }
        }
    }
}

.my_purchases_card_section{
    margin: 10px 0px;
    .purchases_section{
        background-color: #fff;
        padding: 10px;
        display: flex;
        border-radius: 10px;

        img{
            border-radius: 10px;
            width: 200px;
            aspect-ratio: 1/1;
        }

        .purchases_info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 0px 20px;
            position: relative;

            .product_details{
                #product_title{
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: 700;
                    color: black;
                }

                #hosp_name{
                    margin-bottom: 0;
                    color: black;
                }

                #username{
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 600;
                }

                #username_ic{
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1;
                    margin-bottom: 5px;
                }

                #product_unit{
                    margin-bottom: 0;
                    color: black;
                    font-weight: 600;
                    font-size: 18px;
                }
            }

            #product_price{
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 700;
                color: #E98076;
                text-align: end;
            }

            a{
                border: 0;
                background-color: transparent;
                color: #967F65;
                font-size: 16px;
                position: absolute;
                bottom: 10px;
                right: 0;

                #purhcase-download-icon{
                    width: 17px;
                    aspect-ratio: 1/1;
                    border-radius: 0;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .purchases_section{
        flex-direction: column;
        gap: 10px;

        img{
            width: 150px;
            // height: 150px;
        }

        .purchases_info{
            margin: 0 !important;
        }
    }
}

@media (max-width: 480px) {
    .purchases_section{
        flex-direction: row;
        gap: 10px;
    }

    .my_purchases_card_section {
        .purchases_section {
            img {
                border-radius: 10px;
                width: 104px;
                height: 104px;
            }

            .purchases_info {
                flex-direction: column;
                align-items: flex-start;
                width: 65%;

                a{
                    position: unset;
                    font-size: 13px;

                    #purhcase-download-icon {
                        height: 17px;
                    }
                }

                #product_price {
                    font-size: 15px;
                    font-weight: 500;
                }

                .product_details {
                    #product_title {
                        font-size: 17px;
                        color: #070707;
                    }

                    #hosp_name {
                        font-size: 10px;
                    }

                    #username, #username_ic {
                        font-size: 10px;
                        font-weight: 500;
                        color: #606060;
                    }

                    #product_unit {
                        font-weight: 500;
                        font-size: 15px;
                    }
                }     
            }
        }
    } 

    .my_purchases_kpj_section {
        .container {
            .book_again_total {
                #book_again_btn {
                    border: 0;
                    background-color: #967F65;
                    border-radius: 30px;
                    color: white;
                    font-size: 13px;
                    font-weight: 600;
                    padding: 10px 0;
                    width: 161px;
                }

                #total_word {
                    font-size: 13px;
                    font-weight: 500;
                    margin-right: 0px;
                    color: #606060;
                    text-align: right;
                    margin-bottom: 0;

                    span {
                        font-size: 17px;
                        font-weight: 700;
                        color: #E78076;
                        margin-left: 15px;
                    }
                }
            }       
        }
    } 
}
