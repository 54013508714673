.health_tourism_header_section {

 

  background-color: rgb(243, 245, 247);
  padding-top: 50px;
  padding-bottom: 30px;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h1,
    h2 {
      color: black;
    }

    .ht_second_title{
      font-size: 39px;
      color: #000000;
    }
  }

  .health_tourism_hero_section, .health_tourism_hero_section_mobile {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    // min-height: 925px;
    position: relative;
    margin-top: 40px;
    border-radius: 70px;


    .container {
      padding: 30px 0px;
      flex-direction: column;
      // min-height: inherit;
      justify-content: space-between;

      .box_1 {
        display: flex;
        flex-wrap: wrap;
        margin: 10px;

        #medical_concierge_box {
          background-color: black;
          color: white;
          padding: 15px 25px;
          border-radius: 15px;
          font-weight: 600;
          margin-right: 30px;
          font-size: 16px;
          cursor: pointer;
        }

        #teleconsult_box {
          border: 1px solid black;
          color: black;
          background-color: transparent;
          padding: 15px 25px;
          border-radius: 15px;
          font-weight: 600;
          font-size: 16px;
          height: fit-content;
          width: 280px;
          cursor: pointer;
        }
      }

      .text_box {
        margin: 500px 10px 10px 10px;
        display: flex;
        justify-content: flex-end;

        #long_text_health_tourism {
          color: white;
          max-width: 800px;
          line-height: 1.5;
          text-align: justify;
        }
      }
    }
  }
}

.international_patient_centre_section {
  background-color: rgb(243, 245, 247);
  padding-top: 50px;
  padding-bottom: 30px;

  .container {
    text-align: center;

    .image_container {
      position: relative;
      width: 100%;
      margin-bottom: 110px;

      // .ipc_image {
      //   width: 70%;
      // }
    }

    h2 {
      color: black;
    }

    p {
      font-size: 14px;
      line-height: 1.2;
      margin-top: 50px;
    }

    #see_more_button {
      border: 1px solid #967F65;
      color: #967F65;
      font-weight: 700;
      padding: 10px 30px;
      font-size: 18px;
      border-radius: 50px;
    }

    .desc_text{
      font-size: 18px;
    }
  }
}

.health_tourism_our_doctor_section {
  background-color: rgb(243, 245, 247);
  padding: 50px 80px;

  .container {
    h4 {
      color: black;
      font-size: 25px;
      font-weight: 700;
    }

    a {
      color: #967F65;
      font-weight: 700;
    }
  }
}

.health_tourism_health_packages_section {
  background-color: rgb(243, 245, 247);
  padding: 50px 80px;

  .container {
    h3 {
      color: black;
      margin-bottom: 30px;
    }

    p {
      line-height: 1.5;
      // margin-top: 30px;
    }

    a {
      color: #967F65;
      font-weight: 700;
    }
  }
}

.health_packages_card_health_tourism{
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  img{
    border-radius: 15px;
    margin-bottom: 18px;
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  #package_name{
      font-size: 18px;
      color: black;
      font-weight: 700;
      margin-bottom: 0px;
      line-height: 1.2;
      padding: 5px 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }

  #hosp_name{
      font-size: 14px;
      color: black;
      margin-bottom: 0px;
      padding: 0px 15px;
  }

  .package_price{
      font-size: 20px;
      color: #F06661;
      font-weight: 700;
      padding: 0 15px;
  }

  #book_package{
    border: 1px solid #967F65;
    color: #967F65;
    width: full-width;
    padding: 15px;
    border-radius: 30px;
    text-align: center;
    padding: 10px 5px;
    margin: 8px 15px 14px 15px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }
  
  #download_full_broc{
      color: black;
  }
}

.health_tourism_ipc_section {
  background-color: rgb(243, 245, 247);
  padding: 50px 0px;

  .container {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    padding: 60px;

    .health_tourism_ipc_services {
      padding-right: 150px;
      h2 {
        color: white;
        margin-bottom: 30px;
      }
      p {
        font-size: 16px;
        color: white;
        line-height: 1.2;
      }
    }
  }
}

.health_tourism_social_medias_section {
  background-color: #ebedef;
  padding: 50px 80px;

  .container {
    .social_medias_title_sub {
      h3 {
        color: black;
        margin-bottom: 30px;
      }
      p {
        line-height: 1.5;
        color: #606060;
      }
    }

    .social_medias_gallery {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      //.gallery-image{
      //    padding: 10px;
      //    width: 170px;
      //}
      img {
        padding: 10px;
        width: 170px;
      }

      .slider {
        display: flex;
        transition: transform 0.5s ease;
      }
      
      .slide {
        flex: 0 0 auto; // Allow slides to shrink to fit
        transition: transform 0.5s ease;
      }
    }
  }
}

@media (max-width: 1024px) {
  .health_tourism_header_section {
    .health_tourism_hero_section {
      // height: 730px;

      .container .text_box{
        bottom: 20px;

        #long_text_health_tourism {
          font-size: 17px;
        }
      }
    }
  }

 
}

@media (max-width: "768px") {
  .health_tourism_header_section {
    .health_tourism_hero_section {
      // height: 550px;

      .container {
        .text_box {
          bottom: 40px;
          margin: 0px 15px;
          right: 0px;
          left: 0px;

          #long_text_health_tourism {
            font-size: 14px;
          }
        }
      }
    }
  }

  .international_patient_centre_section .container .desc_text {
    font-size: 14px;
  }

  .health_tourism_health_packages_section .container p {
    line-height: 1.2;
    font-size: 14px;
  }

  .health_tourism_header_section .container .ht_second_title {
    font-size: 22px;
  }
}

@media (min-width: 481px) {
  .health_tourism_hero_section_mobile {
    display: none;
  }

  .health_tourism_health_package_slider{
    display: none;
  }

  .health_tourism_social_medias_section_mobile{
    display: none;
  }

  .health_tourism_our_doctor_section .mobile_view {
    display: none !important;
  }
}

@media (max-width: "480px") {
  //hide section view
  .health_tourism_hero_section {
    display: none;
  }

  .health_tourism_health_package_slider_normal{
    display: none !important;
  }

  .health_tourism_social_medias_section_normal{
    display: none;
  }

  .health_tourism_header_section {
    .container {
      flex-direction: column;
      // margin: 10px;
      // row-gap: 10px;

      h1{
        font-size: 32px !important;
        font-weight: 700;
        line-height: 37px;
        width: 246px;
      }

      p{
        font-size: 17px !important;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .health_tourism_hero_section_mobile {
      height: 100%;

      .container  {
        padding: 0;

        .button-button {
          display: flex;
          flex-direction: column;
          padding: 30px 24px;
          row-gap: 12px;

          .button_1 {
            width: 327px;
            height: 44px;
            border-radius: 8px;
            background-color: black;
            font-family: 'Flexo';
            font-size: 15px;
            font-weight: 700;
            line-height: 17px;
            text-align: center;
            color: white;
          }

          .button_2 {
            width: 327px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #000000;
            font-size: 15px;
            font-weight: 700;
            line-height: 18px;
            text-align: center;
            color: #000000;
            background-color: transparent;
          }
        }
      }
      
      .long_text_desc {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        color: #FFFFFF;
        // width: 295px;
        padding: 100px 10px 10px 10px;
      }
    }  

  }

  .international_patient_centre_section {
    .container {
      .image_container {
        position: relative;
        width: 100%;
        margin-bottom: 80px;
      }

      h2 {
        font-size: 25px;
        font-weight: 700;
        line-height: 29px;
        text-align: center;
        width: 170px !important;
      }

      #see_more_button {
        font-size: 15px;
      }

      .desc_text{
        font-size: 15px;
      }
    }
  }

  .health_tourism_health_packages_section {
    background-color: #f3f5f7;
    padding: 50px 0px;

    .container {
      h3 {
        margin-bottom: 20px;
        font-size: 25px !important;
        font-weight: 700;
        line-height: 29px;
      }

      .health_tourism_health_package_slider{
        margin-top: 30px;

        .slick-dots {
          position: absolute;
          bottom: -26px;
          display: block;
          width: 100%;
          padding: 0;
          margin: 0;
          list-style: none;
          text-align: center;
        }
      }
    }
  } 

  .health_tourism_social_medias_section {
    background-color: #ebedef;
    padding: 60px 20px;

    .container {
      .social_medias_title_sub {
        h3 {
          color: black;
          font-size: 25px !important;
          font-weight: 700;
          line-height: 29px;
          text-align: center;
          margin-bottom: 18px;
        }

        p {
          font-size: 13px !important;
          font-weight: 400;
          line-height: 16px;
          text-align: center;
          color: #606060;
          width: 326px;
        }
      }
    }
  }

  .health_packages_card_health_tourism {
    margin-inline: 10px;
  }
}
