.award-area {
  background: rgb(243, 245, 247);

  .container {
    padding-top: 120px;
    padding-bottom: 100px;

    .award-title {
      p {
        font-size: 40px;
        color: black;
        font-weight: bold;
      }
    }

    .award-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 30px;
      align-items: flex-start;

      .award-content-summary {
        width: 54%;
        line-height: 1.1;

        p{
          color: #606060;
          line-height: 1.5;
          text-align: justify;
          font-size: 20px;
        }
      }

      .award-content-logo {
        display: flex;
        justify-content: space-around;
        align-items: center;
        column-gap: 20px;

        img{
          width: 30%;
          background-size: contain;
          background-repeat: no-repeat;
          height: fit-content;
        }
      }
    }
  }
}

@media (max-width: 992px) {

  .award-title{
    text-align: center ;
  }

  .award-content {
      display: flex !important;
      flex-direction: column !important;

      .award-content-summary {
        width: 100% !important;
      }

      .award-content-logo {
        width: 100% !important;
      }
    }
}

@media (min-width: 481px) {
  .home_award_mob_view {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .home_award_web_view {
    display: none !important;
  }

  .award-area{
    .container{
      padding-top: 90px;
      padding-bottom: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .award-title p {
        font-size: 25px;
      }

      .award-content{
        padding-top: 10px;
        align-items: center;

        .award-content-summary {
          width: 327px !important;

          p {
            font-size: 15px;
            font-weight: normal;
            line-height: 1.3;
            color: #606060;
            margin-bottom: 20px;
            text-align: center;
          }
        }  
        
        .award-content-logo {
          display: flex;
          justify-content: space-around;
        }
      }
    } 
  } 

  .award-area .container .award-content .award-content-logo img {
    width: 10%;
    background-size: contain;
    background-repeat: no-repeat;
    height: -moz-fit-content;
    height: fit-content;
  }
}
