.doctor-bgc{
  background-size: cover;
  background-repeat: no-repeat;

  .doc-bgc-container {
    height: 390px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}

.find-a-doctors-section {
  .selection-dropdown {
    select {
      border: 0 none;
      min-height: 40px !important;
      max-width: 200px;
      text-align: left;
      word-wrap: normal;
      white-space: pre-wrap;
      overflow-wrap: normal;
      min-height: 100px;
      font-size: 1rem;
    }
  }

  input {
    border: 0 none;
    min-height: 30px !important;
    max-width: 200px;
    text-align: left;
    word-wrap: normal;
    white-space: pre-wrap;
    overflow-wrap: normal;
    min-height: 100px;
    font-size: 1rem;
  }
}

.find-a-doctors-area {
  background-color: rgb(243, 245, 247);
  padding-top: 160px;
  padding-bottom: 15px;
 
  .container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .col-lg-4 {
      padding: 1%;
    }

    .doctors-pagination {
      .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    
        .page-number {
          display: inline-block;
          background-color: white;
          border-radius: 10px;
          padding: 15px;
          margin: 50px;
          box-shadow: 0px 4px 10px 0px #B7BBCA5C;
    
          a {
            color: black;
            padding: 5px 8px;
            margin-right: 5px;
    
            &:hover {
              background-color: rgb(243, 245, 247);
              border-radius: 5px;
            }
    
            &.active {
              background-color: #FCB11D;
              color: white !important;
              border-radius: 5px;
            }
          }
        }
    
        .page-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 15px;
    
          p {
            margin-right: 10px;
            font-size: 15px;
          }
    
          input {
            margin-top: -15px;
            margin-right: 10px;
            width: 60px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

.filter_table_doctor_mv{
  position: absolute;
  bottom: -12%;
  margin: 0px;
  width: 100%;
  text-align: center;
}

.appointment-section {
  &.find-doctor-section {
    .tab-content {
      border-radius: 2rem;
    }
  }

  .doctor-selection {
    padding: 20px;
    position: relative;
  }
}

.find-a-doctors-card {
  background-color: white;
  padding: 20px;
  // min-height: 570px;
  height: 100%;
  border-radius: 20px;
  justify-content: center;
  display: flex;

  .doctor-image {
    position: relative;
    border-radius: 50%;
    width: 100%;
    aspect-ratio: 1 / 1;

    img {
      width: 130px;
      border-radius: 50%;
    }
  }

  .doctor-informations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    .doctor-name {
      p {
        font-weight: 600;
        font-size: 18px;
        text-align: left;
        text-transform: uppercase;
        text-wrap: balance;

        // white-space: pre-wrap;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.2em;
        overflow: hidden;
        height: 2.4em;
        color: #967F65;
        margin-bottom: 6px;
      }
    }

    .doctor-status {
      p {
        color: #606060;
        font-size: 14px;
        margin-bottom: 0px;
      }
    }

    .doctor-type {
      p {
        color: #000;
        font-size: 18px;
        margin-bottom: 0px;
        font-weight: 700;
        text-wrap: balance;
      }
    }

    .doctor-title {
      p {
        margin-bottom: 10px;
        font-size: 18px;
        color: black;
        font-weight: 700;
        text-align: left;
        line-height: 1.1;
        text-wrap: balance;
        height: auto;
        text-transform: capitalize;

      }
    }

    .doctor-fellowship {
      p {
        display: flex;
        flex-direction: column;
        // justify-content: center;

        margin-bottom: 10px;
        font-size: 14px;
        color: #606060;
        font-weight: 400;
        text-wrap: balance;
        line-height: 1.1;
        height: 80px;
      }
    }

    .doctor-type-btn-container {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .doctor-type-btn {
        appearance: none;
        
        border: 1px solid #967F65;
        border-radius: 20px;
        background-color: transparent;
        color: #967F65;

        padding-inline: 5px;

        &.active {
          background-color: #967F65;
          color: white;
        }

      }
    }

    .doctor-hospital-listing {
      text-align: left;

      ul {
        li {
          display: flex;
          margin-bottom: 5px;
          cursor: pointer;
          align-items: center;

          p {
            font-size: 14px;
            line-height: 1.1;
            margin-bottom: 0;
          }
        }
      }
    }

    .doctor-contacts {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      color: white;
      margin-top: 25px;

      .tab {
        position: relative;
        display: flex;
        width: 33px;
        flex-direction: row;
        align-items: center;
        height: 30px;
        cursor: pointer;

        &:nth-child(1) {
          background-color: #967F65;
        }
        &:nth-child(2) {
          background-color: #e78076;
        }
        &:nth-child(3) {
          background-color: #39B5B2;
        }

        &.active {
          flex: 2;

          p {
            opacity: 1;
          }
        }

        p {
          margin-bottom: 0px;
          color: white;
          opacity: 0;
        }

        a {
          display: inline-flex;
          align-items: center;
        }
      }
    }

    
  }
}

@media (max-width:1280px) {
  .doctor-bgc .doc-bgc-container {
    height: 385px;
  } 
}

@media (max-width:1200px) {
  .find-a-doctors-card {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
}

@media (max-width: "1000px") {
  .find-a-doctors-area {
    padding-top: 350px;
  }
}

@media (max-width: "1024px") {
  .doctor-bgc .doc-bgc-container {
    height: 309px;
  }

  .find-a-doctors-card {
    height: 100%;
    flex-direction: column;

    .doctor-informations {
      width: 100%;
      margin-top: 10px;
    }
  }

  .find-a-doctors-area {
    padding-top: 70px;
  }

  .filter_table_doctor_mv, #filter-btn-mv {
    display: block !important;
  }

  .filter_table_doctor_mv {
    bottom: -10%;
  }

  #filter-btn-mv {
    border: 0;
    box-shadow: 0 4px 10px 0 rgba(183, 188, 202, 0.36);
    background: #FFFFFF;
    width: 90%;
    height: 60px;
    border-radius: 32px;
    text-align: left;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 700;
    display: block !important;
    margin: auto;
  }
}

@media (max-width: 768px) {

  .doctor-bgc .doc-bgc-container {
      height: 230px;
  }

  .filter_table_doctor_mv {
    bottom: -13%;
  }
}

@media (min-width: 576px) {
  .selection-dropdown {
    padding: 10px;
    width: 20%;
    border-right: 1px solid lightgray;

    &:last-child {
      border-right: 0;
    }
  }

  .doctor-selection {
    font-size: 13px;
    .row {
      justify-content: space-between;
      align-items: baseline;
    }
  }
}

@media (min-width: 481px) {
  .doctor_contacts_mv, .filter_table_doctor_mv{
    display: none ;
  }
}

@media (max-width: 480px) {

  .doctor-bgc{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .container {
      height: 200px !important;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
  }

  .find-a-doctors-section{
    .slider-height {
      min-height: 150px;
      position: relative;
    }

    .hero-slider-caption {
      min-height: 113px;
      text-align: initial;
      justify-content: flex-start;
    }

    h1{
      font-size: 25px !important;
      font-weight: 700;
    }
  }

  .find-a-doctors-area {
    padding-top: 48px;
  }

  .find-a-doctors-card{
    max-width: -webkit-fill-available;
    height: 100%;
    min-height: 0;
    border-radius: 32px;
    padding: 24px;
    align-items: flex-start;
    flex-direction: row;
    margin-inline: auto;

    .doctor-informations {      
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      justify-content: normal;
      width: 100%;
      margin-top: 0;

      .doctor-name p {
        color: #967F65;
        font-weight: 700;
        font-size: 17px;
        text-align: left;
        margin-bottom: 10px;
      }

      .doctor-type p {
        color: #606060;
        font-size: 12px;
        line-height: 14.32px;
        margin-bottom: 0px;
      }

      .doctor-title p {
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: 700;
        color: black;
        line-height: 15.51px;
      }
    }
  }

  .doctor-contacts{
    display: none !important;
  }

  .doctor_contacts_mv{
    display: flex;

    button{
      border: 0;
      padding: 5px;
      width: 40px;
      height: 40px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(1) {
        background-color: #967F65;
      }
      &:nth-child(2) {
        background-color: #e78076;
      }
      &:nth-child(3) {
        background-color: #afd746;
      } 
    }

    img {
      width: 30%;
    }
  }

  .find-a-doctors-area .container .doctors-pagination .container .page-number {
    margin: 20px;
  }

  .find-a-doctors-area .container .doctors-pagination .container {
    flex-direction: column;
  }
}
