.faq_hero_section{
    background-repeat: no-repeat;
    height: 234px;
    background-size: 100%;

    .container{
        display: flex;
        align-items: center;
        height: inherit;

        h1{
            color: black;
        }
    }
}

.faq_content_section{
    display: flex;
    height: 100vh;
}

.faq_navigation{
    flex: 1.5;
    background-color: #F2F4F6;
    padding: 70px;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            cursor: pointer;
            padding: 8px;

            &:not(:last-child){
                border-bottom: 1px solid #967F65;
            }

            &.active{
                padding: 20px;
                p{
                    background-color: black;
                    color: white;
                    border-radius: 30px;
                    padding: 10px;
                    font-size: 20px;
                    text-align: center;
                    margin-bottom: 0;

                }
            }

            p{
                color: black;
                padding: 10px;
                font-size: 20px;
                margin-bottom: 0;
            }
        }
    }
}

.faq_content{
    flex: 3;
    padding: 80px;

    h2{
        color: black;
        width: 80%;
        border-bottom: 1px solid black;
        padding-bottom: 20px;
    }

    .accordion-content {
        border-bottom: 1px solid black;
    }
      
    .accordion-header {
        cursor: pointer;
        padding: 12px;
        font-size: 18px;
        font-weight: bold;
        color: #967F65;

        .symbol {
            position: relative;
            font-size: 18px;
            margin-left: -22px;
            margin-right: 40px;
        }
    }
    
    .accordion-content {
        padding: 12px;
    }
}

@media (max-width: 480px) {
    .faq_hero_section {
        background-repeat: no-repeat;
        height: 198px;
        background-size: cover;
        background-position: center;
    }

    .faq_content_section {
        
        display: flex;
        height: 100%;
        flex-direction: column;
    }

    .faq_navigation {
        flex: 1.5 1;
        background-color: #F2F4F6;
        padding: 10px;
    }

    .faq_content {
        flex: 3 1;
        padding: 10px;
    }
    
}