.individual-hospital-hero-section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .individual-hospital-hero-title {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      h1 {
        width: 70%;
        color: black;
      }

      #back_to_hosp_btn {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        margin-top: 10px;
        text-align: start;
        width: fit-content;
        color: #39B5B2;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        font-size: 18px;
        font-weight: 600;
        color: #967F65;
      }
    }
  }
}

.individual-hospital-contact-section {
  padding: 70px 0;

  .container,
  .container-mobile-view {
    #ambulance-logo {
      background-color: #e98076;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      // position: absolute;
      right: 80px;
      height: 80px;
      width: 80px;
      top: 10px;
    }

    .contact_details {
      display: flex;
      flex-direction: row;

      p {
        margin-bottom: 0px;
      }

      h3 {
        color: gray;
        white-space: nowrap;
      }
    }

    #our-doctors,
    #services,
    #patient-care {
      display: flex;
      flex-direction: column;

      a {
        color: #967F65;
      }

      .icon_container {
        width: 35px;
        height: 100%;
        margin-top: 20px;
        height: 100%;
        left: 60%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      // img {
      //     position: relative;
      //     width: 35px;
      //     height: 100%;
      //     margin-top: 20px;
      //     left: 60%;
      // }
    }

    #our-doctors,
    #services,
    #our_contact {
      border-right: 1px solid gray;
    }
  }
}

@media (max-width: "767px") {
  #our-doctors,
  #services {
    border-right: 0 !important;
    border-bottom: 1px solid gray;
  }
}

.individual-hospital-about-section {
  padding: 100px 0px;
  background-color: rgb(243, 245, 247);

  .container {
    padding: 0px 50px;

    img {
      width: 500px;
      height: 300px;
      border-radius: 20px;
    }

    #individual-hospital-content {
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .individual-hospital-name {
        margin-bottom: 20px;

        h2 {
          color: rgb(7, 7, 7);
          font-weight: 700;
          width: 70%;
        }
      }

      .individual-hospital-about {
        span {
          color: rgb(96, 96, 96);
          line-height: 1.3;
          font-size: 16px;
          font-weight: 400;
          width: 700px;
        }
      }
    }
  }
}

.individual-hospital-medical-programme-section {
  background-size: cover;
  height: fit-content;
  background-repeat: no-repeat;

  .container {
    .medical-programme-title-subs {
      position: relative;
      padding-top: 125px;
      padding-bottom: 70px;
      // left: 10%;

      h2 {
        color: white;
        margin-bottom: 15px;
        font-size: 33px;
      }

      p {
        font-size: 18px;
        max-width: 450px;
        color: white;
        line-height: 1.5;
        font-weight: 300;
      }
    }

  }

  .medical-prog .medical-prog-containter {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    transition: transform 0.5s ease 0s;
  }

  .navigation_line_container {
    padding-bottom: 50px;
    position: relative;
    left: 14%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .medical-prog-mobile {
    padding-bottom: 60px;
  }
}

.medical-programme-link:hover {
  .medical-programme-card {
    background-color: #f00; // Change the background color on link hover
  }
}

.medical-programme-card {
  position: relative;
  cursor: default;

  .container {
    margin: 0px;
    width: 300px;
    height: 265px;
    background-color: #775490;
    border-radius: 20px;
    padding: 40px 25px;
    position: relative;
    cursor: pointer;

    a {
      color: white !important;
    }

    img {
      width: 30px;
      /* height: 50px; */
      position: absolute;
      right: 10%;
    }

    .multiline-ellipsis {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 3em; /* Adjust this to control the number of lines shown */
    }

    .medical-programme-link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.individual_hospital_health_package_area {
  background-color: rgb(243, 245, 247);
  padding-top: 120px;
  padding-bottom: 50px;

  .container {
    .title-subtitle-box {
      text-align: center;
      margin-bottom: 40px;

      .title {
        p {
          font-size: 40px;
          color: black;
          font-weight: bold;
        }
      }

      .subtitle {
        p {
          width: 70%;
          margin: 3% auto;
          line-height: 1.5;
        }
      }
    }

    .health-package-button-container {
      text-align: center;
      color: #967F65;
      padding: 20px;
      font-size: large;

      a {
        color: #967F65 !important;
        font-weight: 800;
      }
    }
  }
}

.individual-hospital-international-section {
  background-color: rgb(243, 245, 247);
  padding: 50px 0px;

  .container {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    padding: 60px;

    .international-patient-services {
      padding-right: 150px;
      h2 {
        color: white;
        margin-bottom: 30px;
      }
      p {
        color: white;
        line-height: 1.5;
        text-align: justify;
      }
    }
  }
}

.individual-hospital-insight-corner-section {
  background-color: rgb(243, 245, 247);
  padding: 50px 0px;

  .container {
    .insight-corner-title-subs {
      position: relative;
      top: 30%;
      h2 {
        font-size: 40px;
        color: black;
        margin-bottom: 20px;
      }

      p {
        line-height: 1.5;
        text-align: justify;
      }
    }

    .panel {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 50vh;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      flex: 0.2;
      margin: 10px;
      position: relative;
      transition: all 0s ease-in 0s;

      .container-color-overlay-3 {
        background: linear-gradient(0deg, #371450 5.14%, rgba(55, 20, 80, 0.96) 13.14%, rgba(55, 20, 80, 0.86) 25.14%, rgba(55, 20, 80, 0.7) 40.14%, rgba(55, 20, 80, 0.47) 56.14%, rgba(55, 20, 80, 0.18) 75.14%, rgba(55, 20, 80, 0) 85.14%);
        content: "";
        position: absolute;
        top: 20%;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: 1;
        border-radius: 20px;
      }

      .container-color-overlay-4 {
        background: linear-gradient(0deg, #39B5B2 5.14%, rgba(57, 181, 178, 0.98) 15.14%, rgba(57, 181, 178, 0.91) 26.14%, rgba(57, 181, 178, 0.79) 38.14%, rgba(57, 181, 178, 0.63) 51.14%, rgba(57, 181, 178, 0.43) 65.14%, rgba(57, 181, 178, 0.18) 79.14%, rgba(57, 181, 178, 0) 87.14%);
        content: "";
        position: absolute;
        top: 20%;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: 1;
        border-radius: 20px;
      }

      .container-color-overlay-5 {
        background: linear-gradient(0deg, #FCB11D 5.14%, rgba(252, 177, 29, 0.98) 15.14%, rgba(252, 177, 29, 0.91) 26.14%, rgba(252, 177, 29, 0.79) 38.14%, rgba(252, 177, 29, 0.63) 51.14%, rgba(252, 177, 29, 0.43) 65.14%, rgba(252, 177, 29, 0.18) 79.14%, rgba(252, 177, 29, 0) 87.14%);
        content: "";
        position: absolute;
        top: 20%;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: 1;
        border-radius: 20px;
      }

      &.active {
        flex: 1;

        h3 {
          position: absolute;
          bottom: 20px;
          left: 20px;
          opacity: 1;
          writing-mode: horizontal-tb;
          transform: rotate(0deg);
          transition: all 0s ease-in 0s;
          font-size: 32px;
        }

        p {
          font-size: 32px;
          position: absolute;
          color: white;
          bottom: 20px;
          left: 86%;
          margin: 0;
          opacity: 1;
          transition: all 0s ease-in 0s;
        }
      }

      h3 {
        position: absolute;
        color: white;
        bottom: 20px;
        left: 40%;
        margin: 0;
        opacity: 1;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
      }

      p {
        font-size: 24px;
        position: absolute;
        color: white;
        bottom: 20px;
        left: 90%;
        margin: 0;
        opacity: 0;
      }
    }
  }
}

.individual-hospital-map {
  // min-height: 70vh;

    .google_map{
        iframe{
            width: 100%;
            height: 600px;
        }
    }
  .container {
    height: inherit;
    position: relative;

    .map-informations {
      z-index: 0;
      background-color: white;
      max-width: 450px;
      max-height: 450px;
      padding: 70px 70px 70px 50px;
      position: absolute;
      top: 55px;
      left: 10px;
      border-radius: 2rem;

      h4 {
        color: #967F65;
        font-size: 25px;
        font-weight: 700;
      }

      p {
        color: black;
        line-height: 1.1;
        margin-bottom: 0px;
        font-size: 15px;
      }

      .address_box {
        display: flex;
        align-items: flex-start;
        margin-top: 24px;
      }

      .contact_box {
        display: flex;
        align-items: center;
        margin-top: 12px;
      }

      .email_box {
        display: flex;
        align-items: center;
        margin-top: 12px;
      }

      .direction_box {
        display: flex;
        margin-top: 30px;
        border-top: 1px solid white;
        padding-top: 20px;

        .waze_box {
          margin-right: 18px;
          text-align: center;

          img {
            max-width: 50px;
            background-color: #ECEEF0;
            border-radius: 50%;
            padding: 10px;
          }
        }

        .google_box {
          text-align: center;

          img {
            max-width: 50px;
            background-color: #ECEEF0;
            border-radius: 50%;
            padding: 10px;
          }
        }
      }
    }
  }
}

.medical-program-single-page {
  .page-header {
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 58px 0;

    .container {
      display: flex;
      flex-direction: column;
      height: inherit;
      justify-content: center;

      h1 {
        font-size: 36px;
        color: black;
      }
    }
  }

  .page-content {
    background: rgb(243, 245, 247);

    .container {
      padding: 70px 0;

      .accordion-item {
        margin-bottom: 5px;
        background-color: transparent;
        color: white;
        border: 0px;
        border-bottom: 1px solid black;
      }
    }
  }
}

.individual-hospital-map-mv {
  min-height: 100vh;
  position: relative;

  .container {
    padding-top: 50px;
    padding-bottom: 20px;
    padding-inline: 25px;

    .map-informations {
      border-bottom: 1px solid gray;

      h4 {
        color: #606060;
        font-size: 25px;
        font-weight: 600;
      }

      p {
        color: #606060;
        line-height: 1.1;
        margin-bottom: 0px;
      }

      .address_box {
        margin-top: 24px;
        margin-bottom: 15px;
        display: flex;
        align-items: flex-start;
      }

      .contact_box {
        margin-bottom: 15px;
      }

      .email_box {
        margin-bottom: 40px;
      }
    }

    .direction_box {
      display: flex;
      margin-top: 50px;
      justify-content: space-around;

      .waze_box {
        margin-right: 18px;
        text-align: center;
        display: flex;
        align-items: center;

        img {
          max-width: 50px;
          background-color: #ECEEF0;
          border-radius: 50%;
          padding: 10px;
          margin-right: 15px;
        }
      }

      .google_box {
        text-align: center;
        align-items: center;
        display: flex;

        img {
          max-width: 50px;
          background-color: #ECEEF0;
          border-radius: 50%;
          margin-right: 15px;
          padding: 10px;
        }
      }
    }
  }
}

.medical-program-single-page {
  .page-content-text {
    font-size: 17px;
  }
}


@media (max-width: "1024px") {
  .individual-hospital-hero-section {
    background-size: cover;
  }

  .individual-hospital-contact-section .container #ambulance-logo {
    right: 30px;
  }

  .individual-hospital-map .container .map-informations {
    padding: 40px;
  }
}

@media (min-width: 769px) {
  .individual-hospital-contact-section .container-mobile-view {
    display: none;
  }
}

@media (max-width: "768px") {
  .individual-hospital-contact-section .hosp-normal-view {
    display: none;
  }

  .individual-hospital-medical-programme-section {
    background-size: 100%;
  }

  .medical-prog {
    background: #5775D2;
  }

  .navigation_line_container {
    left: 15% !important;
  }

  .medical-programme-card {
    left: 2% !important;
    .container {
      padding: 25px 10px !important;
      width: 250px !important;
    }
  }

  .individual-hospital-medical-programme-section .medical-prog .medical-prog-containter {
    position: relative;
    left: 10%;
  }

  .individual_hospital_health_package_area .container .title-subtitle-box .subtitle p {
    width: 88%;
  }

  .individual-hospital-contact-section .container-mobile-view {

    .hosp_contact_mv {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10%;
      border-bottom: 1px solid black;
      padding-bottom: 20px;
    }

    .contact_details img {
      width: 40px !important;
      height: 40px !important;
      margin-right: 10px !important;
      position: relative;
      top: 4px;
    }

    #services, #our-doctors, #patient-care {
      border-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .individual-hospital-contact-section .container-mobile-view {
    #services .icon_container, #our-doctors .icon_container,  #patient-care .icon_container  {
      position: relative;
      width: 31px;
      height: 45px;
      margin-top: 20px;
      left: 0%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  .individual-hospital-about-section .container img {
    width: 100%;
    height: 100%;
  }
  
  .individual-hospital-insight-corner-section .container .insight-corner-title-subs {
    text-align: center;

    p {
      width: 100%;
      padding-inline: 70px;
    }
  }

}

@media (min-width: 481px) {
  // .individual-hospital-contact-section .container-mobile-view {
  //   display: none;
  // }

  .individual-hospital-medical-programme-section .medical-prog-mobile {
    display: none;
  }

  .individual-hospital-insight-corner-section .hosp-insight-corner-mv {
    display: none;
  }

  .individual-hospital-map-mv{
      display: none !important;
  }

  .individual-hospital-hero-section .back_to_mob {
      display: none !important;
  }

  .med_program_mob {
    display: none;
  }

  .individual-hospital-international-section .hospital_ips_mob_view {
    display: none !important;
  }


}

@media (max-width: "480px") {
  .individual-hospital-medical-programme-section .medical-prog {
    display: none;
  }

  .individual-hospital-international-section .hospital_ips_web_view {
    display: none !important;
  }

  .med_program_web {
    display: none;
  }

  .individual-hospital-insight-corner-section .hosp-insight-corner {
    display: none;
  }

    .individual-hospital-map{
        display: none;
    }

    .individual-hospital-hero-section .back_to_web {
        display: none !important;
    }


  .individual-hospital-hero-section {
    min-height: 200px;
    background-size: cover;

    .container {
      padding-inline: 40px;

      .individual-hospital-hero-title h1 {
        width: 100%;
        font-size: 25px !important;
      }
    }
  }

    .individual-hospital-hero-section .container 
    .individual-hospital-hero-title #back_to_hosp_btn {
        font-size: 10px;
        font-weight: 700;
    }

  .individual-hospital-contact-section {
    // .hosp-normal-view {
    //   display: none;
    // }

    .container-mobile-view {
      #ambulance-logo {
        display: none;
      }

      .contact_details {
        align-items: center;

        img {
          width: 24px !important;
          height: 24px !important;
          margin-right: 5px !important;
        }
      }

      .hosp_contact_mv {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10%;
        border-bottom: 0;
        padding-bottom: 0;

        h4 {
          font-size: 17px;
        }
      }
    }
  }

  .medical-programme-title-subs {
    left: 2% !important;
    padding-top: 155px !important;
    padding-bottom: 34px !important;
  }

  .individual-hospital-medical-programme-section .medical-prog-mobile {
    .slick-dots {
      position: absolute;
      bottom: 6%;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }

    .slick-dots li.slick-active button:before {
      font-family: "slick";
      font-size: 10px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.75;
      color: #ffffff;
      -webkit-font-smoothing: antialiased;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 10px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #d9d9d9;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .navigation_line_container {
    left: 2% !important;
  }

  .medical-programme-card {
    left: 2% !important;
    .container {
      padding: 25px 10px !important;
      width: 70vw !important;

      img {
        width: 40px;
        bottom: 10%;
      }
    }
  }
  .individual-hospital-contact-section {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .individual-hospital-international-section {
    padding: 50px 10px;

    .container {
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      padding: 30px;
      background-size: cover !important;
  
      .international-patient-services h2 {
        color: white;
        margin-bottom: 12px;
        width: 200px;
      }
  
      .international-patient-services p {
        font-size: 13px;
        color: white;
        line-height: 15px;
        width: 193px;
        margin-bottom: 76px;
      }
    }
  } 

  .individual-hospital-contact-section .container-mobile-view {
    .contact_details {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-bottom: 0px;
        font-size: 13px !important;
        color: #606060 !important;
        line-height: 15.51px !important;
      }

      h3 {
        color: #7c7b7b;
        font-size: 15px;
        margin-bottom: 0;
      }
    }

    #our-doctors,
    #services,
    #patient-care {
      display: flex;
      flex-direction: column;
      align-items: center;

      h4 {
        font-size: 15px !important;
        margin-bottom: 0px;
        font-weight: 700;
      }

      a {
        font-size: 10px !important;
      }

      .icon_container {
        position: relative;
        width: 31px;
        height: 45px;
        margin-top: 20px;
        left: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      // img {
      //     position: relative;
      //     width: 31px;
      //     height: 45px;
      //     margin-top: 20px;
      //     left: 0%;
      // }
    }

    .hr-line-hospital {
      border: 0.25px solid black;
      width: 100%;
      justify-content: flex-end;
      right: 0px;
      margin-top: 13px;
      position: absolute;
    }
  }

  .individual-hospital-about-section {
    padding: 40px 0px;
    background-color: rgb(243, 245, 247);

    .container {
      padding: 0 10px;
      img {
        width: 500px;
        height: 100%;
        border-radius: 20px;
      }

      #individual-hospital-content {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .individual-hospital-name {
          margin-bottom: 20px;
          text-align: center;
          display: flex;
          justify-content: center;

          h2 {
            color: rgb(7, 7, 7);
            font-weight: 700;
            width: 268px;
          }
        }

        .individual-hospital-about {
          text-align: center;
          display: flex;
          justify-content: center;
          color: #606060;
          flex-wrap: wrap;

          p {
            color: #606060;
            line-height: 15.51px;
            font-size: 13px;
            font-weight: 400;
            width: 327px;
          }
        }
      }
    }
  }

  .individual-hospital-insight-corner-section .hosp-insight-corner-mv {
    .panel_mobile {
      .container-color-overlay-3 {
        background: linear-gradient(0deg, #371450 5.14%, rgba(55, 20, 80, 0.96) 13.14%, rgba(55, 20, 80, 0.86) 25.14%, rgba(55, 20, 80, 0.7) 40.14%, rgba(55, 20, 80, 0.47) 56.14%, rgba(55, 20, 80, 0.18) 75.14%, rgba(55, 20, 80, 0) 85.14%);
        content: "";
        pointer-events: none;
        z-index: 1;
        border-radius: 30px;

        h3 {
          font-size: 25px !important;
          font-weight: 700 !important;
          line-height: 31px !important;
          color: white;
        }

        p {
          font-size: 25px !important;
          font-weight: 400 !important;
          line-height: 29.82px !important;
          color: white;
        }
      }
      .container-color-overlay-4 {
        background: linear-gradient(0deg, #39B5B2 5.14%, rgba(57, 181, 178, 0.98) 15.14%, rgba(57, 181, 178, 0.91) 26.14%, rgba(57, 181, 178, 0.79) 38.14%, rgba(57, 181, 178, 0.63) 51.14%, rgba(57, 181, 178, 0.43) 65.14%, rgba(57, 181, 178, 0.18) 79.14%, rgba(57, 181, 178, 0) 87.14%);
        content: "";
        pointer-events: none;
        z-index: 1;
        border-radius: 30px;

        h3 {
          font-size: 25px !important;
          font-weight: 700 !important;
          line-height: 31px !important;
          color: white;
        }

        p {
          font-size: 25px !important;
          font-weight: 400 !important;
          line-height: 29.82px !important;
          color: white;
        }
      }
      .container-color-overlay-5 {
        background: linear-gradient(0deg, #FCB11D 5.14%, rgba(252, 177, 29, 0.98) 15.14%, rgba(252, 177, 29, 0.91) 26.14%, rgba(252, 177, 29, 0.79) 38.14%, rgba(252, 177, 29, 0.63) 51.14%, rgba(252, 177, 29, 0.43) 65.14%, rgba(252, 177, 29, 0.18) 79.14%, rgba(252, 177, 29, 0) 87.14%);
        content: "";
        pointer-events: none;
        z-index: 1;
        border-radius: 30px;

        h3 {
          font-size: 25px !important;
          font-weight: 700 !important;
          line-height: 31px !important;
          color: white;
        }

        p {
          font-size: 25px !important;
          font-weight: 400 !important;
          line-height: 29.82px !important;
          color: white;
        }
      }
    }

    .slick-dots {
      position: absolute;
      bottom: -8%;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }
  }

  .individual-hospital-insight-corner-section .container {
    .insight-corner-title-subs h2 {
      font-size: 25px;
      color: black;
      margin-bottom: 16px;
    }

    .insight-corner-title-subs p {
      line-height: 15px;
      width: 100% !important;
      font-size: 13px;
      padding: 0 20px;
      margin-bottom: 40px;
      color: #606060;
    }
  }

  .individual-hospital-medical-programme-section {
    background-size: 100%;
    height: auto;

    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      -webkit-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
      // background: #5775D2;
      top: 0px;
    }

    .container .medical-programme-title-subs {
      h2 {
        color: white;
        margin-bottom: 20px;
        font-size: 25px;
      }

      p {
        font-size: 13px;
        max-width: 327px;
        color: white;
        line-height: 15.51px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }

  .individual_hospital_health_package_area {
    padding-top: 40px;

    .slick-dots {
      position: absolute;
      bottom: -30px;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }

    .container .title-subtitle-box {
      .title p {
        font-size: 25px;
        color: black;
        font-weight: 700;
        line-height: 28.75px;
      }

      .subtitle p {
        font-size: 13px;
        font-weight: 400;
        line-height: 15.51px;
        width: 327px;
        margin: 20px auto 40px;
        color: #606060;
      }
    }
  }

  .medical-program-single-page {
    .page-header {
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .page-content {
      .container {
        padding: 39px 0;

        .page-content-text {
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          text-align: center;
          color: #606060;
          padding: 0 10px;
        }
      }
    }
  }

  .health_tourism_our_doctor_section {
    padding: 21px 10px !important;
  }
}

// .embla {
//   margin: auto;
//   --slide-height: 19rem;
//   --slide-spacing: 8.5rem;
//   --slide-size: 100%;
// }
// .embla__viewport {
//   overflow: hidden;
// }
// .embla__container {
//   backface-visibility: hidden;
//   display: flex;
//   touch-action: pan-y pinch-zoom;
//   margin-left: 0; /* Remove extra margin */
//   gap: var(--slide-spacing); /* Add gap between slides */
// }
// .embla__slide {
//   flex: 0 0 var(--slide-size);
//   min-width: 0;
//   padding-left: var(--slide-spacing);
// }
// .embla__slide__number {
//   box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
//   border-radius: 1.8rem;
//   font-size: 4rem;
//   font-weight: 600;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: var(--slide-height);
// }
// .embla__controls {
//   display: grid;
//   grid-template-columns: 7fr 1fr;
//   justify-content: space-between;
//   gap: 1.2rem;
//   margin-top: 1.8rem;
//   width: 88%;
// }
// .embla__buttons {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 0.6rem;
//   align-items: center;
// }
// .embla__button {
//   -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
//   -webkit-appearance: none;
//   appearance: none;
//   background-color: transparent;
//   touch-action: manipulation;
//   display: inline-flex;
//   text-decoration: none;
//   cursor: pointer;
//   border: 0;
//   padding: 0;
//   margin: 0;
//   box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
//   width: 3.6rem;
//   height: 3.6rem;
//   z-index: 1;
//   border-radius: 50%;
//   color: #fff;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .embla__button:disabled {
//   color: rgba(255, 255, 255, 0.5); /* White color with 50% opacity */
// }
// .embla__button__svg {
//   width: 35%;
//   height: 35%;
// }
// .embla__dots {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-end;
//   align-items: center;
//   margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
// }
// .embla__dot {
//   -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
//   -webkit-appearance: none;
//   appearance: none;
//   background-color: transparent;
//   touch-action: manipulation;
//   display: inline-flex;
//   text-decoration: none;
//   cursor: pointer;
//   border: 0;
//   padding: 0;
//   margin: 0;
//   width: 2.6rem;
//   height: 2.6rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50%;
// }
// .embla__dot:after {
//   box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
//   width: 1.4rem;
//   height: 1.4rem;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   content: '';
// }
// .embla__dot--selected:after {
//   box-shadow: inset 0 0 0 0.2rem var(--text-body);
// }
// .embla__progress-bar {
//   width: 100%; /* Set the width of the progress bar */
//   height: 1px; /* Set the height of the progress bar */
//   background-color: rgba(255, 255, 255, 0.5); /* Background color of the progress bar */
//   border-radius: 5px; /* Rounded corners */
//   overflow: hidden; /* Ensure progress doesn't overflow */
//   position: relative; /* Position relative for inner bar positioning */
//   align-items: center;
//   margin: auto 0; /* Center align within its grid area */
// }

// .embla__progress-bar__inner {
//   height: 80%;
//   width: 0%; /* Initially set to 0, will be updated dynamically */
//   background-color: #fff; /* Color of the progress */
//   transition: width 0.3s ease; /* Smooth transition */
//   border-radius: 5px; /* Match the outer bar's rounded corners */
//   height: 4.35px;
// }


