.my_collections_videos_section{
    background-color: rgb(243, 245, 247);
    padding-top: 100px;
    padding-bottom: 50px;

    .container{
        h2{
            color: black;
            margin-bottom: 50px;
        }
    }
}

.my_collections_videos_card{
    background-color: #fff;
    padding: 15px;
    border-radius: 30px;
    // width: 500px;

    img{
        border-radius: 10px;
        // margin-bottom: 18px;
        width: 250px;
        height: 150px;
    }

    #video_name{
        font-size: 16px;
        color: black;
        font-weight: 700;
        line-height: 1.1;
    }

    #video_content{
        font-size: 14px;
        line-height: 1.1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.4em;
        line-height: 2.4em;
        margin-bottom: 10px;

        a{
            font-size: 13px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
        }

    }

    #video_writer{
        font-size: 14px;
        font-weight: 700;
        color: #666567;
    }

    #video_date{
        font-size: 14px;
        margin-bottom: 0px;
        color: #666567;
    }

}

.my_collections_videos_card_profile{
    background-color: #fff;
    padding: 15px;
    border-radius: 30px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img{
        border-radius: 10px;
        margin-bottom: 18px;
        width: 100%;
        aspect-ratio: 2/1;
    }

    #video_name{
        font-size: 16px;
        color: black;
        font-weight: 700;
        line-height: 1.1;
    }

    #video_content{
        font-size: 14px;
        line-height: 1.1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.4em;
        line-height: 2.4em;
        margin-bottom: 10px;

        a{
            font-size: 13px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
        }

    }

    #video_writer{
        font-size: 14px;
        font-weight: 700;
        color: #666567;
    }

    #video_date{
        font-size: 14px;
        margin-bottom: 0px;
        color: #666567;
    }

}

.video_page_card{
    background-color: #fff;
    padding: 15px;
    border-radius: 30px;
    height: 100%;

    img{
        border-radius: 10px;
        margin-bottom: 18px;
        width: 100%;
        aspect-ratio: 2/1;
    }

    #video_name{
        font-size: 16px;
        color: black;
        font-weight: 700;
        line-height: 1.1;
    }

    #video_content{
        font-size: 14px;
        line-height: 1.1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.4em;
        line-height: 2.4em;
        margin-bottom: 10px;

        a{
            font-size: 13px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
        }

    }

    #video_writer{
        font-size: 14px;
        font-weight: 700;
        color: #666567;
    }

    #video_date{
        font-size: 14px;
        margin-bottom: 0px;
        color: #666567;
    }

}