.articles-section {
    height: 50vh;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: inherit;
        flex-wrap: wrap;
        height: 100%;
        .articles-title {
            h1 {
                color: black;
            }
            p {
                font-size: 18px;
                font-weight: 600;
                color: #39B5B2;
            }
        }
    }
}

.article-information-section {
    margin: 50px 0;
}

.individual-article-information-section {
    padding: 50px 0;
    background: #F3F5F7;
    .back_to_articles {
        margin-bottom: 30px;
    }
    .article-container {
        position: relative;
        width: 100%;
        /* 100% width for mobile and desktop */
        padding-bottom: 56.25%;
        /* Aspect ratio of 9:16 (height/width * 100) */
    }
    /* CSS for the embedded iframe */
    .article-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .back_to_articles {
        a {
            color: #39B5B2;
            font-size: 18px;
            font-weight: 700;
        }
        svg {
            margin-right: 10px;
        }
    }
    .doctor-group {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        display: flex;
        align-items: center;
        .doctor-name {
            font-style: italic;
            margin-right: 10px;
        }
        .doctor-img {
            width: 40px;
            margin-right: 10px;
            img {
                border-radius: 50%;
            }
        }
        .doctor-calender-date {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 25px;

            span.doctor-calender {
                margin-right: 10px;
            }
        }
        .icon-list {
            display: flex;
            flex-direction: row;
            position: absolute;
            right: 0;
            align-items: center;
        }
    }
    .article-content-2 {
        margin-top: 60px;
        p {
            line-height: inherit;
            margin: 30px 0;
        }
        h3 {
            margin: 30px 0;
        }
        button {
            background: var(--button);
            padding: 5px 20px;
            border-radius: 20px;
            border: 0;
            font-weight: 500;
        }
        .col-md-6 {
            margin-bottom: 20px;
        }
    }
}

.article-information-content-2 {
    background: white;
    padding: 35px;
    height: 100%;
    margin: 25px 0;
    
    .article-information-content-2-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .article-popular-content {
        li {
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
        }
        p.article_title {
            color: black;
            width: 150px;
        }

        p.article_date {
            color: #B1B1B1;
        }
    }

    .article-popular-content-whats-new {
        ul {
            list-style-type: disc;

            li {
                padding: 0 10px;
                list-style: disc;
                color: #B1B1B1;
                
                a{    
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        p.article_title {
            color: black;
            width: 150px;
        }

        p.article_date {
            color: #B1B1B1;
        }
    }

    .doctor_profile {
        img {
            border-radius: 50%;
        }
    }
    .doctor_des {
        padding: 20px 0;
        button {
            border: 0;
            background: none;
            color: var(--primary-color);
            font-weight: 600;
        }
    }
}
.slide-with-dots {
    position: relative;
    .slider {
        display: flex;
        overflow: hidden;
        position: relative;
        .slide {
            display: none;
            flex-shrink: 0;
            width: 100%;
            transition: transform 0.3s ease-in-out;
            img {
                width: 100%;
                height: 300px;
            }
            &.active {
                display: block;
            }
        }
    }
    .dots {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #919090;
            margin: 0 5px;
            cursor: pointer;
            &.active {
                height: 15px;
                width: 15px;
                background-color: #D0D0D0;
            }
        }
    }
}



@media screen and (max-width: 768px) {
    .article-information-section {
        .col-md-4,
        .col-md-8 {
            margin: 25px 0;
        }
    }

    .individual-article-information-section {
        .doctor-group {
            .icon-list {
                position: relative;
            }
        }
    }
}

.filter_table_article_mv{
    margin: 0px;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

@media (min-width: 481px) {

    .articles-section .mob_view {
        display: none !important;
    }

    .article-information-section .article_info_mobile, .filter_table_article_mv {
        display: none;
    }
}

@media (max-width: 480px) {

    .articles-section .web_view {
        display: none !important;
    }

    .article-information-section .article_info_normal {
        display: none;
    }

    .articles-section {
        height: 198px;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
    }
}

