/**********/
// fix for react-slick
.slider-container .slick-track{
    margin-left: unset;
}
/**********/
.individual-event-hero-section{
    background-repeat: no-repeat;
    background-size: cover;
    height: 390px;

    .container{
        height: inherit;
        display: flex;
        align-items: center;

        .individual-event-title{
            h1{
                color: black;
                width: 360px;
            }

            p{
                color: #39B5B2;
                font-weight: 600;
            }
        }
    }
}

.share-this-campaignspromotions-modal {
    .container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        width: 450px;
        .image-content {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;
            img {
                width: 150px;
                border-radius: 15px;
                margin-right: 20px;
            }
            #campaignspromotions-title {
                font-size: 15px;
                font-weight: 700;
                margin-bottom: 3px;
                color: black;
            }
            #campaignspromotions-validedate {
                font-size: 12px;
                color: #666567;
            }
        }
        .social-media-profile {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            p {
                color: black;
                margin: 0;
                font-size: unset;
            }

            #social-media-container {
                width: 120px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid gray;
                border-radius: 8px;
                padding: 2px 10px;
                margin-right: 10px;
                margin-top: 10px;
            }
        }
    }
}


.individual-events-information-section, .individual-events-information-section-mv {
    background-color: rgb(243, 245, 247);
    padding: 50px 80px;
    min-height: 80vh;
    .container {
        .events-content-2 {
            margin-top: 50px;
        }
        .events-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 300px;
                border-radius: 20px;
            }
            #book-package {
                border: 1px solid #967F65;
                background-color: #967F65;
                width: 270px;
                border-radius: 30px;
                text-align: center;
                margin-top: 12px;
                cursor: pointer;
                p {
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 13px;
                    color: white;
                }
            }
            #add-to-cart {
                border: 1px solid #967F65;
                width: 270px;
                border-radius: 30px;
                text-align: center;
                margin-top: 12px;
                cursor: pointer;
                p {
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 13px;
                    color: #967F65;
                }
            }
            #download-full-broc {
                margin-top: 12px;
                cursor: pointer;
                p {
                    color: black;
                }
            }
        }
        .events-content-1 {
            h2 {
                padding-top: 5px;
                max-width: 500px;
            }
        }
        .events-content-2 {
            h3 {
                color: black;
                margin-bottom: 10px;
            }
            p {
                font-size: 18px;
                margin-bottom: 40px;
                line-height: 1.5;
                text-align: justify;
            }
        }
    }
}

.individual-events-other-section {
    background-color: #EBEDEF;
    padding: 50px 80px;
    .events-card {
        background-color: white;
        padding: 17px 0px;
        border-radius: 20px;
        margin-top: 20px;
        margin-left: 20px;
        .container {
            .events-image {
                img {
                    border-radius: 10px;
                }
            }
            .events-content {
                margin: 0;
                position: relative;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                h3 {
                    color: #967F65;
                }
                p {
                    font-size: 12px;
                    margin-bottom: 15px;
                    line-height: 1.1;
                }
                h5 {
                    font-weight: 600;
                    color: black;
                }
                button {
                    border: 0;
                    background: transparent;
                    color: var(--primary-color);
                }
            }
        }
    }
}

.events-card {
    background-color: white;
    padding: 5px;
    border-radius: 20px;
    // width: 450px;

    .container {
        display: flex;
        padding: 5px;

        .events-image {
            img {
                border-radius: 10px;
            }
        }
        .events-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px;
            height: 150px;

            h3 {
                color: #967F65;
            }
            p {
                font-size: 14px;
                margin-bottom: 15px;
                line-height: 1.1;
                color: #606060;
            }
            h5 {
                font-weight: 600;
                color: black;
            }
            button {
                border: 0;
                background: transparent;
                color: var(--primary-color);
            }
        }
        .events-detail {
            display: flex;
            justify-content: space-between;

            .events-date {
                color: #666567;
                font-size: 14px;
            }
            span.events-writer {
                font-weight: 600;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .individual-events-information-section {
        padding: 0;
    }
}

@media (min-width: 481px) {
    .individual-events-information-section-mv{
        display: none;
    }

    .individual-event-hero-section .back_to_mob {
        display: none !important;
    }

    .individual-campaignspromotions-other-section .recomm_event_mob_slider {
        display: none !important;
    }

    .share-this-campaignspromotions-modal .share_event_mob_view {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .individual-events-information-section{
        display: none;
    }

    .individual-event-hero-section .back_to_web {
        display: none !important;
    }

    .individual-campaignspromotions-other-section .recomm_event_web_slider {
        display: none !important;
    }

    .share-this-campaignspromotions-modal .share_event_web_view {
        display: none !important;
    }

    .individual-events-information-section-mv {
        padding: 0 0 20px 0;
    }

    .individual-events-information-section-mv .container .events-image img {
        width: -webkit-fill-available;
        border-radius: 20px;
    }

    .individual-events-information-section-mv .container .events-content-2 p {
        font-size: 18px;
        margin-bottom: 0;
        line-height: 1.1;
        color: #666567
    }

    .individual-event-hero-section {
        background-repeat: no-repeat;
        background-size: cover;
        height: 200px;
    }

    .individual-campaignspromotions-other-section .eos_mobile{
        overflow-y: hidden;
        -ms-overflow-style: none; 
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .events-card {
        width: 351px;
        margin-bottom: 20px;
        // margin-inline: auto;

        .container {
            .events-content {
                padding: 10px;
            }
        }     
    }

    .share-this-campaignspromotions-modal .container .social-media-profile p {
        font-size: 15px;
    }

    .share-this-campaignspromotions-modal .container .image-content img {
        width: 100%;
    }
}