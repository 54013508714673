.pagination {
    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .page-number {
            display: inline-block;
            background-color: white;
            border-radius: 10px;
            padding: 15px 30px;
            margin: 50px;
            a {
                color: black;
                padding: 8px 16px;
                margin-right: 5px;
            }
            a.active {
                background-color: #5675d0;
                color: white;
                border-radius: 5px;
            }
        }
        .page-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 15px;
            p {
                margin-right: 10px;
            }
            input {
                margin-top: -15px;
                margin-right: 10px;
                width: 60px;
            }
        }
    }
}

._pagination.page-number{
    display: inline-block;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    margin: 50px;
    box-shadow: 0px 4px 10px 0px #B7BBCA5C;
    a{
        color: black;
        padding: 5px 8px;
        margin-right: 5px;
        &.active{
            background-color: #5675d0;
            color: white !important;
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 768px) {
    .pagination {
        .container {
            .page-number {
        margin: 20px;
    }
}
}
}