.my_cart_area_section{
    background-color: rgb(243, 245, 247);
    padding: 50px 0px;
    min-height: 100vh;

    .container{
        h2{
            color: black;
            margin-bottom: 20px;
        }

        .category_container{
            background-color: white;
            border-radius: 15px;
            padding: 40px 20px;
            margin-bottom: 20px;

            #product_category_name{
                font-size: 20px;
                font-weight: 700;
                color: black;
                margin-bottom: 0;
                margin-left: 8px;
            }

            #voucher_option{
                border: 1px solid #967F65;
                padding: 5px;
                border-radius: 30px;
                color: #967F65;
                font-weight: 600;
                margin-left: 40px;
                max-width: 200px;
            }

        }

        .bottom_navigation{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            #select_all{
                font-weight: 600;
                margin-left: 10px;
                margin-right: 60px;
                color: #606060;
            }

            #delete{
                font-weight: 600;
                margin-right: 60px;
                cursor: pointer;
                color: #606060;
            }

            #move_to_my_collections{
                font-weight: 600;
                cursor: pointer;
                // margin-right: 20px;
                color: #606060;
            }

            #selected_product_quantity{
                color: #E98076;
                font-weight: 600;
                margin-right: 10px;
            }

            #selected_products{
                color: black;
                font-weight: 600;
                margin-right: 40px;
            }

            #total{
                color: black;
                font-weight: 600;
                margin-right: 40px;
            }

            #total_price{
                color: #E98076;
                font-weight: 600;
                font-size: 20px;
            }
        }


    }
}

.product{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .product_left{
        flex: 50%;

        img{
            border-radius: 20px;
            padding: 10px;
            max-height: 150px;
            max-width: 150px;
        }
    
        #product_name{
            font-size: 1.3vw;
            font-weight: 700;
            color: black;
        }
    
        #product_hosp_name{
            color: black;
            font-size: 1.1vw;
        }
    
        .username_box{
            border: 1px solid gray;
            border-radius: 10px;
            padding: 10px;
    
            width: 200px;
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 10px;
            cursor: pointer;
        }

    }

    .product_right{
        flex: 22%;

        #product_unit_price_cancelled{
            font-weight: 600;
            text-decoration: line-through;
            text-decoration-thickness: 1px;
        }
    
        #product_unit_price{
            font-weight: 600;
            color: #606060;
        }
    
        #quantity_control_button{
            border: 0;
            background-color: transparent;
            font-size: 20px;
        }
    
        #product_quantity{
            border: 1px solid gray;
            background-color: transparent;
            padding: 5px 10px;
            border-radius: 8px;
        }
    
        #product_total_price{
            font-weight: 700;
            color: #E98076;
        }
    }
}

.username_modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;

    .container {
        position: relative;
        padding: 2%;
        background-color: white;
        border-radius: 15px;
        width: 50%;
        height: fit-content;
        max-height: 100%;

        // overflow: auto;
        // &::-webkit-scrollbar {
        //     width: 25px;
        // }

        // &::-webkit-scrollbar-thumb {
        //     background-color: #967F65;
        //     border-radius: 30px;
        //     border: 10px solid white;
        // }

        .web_view {
            position: absolute;
            // text-align: right;
            // margin-top: 10px;
            top: 10px;
            right: 10px;
            background-color: white;
        }

        .mobile_view {
            position: absolute;
            left: 90%;
            top: 2%;
        }

        .image-name-title {
            display: flex;
            flex-direction: row;
            margin-bottom: 3%;
      
            img {
                width: 20%;
                height: 100%;
                border-radius: 15px;
                margin-right: 20px;
            }
      
            #name-title {     
                display: flex;
                flex-direction: column;
                justify-content: center;

                #tag1 {
                    padding-top: 10px;
                    color: black;
                    font-size: 1.5vw;
                    font-weight: 700;
                }
      
                #tag2 {
                    margin-bottom: 2%;
                    background-color: #967F65;
                    color: #fff;
                    padding: 0.3em 0.8em;
                    border-radius: 25px;
                    font-size: 1vw;
                    width: fit-content;
                }
      
                #tag3 {
                    color: #967F65;
                    font-size: 1.5vw;
                    font-weight: 700;
                }
            }
        }

        #form_select_member{
            background-color: #EAEDF8;
            border-radius: 10px;
            margin-bottom: 2%;

            select#member_id {
                border: 0px;
                background-color: transparent;
                font-size: 1vw;
                width: 100%;
                padding: 2%;
                border-radius: 10px;

                option {
                    font-size: 1vw;
                }
            }
        }

        .form_field_container {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            row-gap: 10px;

            input {
                &::placeholder {
                    font-size: 1vw;
                }
            }
        }

        #form_name, 
        #form_relationship, 
        #form_dateOfBirth, 
        #form_phoneNumber,
        #form_email {
            display: flex;
            flex-direction: column;
            width: 48%;
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 0.8vw;
        }

        #form_gender, 
        #form_countryOfResident, 
        #form_nric {
            display: flex;
            flex-direction: column;
            width: 48%;
            background-color: #EAEDF8;
            border-radius: 5px;
            padding: 0.8vw;
        }
    }
}

.checkout_confirmed_modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .container{
        position: fixed;
        padding: 50px;
        background-color: white;
        border-radius: 20px;
        width: 350px;
        text-align: center;

    }
}

@media (max-width:800px) {
    .product_left, .product_right{
        flex: 100%;
    }
}

@media (min-width:481px) {
    .product_mob_view{
        display: none !important;
    }

    .my_cart_area_section {
        .container {
            .delete_mob_view {
                display: none !important;
            }
        }
    }

    .username_modal .mobile_view {
        display: none !important;
    }
}

@media (max-width:480px) {

    .my_cart_area_section .container .bottom_navigation #select_all {
        font-weight: 600;
        margin-left: 10px;
        margin-right: 0;
        color: #606060;
    }

    .my_cart_area_section {
        .container {
            .product_info {
                display: none !important;
            }
        }
    }

    .product_web_view {
        display: none !important;
    }

    .product {
        align-items: flex-start;
        margin-bottom: 20px;

        .product_left {
            flex: 15% 1;

            img {
                border-radius: 10px;
                padding: 0;
                margin-left: 10px;
                width: 104px;
                height: 104px;
            }
        }

        .product_right {
            .username_box{
                border: 1px solid gray;
                border-radius: 10px;
                padding: 10px;
        
                // max-width: 400px;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-top: 10px;
                margin-bottom: 22px;
                cursor: pointer;
            }

            #product_total_price {
                font-weight: 500;
                color: #E78076;
                font-size: 17px;
                line-height: 20.76px;
            }
        }
    }

    .my_cart_area_section {
        .container {
            .bottom_navigation {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-start;

                #total {
                    color: #606060;
                    font-weight: 500;
                    margin-right: 0;
                    font-size: 13px;
                }
            }
        }
    } 

    .username_modal .container {
        #form_name, 
        #form_relationship, 
        #form_dateOfBirth, 
        #form_phoneNumber,
        #form_email {
            width: 100%;
        }
    
        #form_gender, 
        #form_countryOfResident, 
        #form_nric {
            width: 100%;
        }
    }
}