.gl-application {
    max-width: fit-content;
    margin: 0 5rem;
    .input-field {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;

        input {
            border: none;
            background-color: transparent;
            font-size: 14px;
        }
    }

    .select-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #eaedf8;
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;

        label {
            font-size: 14px;
        }
    }

    ._card {
        color: #000;
        border-radius: 20px;
        border: none;
        background-color: #fff;
        width: 100%;
        margin-inline: auto;
    }

    .form-container {
        padding: 30px;
        .title-container {
            background-color: #5675d3;
            color: #fff;
            h5 {
                margin: 0px;
                color: #fff !important;
            }
        }
    }

    .phone-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 70px;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;
        justify-content: center;
    }

    .input-resume {
        border: transparent;
        margin-top: 4px;
    }

    .btn-blue[disabled] {
        background-color: #5675d3 !important;
        opacity: 0.5;
    }

    .date-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;
        justify-content: center;
    }
}
.anciliary-content{
    max-width: fit-content;
    margin: 0 5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

@media (max-width: 480px) {
    .gl-application {
        margin: 0 auto;
        .form-container {
            padding: 0;
        }
    }
    .anciliary-content{
        margin: 0 1rem;
    }
}
