.who-we-are-hero-section{
    background-size: cover;
    background-repeat: no-repeat;
    height: 390px;
  
    .container{
      height: inherit;
      display: flex;
      align-items: center;
  
      h1{
        color: black;
      }
    }
}

.who-we-are-vision-section{
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: stretch;

    .content-container{
        position: relative;
        width: 50%;
        margin-left: 80px;

        p {
            margin-top: 30px;
            margin-right: 50px;
            line-height: 1.5;
            text-align: justify;
        }

        h3 {
            font-size: 30px;
            margin-right: 50px;
            text-align: justify;
        }

        h2 {
            font-size: 40px;
            margin-right: 50px;
            margin-top: 120px;
            text-align: justify;
        }

        .mission-box {
            //background-color: #967F65;
            color: white;
            width: 250px;
            height: 150px;
            display: block;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 24px;
            position: absolute;
            bottom: 0;
            right: 0;
            
            .box-title {
                color:white;
                text-align: start;
                font-size: 20px;
                margin-left: 50px;
                margin-right: 0px;
                padding-top: 15px;
            }
            
            .box-desc {
                color: white;
                text-align: start;
                padding: 0px 50px 15px 50px;
                font-size: 14px;
            }
        }
    }

    .image-container {
        position: relative;
        width: 50%;
    
        img {
          display: block;
          width: 100%;
          height: auto;
        }
    
        .arrow-box {
          //background-color: #371450;
          color: white;
          width: 250px;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-size: 36px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
    }
}

.who-we-are-vision-section-mob {
    margin-top: 60px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content-container {
      position: relative;
      width: 90%;
      margin: 0;

      p {
        margin-top: 10px;
        margin-right: 0;
        line-height: 1.5;
        text-align: justify;
        font-size: 18px;
      }

      h3 {
        font-size: 30px;
        margin-right: 0;
        text-align: justify;
      }

      h2 {
        font-size: 40px;
        margin-right: 0;
        margin-top: 10px;
        text-align: justify;
      }
    }

    .image-container {
        position: relative;
        width: 90%;
  
        img {
          display: block;
          width: 100%;
          height: auto;
        }
  
        .bottom-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
  
          .mission-box, .arrow-box {
            width: 50%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 24px;
            cursor: pointer;
            color: white;

            .box-title {
                color:white;
                text-align: start;
                font-size: 10px;
                margin-left: 40px;
                margin-right: 20px;
                padding-top: 8px;
            }
            
            .box-desc {
                color: white;
                text-align: start;
                //padding: 0px 50px 15px 50px;
                font-size: 8px;
            }
          }
        }
    }
}

.who-we-are-healthcare-section{
    position: relative;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

    .content-container{
        margin-top: 100px;
        max-width: 600px;
        margin-inline: 10px;
        margin-right: 100px;

        p {
            margin-top: 30px;
            //margin-left: 80px;
            line-height: 1.5;
            text-align: justify;
        }

        h2 {
            font-size: 40px;
            //margin-left: 80px;
            text-align: justify;
        }
    }
}  

.who-we-are-healthcare-section-mob{
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    .content-container{
        margin: 0;
        padding: 50px;
        p {
            margin-top: 30px;
            //margin-left: 80px;
            line-height: 1.5;
            text-align: justify;
        }

        h2 {
            font-size: 40px;
            //margin-left: 80px;
            text-align: justify;
        }
    }
}  

.who-we-are-intro-section {
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    display: flex;
    flex-direction: column;
  
    .container {
      height: inherit;
      display: flex;
      align-items: center;
  
      .brown-card {
        background-color: #967F65;
        width: 500px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: start;
        position: relative;
        color: white;
        text-align: center;
        padding: 40px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

        h2 {
            color: white;
        }

        h5 {
            color: white;
            margin-top: 5px;
        }

        h3 {
            margin-top: 30px;
            color:white;
            text-align: start;
        }

        img#arrow_circle_icon {
            width: 80px;
            height: 80px; 
            position: absolute;
            bottom: 30px;
            right: 30px;
        }
      }
    }
}

.who-we-are-intro-container {
    height: inherit;
    display: flex;
    align-items: center;
    margin: 50px;
}
  

@media (min-width: 1025px) {
  .wwa_mob_web {
    display: none !important;
  }

  .who-we-are-vision-section-mob, .who-we-are-healthcare-section-mob{
    display: none !important;
  }

  .who-we-are-vision-section{
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: stretch;

    .content-container{
        position: relative;
        width: 50%;
        margin-left: 80px;

        p {
            margin-top: 30px;
            margin-right: 50px;
            line-height: 1.5;
            text-align: justify;
        }

        h3 {
            font-size: 30px;
            margin-right: 50px;
            text-align: justify;
        }

        h2 {
            font-size: 40px;
            margin-right: 50px;
            margin-top: 120px;
            text-align: justify;
        }

        .mission-box {
            //background-color: #967F65;
            color: white;
            width: 250px;
            height: 150px;
            display: block;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 24px;
            position: absolute;
            bottom: 0;
            right: 0;
            
            .box-title {
                color:white;
                text-align: start;
                font-size: 20px;
                margin-left: 50px;
                margin-right: 0px;
                padding-top: 15px;
            }
            
            .box-desc {
                color: white;
                text-align: start;
                padding: 0px 50px 15px 50px;
                font-size: 14px;
            }
        }
    }

    .image-container {
        position: relative;
        width: 50%;
    
        img {
          display: block;
          width: 100%;
          height: auto;
        }
    
        .arrow-box {
          //background-color: #371450;
          color: white;
          width: 250px;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-size: 36px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
    }
}  
}

@media (max-width: 1024px) {
    .wwa_hero_web {
      display: none !important;
    }
  
    .who-we-are-hero-section {
      height: 328px;
    }
  
    .wwa_mob_web {
      .wwa_mobile_title {
        font-weight: 700;
        font-size: 32px;
        line-height: 28.75px;
        color: rgb(0, 0, 0);
        width: 116px;
      }
    }

    .who-we-are-vision-section, .who-we-are-healthcare-section {
        display: none;
    } 
  }

@media (max-width: 768px) {
  .who-we-are-hero-section {
    height: 255px;
  }

    .who-we-are-vision-section, .who-we-are-vision-section, .who-we-are-healthcare-section {
        display: none;
    }
}

@media (max-width: 480px) {

  .who-we-are-hero-section {
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    background-position: center;
  }

  .wwa_mob_web {
    .wwa_mobile_title {
      font-weight: 700;
      font-size: 25px;
      line-height: 28.75px;
      color: rgb(0, 0, 0);
      width: 116px;
    }
  }

  .who-we-are-vision-section,.who-we-are-healthcare-section{
    display: none;
  }

  .who-we-are-vision-section-mob {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content-container {
      position: relative;
      width: 90%;
      margin-left: 0;
      margin-top: 20px;

      p {
        margin-top: 10px;
        margin-right: 0;
        line-height: 1.5;
        text-align: justify;
        font-size: 12px;
      }

      h3 {
        font-size: 16px;
        margin-right: 0;
        text-align: justify;
      }

      h2 {
        font-size: 26px;
        margin-right: 0;
        margin-top: 10px;
        text-align: justify;
      }
    }

    .image-container {
        position: relative;
        width: 90%;
  
        img {
          display: block;
          width: 100%;
          height: auto;
        }
  
        .bottom-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
  
          .mission-box, .arrow-box {
            width: 50%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 24px;
            cursor: pointer;
            color: white;

            .box-title {
                color:white;
                text-align: start;
                font-size: 10px;
                margin-left: 40px;
                margin-right: 20px;
                padding-top: 8px;
            }
            
            .box-desc {
                color: white;
                text-align: start;
                //padding: 0px 50px 15px 50px;
                font-size: 8px;
            }
          }
        }
    }
  } 

  .who-we-are-healthcare-section-mob{       
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    .content-container{
        margin: 0;
        padding: 50px;
        p {
            margin-top: 30px;
            //margin-left: 80px;
            line-height: 1.5;
            text-align: justify;
        }

        h2 {
            font-size: 20px;
            //margin-left: 80px;
            text-align: justify;
        }
    }
    }  

    .who-we-are-intro-section {
        background-size: cover;
        background-repeat: no-repeat;
        height: 400px;
        display: flex;
        flex-direction: column;
      
        .container {
          height: inherit;
          display: flex;
          align-items: center;
      
          .brown-card {
            background-color: #967F65;
            width: 500px;
            height: 400px;
            display: flex;
            flex-direction: column;
            align-items: start;
            position: relative;
            color: white;
            text-align: center;
            padding: 40px;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    
            h2 {
                color: white;
            }
    
            h5 {
                color: white;
                margin-top: 5px;
            }
    
            h3 {
                margin-top: 30px;
                color:white;
                text-align: start;
            }
    
            img#arrow_circle_icon {
                width: 80px;
                height: 80px; 
                position: absolute;
                bottom: 30px;
                right: 30px;
            }
          }
        }
    }
}