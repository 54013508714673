.health-package-hero-section{
  background-size: cover;
  background-repeat: no-repeat;
  height: 390px;

  .container{
    height: inherit;
    display: flex;
    align-items: center;

    h1{
      color: black;
    }
  }
}

.health_packages_section {
    background-color: rgb(243, 245, 247);
    padding-top: 150px;
    padding-bottom: 50px;

    .container {
      h2 {
        color: black;
        margin-bottom: 50px;
      }

      .packages-pagination {
        .container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          column-gap: 20px;
    
          .page-number {
            display: inline-block;
            background-color: white;
            border-radius: 10px;
            padding: 15px;
            margin: 50px 0;
            box-shadow: 0px 4px 10px 0px #B7BBCA5C;
    
            a {
              color: black;
              padding: 5px 8px;
              margin-right: 5px;
  
              &:hover {
                background-color: rgb(243, 245, 247);
                border-radius: 5px;
              }
  
              &.active {
                background-color: #FCB11D;
                color: white !important;
                border-radius: 5px;
              }
            }
          }
    
          .page-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 15px;
  
            p {
              margin-right: 10px;
              font-size: 15px;
            }
  
            input {
              margin-top: -15px;
              margin-right: 10px;
              width: 60px;
            }
          }
        }
      }
    }
  }

.package-image-container{
  height: 180px;
  display: grid;
}
.health_packages_card{
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease 0s;
    
    img{
      border-radius: 15px;
      margin-bottom: 18px;
      width: 100%;
      // aspect-ratio: 1/1; // removed on 19/9/2024, ugly with cms size
      margin: auto;
      max-height: 180px;
    }

    #package_name{
        font-size: 18px;
        color: black;
        font-weight: 700;
        margin-bottom: 0px;
        line-height: 1.2;
        padding: 5px 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    #hosp_name{
        font-size: 13px;
        color: black;
        margin-bottom: 0px;
        padding: 0px 15px;
    }

    .package_price{
        font-size: 20px;
        color: #F06661;
        font-weight: 700;
        padding: 0 15px;
    }

    #book_package{
      border: 1px solid #967F65;
      background-color: #fff;
      color: #967F65;
      width: 100%;
      padding: 15px;
      border-radius: 30px;
      text-align: center;
      padding: 10px 5px;
      margin: 10px 0;
      font-size: 12px;
      font-weight: 700;
      cursor: pointer;

      &:hover {
        background-color: #967F65;
        color: #fff;
      }
    }
    
    #download_full_broc{
        color: black;
    }
}

.health_packages_card_home{
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  height: 456px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  img{
    border-radius: 15px;
    margin-bottom: 18px;
    width: 100%;
    // aspect-ratio: 1/1;
    max-height: 180px;
    // height: 286px;
  }

  #package_name{
      font-size: 18px;
      color: black;
      font-weight: 700;
      margin-bottom: 0px;
      line-height: 1.2;
      padding: 5px 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }

  #hosp_name{
      font-size: 13px;
      color: black;
      margin-bottom: 0px;
      padding: 0px 15px;
  }

  .package_price{
      font-size: 20px;
      color: #967F65;
      font-weight: 700;
      padding: 0 15px;
  }

  #book_package{
    border: 1px solid #967F65;
    color: #967F65;
    width: full-width;
    padding: 15px;
    border-radius: 30px;
    text-align: center;
    padding: 10px 5px;
    margin: 8px 15px 14px 15px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }
  
  #download_full_broc{
      color: black;
  }
}

.health_packages_card_collections{
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  img{
    border-radius: 15px;
    margin-bottom: 18px;
    width: 100%;
    aspect-ratio: 1/1;
  }

  #package_name{
      font-size: 18px;
      color: black;
      font-weight: 700;
      margin-bottom: 0px;
      line-height: 1.2;
      padding: 5px 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }

  #hosp_name{
      font-size: 13px;
      color: black;
      margin-bottom: 0px;
      padding: 0px 15px;
  }

  .package_price{
      font-size: 20px;
      color: #967F65;
      font-weight: 700;
      padding: 0 15px;
  }

  #book_package{
    border: 1px solid #967F65;
    color: #967F65;
    width: full-width;
    padding: 15px;
    border-radius: 30px;
    text-align: center;
    padding: 10px 5px;
    margin: 8px 15px 14px 15px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }
  
  #download_full_broc{
      color: black;
  }
}

.health_packages_card:hover {
  transform: scale(1.04);
  box-shadow: 0px 4.144577503204346px 10.371703147888184px 0px #B7BBCA80;
  border: 1.01px solid #967F65
}

.health-package-filter-container{
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    height: 150px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

button.search-package {
    position: absolute;
    bottom: -93px;
    right: 10%;
}

@media (min-width: 1025px) {
  .hp_mob_web {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .hp_hero_web {
    display: none !important;
  }

  .health-package-hero-section {
    height: 328px;
  }

  .hp_mob_web {
    .hp_mobile_title {
      font-weight: 700;
      font-size: 32px;
      line-height: 28.75px;
      color: rgb(0, 0, 0);
      width: 116px;
    }
  }

  .health_packages_section {
    padding-top: 30px;
  }
}

@media (max-width: 768px) {
  .health-package-hero-section {
    height: 255px;
  }
}

@media (max-width: 480px) {

  .health-package-hero-section {
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    background-position: center;
  }

  .health_packages_section {
    padding-top: 0 !important;
  }

  .health_packages_card {
    // height: 604px;
    height: unset;
    margin: auto;

    #package_name {
      font-size: 21px;
      white-space: pre-wrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      // line-height: 1.2em;
      // height: 3em;
    }
  }

  .health_packages_card img {
    height: unset;
  }

  .health_packages_card_collections {
    width: 90%;
    margin: auto;

    img {
      height: unset;
    }
  }

  .hp_mob_web {
    .hp_mobile_title {
      font-weight: 700;
      font-size: 25px;
      line-height: 28.75px;
      color: rgb(0, 0, 0);
      width: 116px;
    }
  }
}