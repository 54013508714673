.podcast-section {
    background-repeat: no-repeat;
    height: 390px;
    background-size: 100%;

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: inherit;
        flex-wrap: wrap;
        height: 100%;

        .podcast-title {
            h1 {
                color: black;
            }
            p {
                font-size: 18px;
                font-weight: 600;
                color: #39B5B2;
            }
        }
    }
}

.podcast_listing_card {
    background-color: #fff;
    padding: 15px;
    border-radius: 25px;
    height: 100%;

    .podcast_image {
        border-radius: 10px;
        margin-bottom: 20px;
        width: 100%;
        height: 250px;
    }

    #podcast_name {
        font-size: 16px;
        color: black;
        font-weight: 700;
        line-height: 1.1;
        text-overflow: ellipsis;
    }

    #podcast_writer {
        margin: 0;
        margin-top: 20px;
        // line-height: 0;
        font-size: 14px;
        font-weight: 700;
        color: #666567;
    }

    #podcast_espisode {
        font-weight: 700;
        color: var(--primary-color);
    }

    #podcast_content {
        font-size: 10px;
        line-height: 1.1;
        a {
            font-size: 10px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
        }
    }

    #podcast_date {
        font-size: 14px;
        margin-bottom: 0px;
        color: #666567;
    }
}

.my_collections_podcasts_card {
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    margin-top: 15px;
    // max-width: 400px;

    img {
        border-radius: 10px;
    }

    #podcast_name {
        font-size: 16px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.4em;
        line-height: 1.2em;
        margin-bottom: 10px;
        color: black;
    }

    #podcast_writer {
        line-height: 1.1em;
        font-size: 14px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 2.2em;
        margin-bottom: 10px;
        text-wrap: balance;
    }

    #podcast_espisode {
        font-weight: 700;
        color: var(--primary-color);
        font-size: 15px;
        margin-bottom: 0;
    }

    #podcast_content {
        font-size: 10px;
        line-height: 1.1;
        a {
            font-size: 10px;
            line-height: 1.1;
            color: #967F65;
            font-weight: 700;
        }
    }

    #podcast_date {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .podcast_image {
        position: relative;
        height: 100%;
        
        svg {
            color: white;
        }
    }

    .podcast_play_icon {
        position: absolute;
        background: #E98076;
        bottom: 10px;
        right: 10px;
        border-radius: 50%;
    }
}

.filter_table_podcast_mv{
    position: absolute;
    bottom: -12%;
    margin: 0px;
    width: 100%;
    text-align: center;
}

@media (min-width: 1025px) {
    .podcast-section .filter_table_podcast_mv{
        display: none;
    }
}

@media (max-width: 1024px) {
    .podcast-section {
        background-repeat: no-repeat;
        height: 290px;
        background-size: cover;
        background-position: center;
        margin-bottom: 50px
    }
}

// @media (min-width: 481px) {
//     .podcast-section .filter_table_podcast_mv{
//         display: none;
//     }
// }

@media (max-width: 480px) {
    .podcast-section {
        background-repeat: no-repeat;
        height: 198px;
        background-size: cover;
        background-position: center;
        margin-bottom: 50px
    }
}

@media screen and (max-width: 425px) {
    .my_collections_podcasts_card {
        #podcast_name {
            font-size: 14px;
            padding: 10px 0;
        }
    }
}
