.filter-table-group {
    margin-top: 50px;
    margin-bottom: 50px;
    .title {
        p {
            font-size: 30px;
            color: black;
            font-weight: bold;
        }
    }
    .filter-table {
        display: flex;
        // flex-wrap: wrap;
        /* justify-content: space-between; */
        // overflow: auto;
        .sortby {
            display: flex;
            background: #EFEFEF;
            margin: 10px;
            border-radius: 5px;
            align-items: center;
            .selection-dropdown select {
                width: 120px;
            }
        }
        .filter {
            display: flex;
            background: #EFEFEF;
            margin: 10px 0;
            border-radius: 5px;
            align-items: center;

            // .event_filter{
            //     padding: 0;
            // }
        }
        .search_button {
            // background-image: linear-gradient(97.06deg, #967F65 2.38%, #F6E6D4 116.41%);
            background-color: #FCB11D;
            margin: 0 10px 0 0;
            padding: 10px 15px;
            border: 0;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            width: max-content;
        }
        .filter-input{
            display: flex;
            border-radius: 5px;
            margin: 0 10px;
            background-color: #fff;
            align-items: center;
            padding: 0px 12px;
            font-size: 13px;
            max-height: calc(1.2em + 24px);
            height: calc(1.2em + 24px);
            input{
                border: none;
                background-color: #fff;
            }
            .search-icon{
                color: #cfcfcf;
            }
        }
        .selection-dropdown select {
            border-radius: 5px;
            margin: 0 2px;
            max-width: 190px;
            min-width: 100px;
            font-size: 13px;
            max-height: calc(1.2em + 24px);
            height: calc(1.2em + 24px);
            white-space: pre-wrap;
            padding-left: 12px;
            line-height: 1;
            padding-right: 25px;


            /* beh added to change the arrow styling */
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-size: 18px;
            background-position-x: 95%;
            background-position-y: 12px;
           
        }
    }
   
}

#filter-btn-mv{
    display: none;
}

@media screen and (max-width: 768px) {
    .filter-table-group {
        .filter-table {
            flex-direction: column;
            .selection-dropdown select {
                border-radius: 5px;
                margin: 0 2px;
                width: 150px;
                font-size: 13px;
                height: 30px;
            }
        }
    }
    .filter-table-group {
        .filter-table {
            .filter {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                gap: 10px;
            }
            .sortby {
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                display: grid;
                justify-content: space-between;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .filter-table-group {
        .filter-table {
            .selection-dropdown {
                select {
                    width: 100px;
                }
            }
        }
    }
}

@media (min-width:769px) {
    .filter_table_mv, #filter-btn-mv{
        display: none;
    }
}

@media (max-width:480px) {
    .filter-table-group{
        display: none;
    }
    
    .filter_table_mv{
        display: flex;
        align-items: center;
        justify-content: center;
        // margin: 70px 0px 20px 0px;
    }

    #filter-btn-mv{
        border: 0;
        box-shadow: 0 4px 10px 0 rgba(183, 188, 202, 0.36);
        background: #FFFFFF;
        width: 90%;
        height: 48px;
        border-radius: 32px;
        text-align: left;
        padding: 0 20px;
        font-size: 13px;
        font-weight: 700;
        display: block !important;
        margin: auto;

        span{
            margin-right: 10px;
        }
    }
}