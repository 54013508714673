.appointment-popup-section {

    .MuiDialog-paperWidthSm {
        min-width: 56%;
        padding: 10px;
        border-radius: 20px;
        max-height: 98%;
    }

    .MuiDialog-paper {
        margin: 32px;
        position: relative;
        overflow-y: hidden;
    }

    .MuiDialogTitle-root {
        flex: 0 0 auto;
        margin: 0;
        padding: 0px 24px;
    }

    // form height
    .MuiDialogContent-root {
        height: fit-content;
        overflow-y: auto;
    }

    //Next button
    .MuiDialogActions-root {
        padding: 0 24px 0 24px;
    }

    .MuiButton-label {
        width: 100%;
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
        padding: 4px;
        font-size: 1vw;
    }

    .MuiTypography-h6 {
        font-size: 1.5vw;
        font-weight: 700;
        color: #000;
    }

    // hospital selection
    .selection-dropdown-gray, .selection-dropdown {
        width: 100%;
        margin: 0;
        border-radius: 5px;
        padding: 0.5rem 0.5rem 0.5rem 0;

        display: flex;
        flex-direction: column;

        label {
            font-size: 0.8vw;
            color: black;
            padding-left: 0.5rem;
        }

        select {
            font-size: 0.8vw;
            color: rgb(167, 167, 167);
            background: transparent;
            border-radius: 5px;
            padding: 0 0 0 0.5rem;
            cursor: pointer;

            option {
                font-size: 0.8vw;
            }
        }
    }

    .selection-dropdown-gray {
        background: #f5f5f5;
    }

    .selection-dropdown {
        background-color: #DBDDE4;
        height: 100%;
    }

    .select_date_time {
        h3 {
            font-size: 1vw;
            color: black;
            margin: 2% 0;
        }

        .selection-dropdown-date-time {
            background: #E9F8F8;
            width: 35%;
            margin: 0;
            display: block;
            border-radius: 5px;
            padding: 0.5rem;

            label {
                font-size: 0.8vw;
                color: black;
            }

            button {
                border: 0px;
                background-color: transparent;
                color: rgb(87, 117, 203);
                font-weight: 500;
                font-size: 0.8vw;
            }

            input {
                background: transparent;
                font-weight: 600;
                font-size: 0.8vw;
            }
        }
    }

    .patients_details, .additional_fields {
        h3 {
            font-size: 1vw;
            color: black;
            margin: 2% 0;
        }
    }

    .additional_fields {
        label {
            cursor: pointer;
            margin-right: 8px;
            margin-left: 5px;
            display: flex;
            color: black;
            font-size: 0.8vw;
        }

        select {
            width: 100%;
            font-size: 0.8vw;
            border: none;
            appearance: none;
            cursor: pointer;
        }
    }

    ._patient_details_input {
        background: #f5f5f5;
        width: 100% !important;
        margin: 0;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 0.5rem 0.5rem 0.5rem 0;

        label {
            font-size: 0.8vw;
            color: black;
            padding-left: 0.5rem;
        }

        input {
            background: transparent;
            font-size: 0.8vw;
            font-weight: 700;

            &::placeholder {
                font-size: 0.8vw;
                font-weight: 400;
            }
        }
    }
}

.confirm_appointment_details {

    h3 {
        font-size: 1vw;
    }

    label {
        font-size: 0.8vw;
        color: black;
    }

    .confirm_patient_name {
        font-weight: 700;
        color: black;
        font-size: 0.8vw;
    }

    .confirm-details-box {
        background-color: #f7f9ff;
        border-radius: 15px;
        padding: 10px 10px 10px 20px;
        color: black;
        font-size: 0.8vw;
        font-weight: 700;
    }
}

@media (max-width: 480px) {
    .appointment-popup-section {
        .MuiTypography-h6 {
            font-size: 25px;
            margin-top: 20px;
        }

        .selection-dropdown-gray{
            label {
                font-size: 13px;
            }

            select {
                font-size: 15px;
                
                option {
                    font-size: 15px;
                }
            }
        }

        .selection-dropdown {
            label {
                font-size: 13px;
            }

            select {
                font-size: 15px;

                option {
                    font-size: 15px;
                }
            }
        }

        .select_date_time {
            h3 {
                font-size: 17px;
                margin: 24px 0 10px 0;
            }

            .selection-dropdown-date-time {
                label, button {
                    font-size: 13px;
                }

                input {
                    font-size: 15px;
                }
            }
        }

        .patients_details, .additional_fields {
            h3 {
                font-size: 17px;
                margin: 24px 0 10px 0;
            }
        }

        ._patient_details_input {
            label {
                font-size: 13px;
            }

            input {
                font-size: 15px;

                &::placeholder {
                    font-size: 15px;
                }
            }
        }

        .additional_fields {
            label, select {
                font-size: 15px;
            }
        }

        .MuiDialogActions-root {
            padding: 0;
        }

        .MuiButton-label {
            font-size: 17px;
        }
    }

    .confirm_appointment_details {
        h3 {
            font-size: 17px;
        }
    
        label {
            font-size: 15px;
        }
    
        .confirm_patient_name {
            font-size: 15px;
        }
    
        .confirm-details-box {
            font-size: 15px;
        }
    }

}
