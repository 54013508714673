.history_kpj_section{
    padding: 50px 0px;
    background-color: rgb(243, 245, 247);

    .container{
        h3{
            color: black;
            margin-bottom: 30px;
        }

        .tabs{
            display: flex;
            flex-direction: row;
            list-style: none;
            padding: 0;

            li{
                cursor: pointer;
                padding: 2px 20px;
                border-radius: 20px;
                margin-right: 10px;

                &:nth-child(1){
                    background-color: #ECF3FF;
                    border: 1px solid #5775D3;
                    color: black;
                }
                &:nth-child(2){
                    background-color: #FBF3DE;
                    border: 1px solid #E3CF9A;
                    color: black;
                }
                &:nth-child(3){
                    background-color: #EDEDED;
                    border:1px solid #CACACA;
                    color: black;
                }

                &.active:nth-child(1){
                    background-color: #ECF3FF;
                    color: black;

                }
                &.active:nth-child(2){
                    background-color: #E3CF9A;
                    color: #fff;

                }
                &.active:nth-child(3){
                    background-color: #CACACA;
                    color: #fff;

                }
            }
        }

        .tab-content {
            margin-top: 30px;
        }
    }
}

.history_card{
    background-color: #fff;
    border-radius: 15px;
    padding: 10px;
    margin: 10px 0px;
    position: relative;

    .container{
        #title_type{
            font-size: 20px;
            color: black;
            font-weight: 700;
            margin-top: 25px;
        }

        .card_content{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 10px;

            .history_doctor_container {
                display: flex;
                align-items: center;
            }

            img{
                border-radius: 50%;
                margin-right: 20px;
                width: 100px;
                height: 100px;
            }

            #docName{
                font-size: 18px;
                font-weight: 700;
                color: gray;
                margin-bottom: 0;
            }

            #docTitle{
                font-weight: 600;
                color: gray;
                margin-bottom: 0;
            }

            .date_box{
                border-radius: 15px;
                background-color: #DEF3F3;
                padding: 10px;
                margin-right: 10px;
                min-width: 130px;

                #date_text{
                    margin-bottom: 0;
                    font-weight: 600;
                    color: black;
                }

                #date_text2{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-weight: 700;
                    color: black;
                }
            }

            .time_box{
                border-radius: 15px;
                background-color: #DEF3F3;
                padding: 10px;
                margin-right: 20px;
                min-width: 130px;

                #time_text{
                    margin-bottom: 0;
                    font-weight: 600;
                    color: black;
                }

                #time_text2{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-weight: 700;
                    color: black;
                }
            }

            .username_box{
                // margin-right: 300px;

                #username_text{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-weight: 700;
                    color: gray;
                }

                #username_text2{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-weight: 700;
                    color: gray;
                }
            }

            .completed_box{
                // position: absolute;
                // right: 50px;
                .complete_text{
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 700;
                    color: #AFD746;
                }
            }

            .cancel_box{
                // position: absolute;
                // right: 90px;
                .cancel_text{
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 700;
                    color: #E98076;
                }
            }

            .pending_box{
                // position: absolute;
                // right: 90px;
                .pending_text{
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 700;
                    color: gray;
                }
            }

        }
    }
}
.history_mob_view .card_content {
    .pending_text{
        font-size: inherit !important;
    }
    .cancel_text{
        font-size: inherit !important;
    }
}

@media (min-width: 481px) {
    .history_mob_view {
        display: none !important;
    }
    
}

@media (max-width: 480px) {
    .history_web_view {
        display: none !important;
    }

    .history_card {
        .container {
            .card_content {
                display: block;

                img {
                    width: 40px;
                    height: 40px;
                }

                #docName {
                    font-size: 15px;
                    font-weight: 700;
                    color: #606060;
                    margin-bottom: 0;
                    line-height: 18.31px;
                }

                .date_box, .time_box{
                    width: 130px;
                }
            }

            #title_type {
               font-size: 17px;
               margin-top: 0;
            }
    
        }

    }
    
}