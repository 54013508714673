.about_kpj_hero_section{
    background: url("./../img/slider/slider-bg-1.jpg");
    background-repeat: no-repeat;
    min-height: 50vh;
    background-position: center top;

    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: inherit;

        h1{
            color: black;
        }
    }
}

.about_kpj_image_gallery{
    background-color: rgb(243, 245, 247);
    padding: 50px 0px;

    .container{

        

        .first_gallery{
            display: grid;
            grid-template-areas:
                "1 2 3 4"
                "myArea myArea 5 6"
                "7 8 9 10";
            gap: 25px;

            .blue_hover{
                background-image: linear-gradient(to top, #967F65, transparent);
                position: absolute;
                height: 50%;
                width: 100%;
                bottom: 0;
    
                #director_name{
                    height: 100%;
                    display: flex;
                    align-items: end;
    
                    font-size: 20px;
                    color: white;
                    font-weight: 700;
                    padding: 20px;
                }
            }

            .item1{
                grid-area: myArea;
                padding-left: 30px;

                h2{
                    color:black
                }

                p{
                    color: gray;
                }
            }
        }

    }

}