.headermenu-popup-secion {
    .first-coloumn-sub {
        &:hover {
            color: var(--primary-color);
        }
    }

    .MuiDialogTitle-root {
        flex: 0 0 auto;
        margin: 0;
        padding: 16px 24px 30px 24px;
    }

    .MuiDialogContent-root {
        flex: 1 1 auto;
        padding: 8px 0 8px 24px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    
    .MuiDialogContent-root {
        a:focus,
        a:hover {
            color: var(--primary-color);
            padding-left: 30px;
        }
    }
    .MuiDialog-paperWidthSm {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 20px 0 20px 20px;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;

        .two-columns-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            font-size: 35px;
        }
        /* columns */
        .two-columns-grid>* {
            // padding: 1rem;
        }
    }
    .MuiDialogActions-root.MuiDialogActions-spacing {
        .row {
            width: 100%;
            ul {
                display: flex;
                li {
                    margin-right: 20px;
                }
            }
        }
        .md-form {
            width: 300px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            border-bottom: 1px solid #ced4da;
            .form-control {
                border: none;
            }
        }
    }
}