.individual-health-package-hero-section{
    background-size: cover;
    background-repeat: no-repeat;
    height: 390px;

    .container{
        height: inherit;
        display: flex;
        align-items: center;

        .individual-health-package-title{
            h1 {
                color: black;
              }
        
            p {
            font-size: 18px;
            font-weight: 600;
            color: #39B5B2;
            }
        }


    }
}

.individual-health-package-information-section{
    background-color: rgb(243, 245, 247);
    padding: 80px;

    ul{
        margin: auto;
        padding-left: 2rem;
    }
    li{
        list-style: inherit;
    }

    .container{

        .health-package-image{
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 300px;
                border-radius: 20px;
            }
            #book-package{
                border: 1px solid #967F65;
                background-color: #967F65;
                width: 270px;
                border-radius: 30px;
                text-align: center;
                margin-top: 12px;
                cursor: pointer;

                p{
                    font-size: 18px;
                    font-weight: 500;
                    margin: 10px;
                    color: white;
                }
            }
            #add-to-cart{
                border: 1px solid #967F65;
                width: 270px;
                border-radius: 30px;
                text-align: center;
                margin-top: 12px;
                cursor: pointer;

                p{
                    font-size: 18px;
                    font-weight: 500;
                    margin: 10px;
                    color: #967F65;
                }
            }
            #download-full-broc{
                margin-top: 12px;
                cursor: pointer;

                p{
                    color: black;
                }
            }
        }
        .health-package-content-1{
            h2{
                padding-top: 5px;
                max-width: 500px;
            }
        }
        .health-package-content-2{
           h3{
            color: black;
            margin-bottom: 10px;
           }
           
           p{
            font-size: 18px;
            line-height: 1.1;
            margin-bottom: 0;
           }

           ul {
            margin-bottom: 20px;
           }

        }
    }
}

.individual-health-package-our-doctors-section {
    background-color: rgb(243, 245, 247);
    padding-bottom: 90px;

    .container {

        a{
            color: #967F65;
            font-weight: 700;
        }
    }
}

.individual-health-package-other-section {
    background-color: #EBEDEF;
    padding: 50px 0px;

    .container {
        h4{
            // margin-bottom: 20px;
        }
    }
}

.individual-health-package-other-section-hp {
    background-color: #EBEDEF;
    padding: 90px 0px;

    .container {
        h4{
            // margin-bottom: 20px;
        }
    }
}

.individual_health_package_card{
    background-color: white;
    border-radius: 20px;
    max-width: 416px;
    height: 160px;

    .container {
      display: flex;
      padding: 10px;
      height: 160px;
      overflow: hidden;

      .health-package-image {
        img{
          border-radius: 10px;
          max-width: 140px;
          height: auto;
        }
      }

      .health-package-content {
            padding: 10px;

            h4{
                margin-bottom: 1px;
                font-weight: 600;
            }
            
            h3{
                color: #F06661;
                margin-bottom: 0;
            }

            p{
                font-size: 12px;
                margin-bottom: 10px;
            }

            .shorten-desc{
                p{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 200px;
                }
            }
        }   
    }
}

.book-package-modal {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .container {
        position: relative;
        padding: 2%;
        background-color: white;
        border-radius: 15px;
        width: 50%;
        height: fit-content;
        max-height: 100%;

        .web_view {
            position: absolute;
            // text-align: right;
            // margin-top: 10px;
            top: 10px;
            right: 10px;
            background-color: white;
        }

        .mob_view {
            border: none;
            background-color: transparent;
            margin-bottom: 25px;
        }

        .image-name-title {
            display: flex;
            flex-direction: row;
            margin-bottom: 3%;
      
            img {
                max-height: 120px;
                max-width: 120px;
                border-radius: 15px;
                margin-right: 20px;
            }
      
            #name-title {    
                display: flex;
                flex-direction: column;
                justify-content: center;

              #tag1 {
                padding-top: 10px;
                color: black;
                font-size: 1.5vw;
                font-weight: 700;
              }
      
              #tag2 {
                font-size: 1vw;
                color: black;
                line-height: 0;
                margin-bottom: 2%;
              }
      
              #tag3 {
                color: #F06661;
                font-size: 1.5vw;
                font-weight: 700;
                }
            }
        }

        #form_select_member{
            background-color: #EAEDF8;
            border-radius: 10px;
            margin-bottom: 2%;

            select#member {
                border: 0px;
                background-color: transparent;
                font-size: 0.8vw;
                width: 100%;
                padding: 2%;
                border-radius: 10px;
                cursor: pointer;

                option {
                    font-size: 1vw;
                }
            }
        }

        .or-word-style {
            color: black;
            text-align: center;
            margin-bottom: 2%;
            font-size: 0.8vw;
            line-height: 2;
        }

        .form_field_container {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            row-gap: 10px;

            input, select {
                border: none;
                background-color: transparent;
                font-size: 0.8vw;

                &::placeholder {
                    font-size: 0.8vw;
                }
            }

            select {
                cursor: pointer;
            }
        }

        #form_name, 
        #form_relationship, 
        #form_dateOfBirth, 
        #form_phoneNumber,
        #form_email {
            display: flex;
            flex-direction: column;
            width: 48%;
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 0.6vw;
            position: relative;

            label {
                font-size: 0.8vw;
                color: black;
            }
        }

        #form_gender, 
        #form_countryOfResident, 
        #form_nric {
            display: flex;
            flex-direction: column;
            width: 48%;
            background-color: #EAEDF8;
            border-radius: 5px;
            padding: 0.6vw;
            position: relative;

            label {
                font-size: 0.8vw;
                color: black;
            }
        }

        .book-package-button-hp {
            border: 0px;
            background-color: #967F65;
            padding: 1%;
            color: white;
            font-weight: 600;
            width: 40%;
            border-radius: 30px;
            font-size: 1vw;
        }
    }
}

.book-confirmed-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .container{
        position: fixed;
        padding: 50px;
        background-color: white;
        border-radius: 20px;
        width: 350px;
        text-align: center;

    }
}

.share-this-package-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    padding: 10px;
   
    .container {
        position: relative;
        // top: 30%;
        // left: 35%;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        width: 450px;

        .image-content {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            img {
                width: 120px;
                border-radius: 15px;
                margin-right: 20px;
            }

            #package-name {
                font-size: 20px;
                line-height: 1;
                font-weight: 700;
                color: black;
                margin-bottom: 3px;
            }

            #hospital-name{
                font-size: 12px;
                margin-bottom: 3px;
            }

            #package-price {
                font-size: 20px;
                line-height: 1;
                font-weight: 700;
                color: #967F65;
                margin-bottom: 0px;
            }
        }
        .social-media-profile {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
      
            p {
              color: black;
              margin: 0;
              font-size: unset;
            }
      
            #social-media-container {
              width: 120px;
              display: flex;
              flex-direction: row;
              align-items: center;
              border: 1px solid gray;
              border-radius: 8px;
              padding: 2px 10px;
              margin-right: 10px;
              margin-top: 10px;
            }
          }
       
    }
}
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the alpha value for desired opacity */
    z-index: 999; /* Ensure the modal background appears above other content */
  }

.hospital-label{
    background-color: #967F65;
    color: #fff;
    padding: 0.3em 0.8em;
    border-radius: 25px;
    font-size: 1vw;
}

@media (max-width:1024px) {
    .individual-health-package-hero-section {
        height: 290px;
    }

    .individual-health-package-information-section {
        padding: 50px;
    }
}

@media (max-width:768px) {
    .individual-health-package-hero-section {
        height: 220px;
    }
}

@media (min-width: 481px) {
    .individual-health-package-hero-section .back_to_mob {
        display: none !important;
    }

    .individual-health-package-information-section .individual_hp_mob_view {
        display: none !important;
    }

    .individual-health-package-our-doctors-section .individual_hp_doctor_mob_view {
        display: none !important;
    }

    .individual-health-package-other-section-hp .individual_hp_our_mob_view {
        display: none !important;
    }

    .book-package-modal .mob_view {
        display: none !important;
    }

    .share-this-package-modal .package_share_mob_view {
        display: none !important;
    }
}


@media (max-width: 480px) {
    .individual-health-package-hero-section .back_to_web {
        display: none !important;
    }

    .individual-health-package-information-section .individual_hp_web_view {
        display: none !important;
    }

    .individual-health-package-our-doctors-section .individual_hp_doctor_web_view {
        display: none !important;
    }

    .individual-health-package-other-section-hp .individual_hp_our_web_view {
        display: none !important;
    }

    .share-this-package-modal .package_share_web_view {
        display: none !important;
    }

    .individual-health-package-hero-section {
        background-size: cover;
        background-repeat: no-repeat;
        height: 200px;
        background-position: center;

        .container .individual-health-package-title h1 {
            color: black;
            font-size: 25px !important;
            width: 116px;
        }
    }

    .individual-health-package-information-section {
        background-color: #f3f5f7;
        padding: 10px;

        .container {
            height: 100%;
            width: 100%;
            padding: 24px;
            border-radius: 0;  
            overflow-y: scroll;
            max-height: none;
            
            .health-package-content-2 p {
                font-size: 13px;
                margin-bottom: 40px;
                line-height: 15.51px;
                color: #606060;
            }
        }
    }

    .individual-health-package-information-section .container .health-package-image img {
        width: 100%;
        border-radius: 20px;
    }

    .book-package-modal {
        padding: 0;

        .web_view {
            display: none !important;
        }

        .container .image-name-title img {
            max-width: 104px;
            max-height: 104px;
            border-radius: 15px;
            margin-right: 20px;
        }
    }

    .share-this-package-modal {
        padding: 0;

        .container .social-media-profile {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .book-package-modal .container {

        height: 100%;
        width: 100%;
        padding: 24px;
        border-radius: 0;  
        overflow-y: scroll;
        max-height: none;
        

        #form_name, 
        #form_relationship, 
        #form_dateOfBirth, 
        #form_phoneNumber,
        #form_email {
            padding: 10px;
            width: 100%;

            label {
                font-size: 13px;
            }
        }
    
        #form_gender, 
        #form_countryOfResident, 
        #form_nric {
            width: 100%;
            padding: 10px;

            label {
                font-size: 13px;
            }
        }

        .image-name-title #name-title {
            
            #tag1 {
                font-size: 17px;
            }
    
            #tag3 {
                font-size: 17px;
            }
        }

        #form_select_member select#member {
            font-size: 13px;
            border-radius: 6.33px;

            option {
                font-size: 13px;
            }
        }

        .or-word-style {
            font-size: 13px;
        }

        .form_field_container {
            input, select {
                border: none;
                background-color: transparent;
                font-size: 15px;

                &::placeholder {
                    font-size: 15px;
                }
            }
        }

        .book-package-button-hp {
            font-size: 17px;
        }

    }
    

    .hospital-label {
        font-size: 10px;
    }

}

