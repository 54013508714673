/* 1. Theme default css */
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
  
body {
    font-family: 'Flexo';
    font-weight: normal;
    font-style: normal;
    color: #647589;
    font-size: 14px;
}
.img,
img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}   
.f-left {
    float: left;
}
.f-right {
    float: right;
}
.fix {
    overflow: hidden;
}
.kpj-footer a:focus,
.kpj-footer a:hover {
    color: #fff !important;
    text-decoration: underline !important;
}
a,
.button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
a:focus,
.button:focus {
    text-decoration: none;
    outline: none;
}
a:focus,
a:hover {
    color: #8fb569;
    text-decoration: none;
}
.nav-link:focus,
.nav-link:hover {
    color: white;
}
a,
button {
    color: #10111e;
    outline: medium none;
    text-decoration: none;
    cursor: pointer;
}
button {
    cursor: pointer;
    transition: 0.3s;
}
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
    outline: 0;
}
.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Flexo';
    color: #000000;
    margin-top: 0;
    //  font-weight: 600;
    text-transform: normal;
    line-height: 1.1;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}

h1 {
    font-size: 60px;
    font-weight: 700;
    // line-height: 88px;
    letter-spacing: 0em;
}

h2 {
    font-size: 40px;
    font-weight: 700;
    // line-height: 61px;
    letter-spacing: 0em;
}

h3 {
    font-size: 20px;
    font-weight: 700;
    // line-height: 32px;
    letter-spacing: 0em;
}

h4 {
    font-size: 20px;
}
h5 {
    font-size: 18px;
    //  font-family: 'Rubik', sans-serif;
    font-weight: 500;
    color: #647589;
}
h6 {
    font-size: 14px;
}
ul {
    margin: 0px;
    padding: 0px;
}
li {
    list-style: none;
}

p {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #606060;
    margin-bottom: 15px;
    letter-spacing: 0em;
}

.learn-more-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    color: #967F65;
}

.learn-more-text-mobile-1 {
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    color: #967F65;
}

hr {
    border-bottom: 1px solid #eceff8;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}
label {
    color: #606060;
    // cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}
*::-moz-selection {
    background: #d6b161;
    color: #fff;
    text-shadow: none;
}
::-moz-selection {
    background: #444;
    color: #fff;
    text-shadow: none;
}
::selection {
    background: #444;
    color: #fff;
    text-shadow: none;
}
*::-moz-placeholder {
    color: #555555;
    font-size: 14px;
    opacity: 1;
}
*::placeholder {
    color: rgb(167, 167, 167);
    font-size: 12px;
    opacity: 1;
    // padding-inline-start: 0.5rem;
}
.theme-overlay {
    position: relative;
}
.theme-overlay::before {
    background: #1696e7 none repeat scroll 0 0;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;
}
.separator {
    border-bottom: 2px solid #eae7ff;
}
/* button style */
.primary_btn {
    -moz-user-select: none;
    background: #e12454;
    border: medium none;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 0;
    padding: 24px 40px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
}
.primary_btn:hover {
    background: #8fb569;
    color: #fff;
}
.btn-icon {
    border-radius: 30px;
    padding-left: 84px;
    padding-right: 50px;
    padding-top: 24px;
    padding-bottom: 22px;
    background-color: rgb(225, 36, 84);
    box-shadow: 0px 8px 16px 0px rgba(225, 36, 84, 0.2);
}
.btn-icon-green {
    background-color: rgb(143, 181, 105);
    box-shadow: 0px 8px 16px 0px rgba(143, 181, 105, 0.3);
}
.btn-icon:hover {
    background-color: rgb(143, 181, 105);
    box-shadow: 0px 8px 16px 0px rgba(125, 178, 73, 0.2);
    color: #fff;
}
.btn-icon-green:hover {
    background-color: rgb(225, 36, 84);
    box-shadow: 0px 8px 16px 0px rgba(225, 36, 84, 0.2);
}
.btn-icon span {
    position: absolute;
    left: 5px;
    text-align: center;
    width: 50px;
    height: 50px;
    top: 5px;
    line-height: 50px;
    background: #fff;
    transition: 0.3s;
    font-size: 20px;
    font-weight: 600;
    color: #223645;
    border-radius: 100%;
}
.btn-icon-white {
    background: #fff;
    color: #647589;
    padding-right: 35px;
    padding-left: 35px;
    border: 2px solid #eae7ff;
    margin-left: 29px;
    box-shadow: none;
    border-radius: 40px;
}
.btn-icon-white i {
    margin-right: 7px;
}
.btn.btn-icon.btn-icon-white:hover {
    border: 2px solid transparent;
}
.btn-icon-blue {
    background-color: rgb(34, 54, 69);
    box-shadow: 0px 8px 16px 0px rgba(34, 54, 69, 0.2);
    padding-right: 60px;
    padding-left: 94px;
}
.btn-icon-blue:hover {
    background-color: rgb(225, 36, 84);
    box-shadow: 0px 8px 16px 0px rgba(225, 36, 84, 0.2);
}
.btn-icon-dark:hover {
    background: #223645;
    box-shadow: 0px 8px 16px 0px rgba(34, 54, 69, 0.2);
}
.primary_btn.gray-btn-border {
    font-size: 14px;
    color: #647589;
    background: 0;
    border: 2px solid #eae7ff;
    border-radius: 40px;
    padding: 23px 60px;
    line-height: 1;
    text-transform: capitalize;
}
.primary_btn.gray-btn-border:hover {
    background: #e12454;
    color: #fff;
    border: 2px solid transparent;
}
.primary_btn.green-bg-btn {
    padding: 23px 60px;
    border-radius: 30px;
    background: #8fb569;
    color: #fff;
}
.btn.green-bg-btn:hover {
    background: #e12454;
}
.play-btn {
    height: 60px;
    width: 60px;
    line-height: 60px;
    display: inline-block;
    border-radius: 50%;
    background-color: rgb(143, 181, 105);
    box-shadow: 0px 8px 16px 0px rgba(125, 178, 73, 0.2);
    text-align: center;
    color: #fff;
    padding-left: 3px;
    margin-left: 27px;
    border: none;
}
.play-btn:hover {
    background-color: rgb(225, 36, 84);
    box-shadow: 0px 8px 16px 0px rgba(225, 36, 84, 0.2);
    color: #fff;
}
.about-video-btn {
    font-size: 14px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border: 2px solid #fff;
    border-radius: 50%;
    display: block;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    padding-left: 3px;
}
.about-video-btn.white-video-btn {
    width: 120px;
    height: 120px;
    line-height: 119px;
    background: #fff;
    color: #223645;
}
.about-video-btn.white-video-btn:hover {
    background: #e12454;
    border: 2px solid #e12454;
    color: #fff;
}
.breadcrumb > .active {
    color: #888;
}
/* scrollUp */
#scrollUp {
    background: #e12454;
    height: 50px;
    width: 50px;
    right: 50px;
    bottom: 77px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
    line-height: 48px;
}
#scrollUp:hover {
    background: #8fb569;
}

/* Style to hide number input arrows */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

@media (max-width: 480px) {
    p {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #606060;
    }

    h1 {
        line-height: 1.1;
    }

    h3 {
        font-size: 12px;
    }
}

form {
    .form-section {
        color: #555;
        font-size: 14px;
        font-weight: 700;
        margin-top: 20px;
    }

    .form-divider {
        border-bottom: 1px solid #555;
        margin: 8px calc(var(--bs-gutter-x) * 0.5);
    }

    .form-desc {
        color: #555;
        font-size: 12px;
        font-weight: 700;
        font-style: italic;
    }

    #form_kpjHospital {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #eaedf8;
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;

        // select {
        //     -webkit-appearance: none;
        //     -moz-appearance: none;
        // }
    }
}

.base-card {
    border-radius: 20px;
    padding: 32px;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3;

    &:hover {
        border: solid 1px #967F65;
        transform: scale(1.04);
        transition: all 0.3;
    }

    &__title {
        font-size: 28px;
        position: relative;
    }

    &__actions {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 2px #967F65;
        border-radius: 50%;
        cursor: pointer;
        margin-top: auto;
        position: relative;
    }

    .image-container {
        height: 100%; /* Or any other size */
        width: 60%;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    min-height: 300px;
}

.card-container2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    min-height: 300px;
}

@media (max-width: 768px) {
    .card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .card-container2 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

table tbody tr:nth-child(odd) {
    background-color: #fff;
}

.klinik-container{
    table{
        tbody tr:nth-child(odd) {
            background-color: initial;
        }
    }
}

@media (min-width: 481px) {
    .mobile-view {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .web-view {
        display: none !important;
    }
}