.individual-doctor-hero-area {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 390px ;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: inherit;
    flex-wrap: wrap;
    
    .individual-doctor-hero-title {

      h1 {
        color: black;
      }

      button {
        font-size: 18px;
        font-weight: 600;
        color: #39B5B2;
        border: 0;
        background-color: transparent;
      }
    }
  }
}

.individual-doctor-profile-area {
  background-color: rgb(243, 245, 247);
  padding: 50px;

  .container {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 30px;
    padding: 60px;
    column-gap: 45px;
    justify-content: space-between;
    max-width: 90%;

    .doctor-image {
      img {
        width: 230px;
        border-radius: 30px;
        margin-bottom: 10px;
      }

      p {
        font-size: 20px;
        color: #606060;
        line-height: 1.2;
      }
    }

    .doctor-details {
      position: relative;
      width: 55%;

      #doctor-name {
        margin-bottom: 16px;

        h2 {
          color: #967F65;
          max-width: 615px;
          text-transform: uppercase;
        }
      }

      #doctor-title {
        margin-bottom: 25px;

        h3 {
          margin: 0;
        }

        p {
          font-size: 20px;
          margin: 0;
          text-transform: capitalize;
          color: #606060;
        }
      }

      .doctor-location {
        background-color: rgb(243, 245, 247);
        border-radius: 20px;
        padding: 30px;
        width: 100%;

        h3 {
          margin: 0;
          font-size: 24px;
        }

        p {
          color: #606060;
        }

        #doctor-contacts {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 30px;

          p {
            color: white;
            padding: 0;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
          }

          #contact, #make-appointment, #teleconsult  {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 15px;
            cursor: pointer;
            height: 40px;
          }

          #contact {
            background-color: #967F65;
            margin-right: 10px;
          }

          #make-appointment {
            background-color: #e78076;
            margin-right: 10px;
          }

          #teleconsult {
            background-color: #39B5B2;
            margin-right: 10px;
          }
        }
      }
    }

    .bookmark-share-icons {
      display: flex;
      flex-direction: row;
      // margin-left: 30px;
    }
  }
}

.share-doctor-profile-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .container {
    padding: 30px;
    background-color: white;
    border-radius: 20px;
    max-width: 450px;
    margin: 10px;
    display: flex;
    flex-direction: column;

    .share-doctor-profile-modal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
      margin-bottom: 30px;
      // width: inherit;
    }

    .image-name-title {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;

      img {
        width: 100px;
        border-radius: 15px;
        margin-right: 20px;
      }

      #name-title {
        p {
          margin: 0;
        }

        #tag1 {
          color: #967F65;
          font-size: 16px;
          font-weight: bold;
        }

        #tag2 {
          font-size: 16px;
          font-weight: bold;
          color: black;
        }

        #tag3 {
          font-size: 14px;
        }
      }
    }

    .social-media-profile {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      p {
        color: black;
        margin: 0;
        font-size: unset;
      }

      #social-media-container {
        width: 120px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid gray;
        border-radius: 8px;
        padding: 2px 10px;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }
}

.individual-doctor-information-area {
  background-color: rgb(243, 245, 247);
  padding: 50px 80px;

  .container {

    .rdp {
      --rdp-cell-size: 50px;
      --rdp-caption-font-size: 18px;
      --rdp-accent-color: #0000ff;
      --rdp-background-color: #e7edff;
      --rdp-accent-color-dark: #3003e1;
      --rdp-background-color-dark: #180270;
      --rdp-outline: 2px solid var(--rdp-accent-color);
      --rdp-outline-selected: 3px solid var(--rdp-accent-color);
      margin: 1em;
    }

    .rdp-head_cell {
      vertical-align: middle;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      height: 100%;
      height: var(--rdp-cell-size);
      padding: 0;
      text-transform: uppercase;
    }

    .rdp-day{
      font-weight: 400 !important;
    }
    .rdp-day.rdp-day_selected{
      color: #fff !important;
    }

    .information-container,
    .education-container,
    .language-container {
      margin-bottom: 50px;
      h4 {
        &::after {
          content: "";
          display: block;
          width: 65px;
          padding-top: 8px;
          border-bottom: 4px solid #967F65;
        }
      }
    }

    .education-container ul {
      padding-left: 17px;

      li {
        list-style-type: disc;
        font-size: 18px;
        color: #606060;
      }
    }
  }
}

.individual-doctor-articles-podcast-section {
  background-color: rgb(236, 238, 240);
  padding: 50px 120px;

  .container {
    .articles_section {
      padding: 10px 50px;
      border-right: 1px solid gray;

      .articles-section-header {
        margin-bottom: 30px;

        a {
          display: flex;
          flex-direction: row;
        }
      }

      .articles-section-content {
        display: flex;
        flex-direction: column;
        padding: 0px;

        &:last-child {
          #articles-title {
            border-bottom: 0px !important;
          }
        }

        .articles-date-title {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

          #articles-date {
            background-color: white;
            padding: 10px 5px;
            margin-right: 10px;
            width: 50px;
            height: 50px;

            p {
              font-size: 10px;
              color: #967F65;
              margin-bottom: 0px;
              line-height: 0;
            }

            h3 {
              color: #967F65;
              line-height: 1.3;
              font-size: 32px;
            }
          }

          #articles-title {
            border-bottom: 1px solid gray;

            p {
              color: black;
            }
          }
        }
      }
    }

    .podcast_section {
      padding: 10px 50px;
      .podcast-section-header {
        margin-bottom: 30px;

        a {
          display: flex;
          flex-direction: row;
        }
      }

      .podcast-section-content {
        display: flex;
        flex-direction: column;
        padding: 0px;

        &:last-child {
          #podcast-title {
            border-bottom: 0px !important;
          }
        }

        .podcast-date-title {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;

         

          #podcast-date {
            background-color: white;
            padding: 10px 5px;
            margin-right: 10px;
            width: 50px;
            height: 50px;

            p {
              font-size: 10px;
              color: #967F65;
              margin-bottom: 0px;
              line-height: 0;
            }

            h3 {
              color: #967F65;
              line-height: 1.3;
              font-size: 32px;
            }
          }

          #podcast-title {
            border-bottom: 1px solid gray;

            p {
              color: black;
            }
          }
        }
      }
    }
  }
}

.individual-doctor-profile-area-mv{
  background-color: #f3f5f7;
  padding-top: 20px;

  .bookmark-share-icons{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    margin-bottom: 16px;
  }

  .container {
    .doctor-image{
      margin: 10px;
      display: flex;
      justify-content: center;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 30px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .individual-doctor-hero-area {
    height: 385px;
  }

  .individual-doctor-profile-area .container {
    padding: 30px;
    column-gap: 27px;
  }

  .share-doctor-profile-modal .container {
    max-width: 490px;
  }

  .individual-doctor-articles-podcast-section {
    padding: 50px;
  }
}

@media (max-width: 1024px) {
  .individual-doctor-hero-area {
    height: 308px;
  }

  .individual-doctor-profile-area .container {
    flex-wrap: wrap;
  }

  // .individual-doctor-profile-area .container .doctor-details .doctor-location {
  //   position: relative;
  // }
}

@media (max-width: "991px") {
  .articles-section {
    border-right: 0px !important;
    margin-bottom: 20px !important;
  }
}

.individual-doctor-health-packages-section, .individual-doctor-health-packages-section-mobile-view {
    background-color: rgb(243, 245, 247);
    padding-top: 100px;
    padding-bottom: 80px;

    .container {
      .health-packages-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;

        p{
            font-size: 17px;
            font-weight: 700;
            color: #967F65;
        }
      }
    }

    .individual_doc_hp_container{
      display: flex;
      column-gap: 20px;

      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none; 

      &::-webkit-scrollbar {
          display: none;
      }
    }
}

.health_package_card {
    background-color: white;
    padding: 5px;
    border-radius: 20px;
    // width: 400px;
    display: flex;
    align-items: center;
    height: 170px;

    .container {
      display: flex;
      padding: 5px;
      user-select: none;

      .health-package-image {
        img{
          border-radius: 10px;
          width: 150px;
          height: 150px;
        }
      }

      img{
        border-radius: 10px;
        max-width: 150px;
        height: 150px;
      }

      .health-package-content {
        padding: 10px 10px 10px 20px;
          h3{
            color: #F06661;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: 1.2em;
            overflow: hidden;
            // height: 2.4em;
          }

          p{
            font-size: 12px;
            margin-bottom: 15px;
            line-height: 1.1;
          }
      }
    }
}

@media (max-width:'768px') {
  .individual-doctor-hero-area {
    height: 230px;
  }
   
  .individual-doctor-profile-area{
    padding: 50px 20px;

    .container{
      padding: 50px;

      .doctor-details{
        .doctor-location{
          padding: 30px;
          display: none; 
        &.active {
          display: block;
        }
        }
      }
    }
  }

  .individual-doctor-information-area{
    padding: 50px 20px;
  }

  .individual-doctor-articles-podcast-section{
    padding: 50px 20px;

    .container{
      .articles_section{
        border-right: 0;
      }
    }
  }
}

@media (min-width: 481px) {
  .individual-doctor-profile-area-mv{
   display: none;
  }

  .individual-doctor-health-packages-section-mobile-view{
    display: none;
  }

  .individual-doctor-hero-area .back_to_mob {
    display: none !important;
  }

  .share-doctor-profile-modal .doctor_share_mob_view {
    display: none !important;
  }

 }

@media (max-width:'480px') {

  .individual-doctor-health-packages-section {
    display: none;
  }

  .individual-doctor-hero-area .back_to_web {
    display: none !important;
  }

  .share-doctor-profile-modal .doctor_share_web_view {
    display: none !important;
  }


.individual-doctor-information-area .container .rdp {
  --rdp-cell-size: 35px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  margin: 1em;
}

 .individual-doctor-profile-area{
  display: none;
 }

 .individual-doctor-information-area .container {
   #information-mv  {
      flex-direction: column-reverse !important;
      row-gap: 50px;
  
      .idia_mobile{
        width: -webkit-fill-available !important;
      }
  
      .information-container{
        // width: 326px;
        p {
          font-size: 13px !important;
          font-weight: 400;
          line-height: 15.51px;
          color: #606060;
          margin-bottom: 15px;
          // width: 326px;
        }
      }
    }

  .education-container ul li {
    font-size: 13px;
  }
}
 

  .individual-doctor-hero-area {
    height: 200px !important;
    background-size: cover !important;
    background-position: center !important;

    .container {
      .individual-doctor-hero-title h1 {
        color: black;
        font-size: 25px !important;
      }

      .individual-doctor-hero-title button {
        font-size: 10px;
        font-weight: 700;
        color: #39B5B2;
        border: 0;
        background-color: transparent;
    }
    } 
  }

  .individual-doctor-profile-area{
    padding: 50px 20px;

    .container{
      padding: 30px;

      .doctor-details{
        #doctor-name{
          h1{
            font-size: 30px !important;
          }
        }

        .doctor-location{
          padding: 30px;
          display: none; 
          &.active {
            display: block;
          }
        }
      }
    }
  }

  .individual-doctor-articles-podcast-section .container {
    .articles_section {
      padding: 0 0 30px 0;
      border-bottom: 1px solid black;

      .articles-section-header a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .articles-section-content .articles-date-title #articles-date h3 {
        color: #967F65;
        line-height: 1.3;
        font-size: 32px !important;
      }
    }
    

    .podcast_section {
      padding: 30px 0 0 0;

      .podcast-section-header a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .podcast-section-content .podcast-date-title #podcast-date h3 {
        color: #967F65;
        line-height: 1.3;
        font-size: 32px !important;
      }
    }   
  }

  .individual-doctor-health-packages-section .container .health-packages-header p {
    font-size: 12px;
    font-weight: 700;
    color: #967F65;
  }

  .individual-doctor-health-packages-section-mobile-view {
    background-color: #f3f5f7;
    padding: 40px 0;

    .container {
      .health-packages-header {
        align-items: center;
        margin-bottom: 38px;

        h3 {
          font-size: 25px !important;
          font-weight: 700;
          line-height: 29px;
          color: black;
          margin-bottom: 0;
        }

        p {
          font-size: 12px;
          font-weight: 700;
          line-height: 13.8px;
          color: #967F65;
          margin-bottom: 0;
        }
      }
    }
  }
  
  .individual-doctor-health-packages-section-mobile-view-slider {
    .slick-dots {
      position: absolute;
      bottom: -26px;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }
  }

  .idhp-mobile-view {
    .health_packages_card {
      background-color: #fff;
      padding: 10px;
      border-radius: 20px;
      height: 477px;
      width: 306px;
      margin: auto;

      #package_name {
        font-size: 21px;
        font-weight: 700;
        line-height: 24px;
        color: black;
        margin-bottom: 0px;
        padding: 5px 15px;
      }

      #hosp_name {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
      }

      .package_price {
        font-size: 21px;
        font-weight: 700;
        line-height: 24px;
        color: #5775D3;
      }
    }

    .health_packages_card:hover {
      transform: unset;
      box-shadow: 0px 4.144577503204346px 10.371703147888184px 0px #B7BBCA80;
      border: 1.01px solid #967F65
    }
  }


  //shre modal
  .share-doctor-profile-modal {
    .container {
      padding: 30px;
      background-color: white;
      border-radius: 0;
      max-width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
  
      .image-name-title {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 30px;

        #name-title {
          #tag1 {
            color: #967F65;
            font-size: 21px;
            font-weight: 700;
            line-height: 24.15px;
            margin-bottom: 10px;
          }

          #tag2 {
            font-size: 17px;
            font-weight: 700;
            color: black;
            line-height: 19.55px;
          }

          #tag3 {
            font-size: 15px;
            font-weight: 400;
            line-height: 17.89px;
            color: #606060;
            margin-bottom: 21px;
          }
        } 

        img {
          width: 327px;
          height: 327px;
          border-radius: 30px;
          margin: auto;
        }
      }

      .social-media-profile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;

        #social-media-container {
          width: 100%;
          justify-content: center;
          height: 50px;
        }

        p {
          font-size: 17px;
        }
      }
    } 
  }
}



