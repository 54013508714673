.mayo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
        padding: 20px;
        flex-direction: column;
    }
}

.mayo-content {
    display: flex;
    gap: 20px;
    align-items: stretch;

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.mayo-images {
    display: flex;
    gap: 10px;
}

.mayo-image {
    width: 200px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
        width: calc((100% - 10px) / 2);
    }
}

.mayo-text {
    max-width: 500px;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        text-align: center;
    }
}

h2 {
    font-size: 24px;
    color: #222;
    margin-bottom: 10px;
}

p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
}

strong {
    color: #000;
}

button {
    background-color: #004080;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

button:hover {
    background-color: #002d5a;
}
