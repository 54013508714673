.our-collaboration {
    &__title {
        font-size: 32px;
        font-weight: 700;
        color: var(--primary-color);
        text-align: center;
        margin: 40px 0;

        @media screen and (max-width: 768px) {
            font-size: 24px;
            margin: 20px 0;
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding: 0 120px;

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            padding: 0 0px;
        }
    }

    &__divider {
        margin: 40px 0;
        background: #967f65;
        height: 2px;
        width: 100%;
    }

    &__video {
        display: flex;
        justify-content: center;
        margin-bottom: 100px;
    }
}

.base-our-collaboration-card {
    border-radius: 32px;
    padding: 32px 20px;
}

.base-our-collaboration-card__content {
    display: flex;
    gap: 10px;
    align-items: center;

    h4 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }

    img {
        width: 60px;
        height: 60px;
    }
}

.about-mayo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #013b59;
    position: relative;

    color: #fff;

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
    h2 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #fff;
    }

    p {
        font-size: 16px;
        line-height: 1.6;
        color: #fff;
    }
}

.about-mayo-content {
    display: flex;
    align-items: center;
    gap: 30px;
    color: #fff;
    padding: 40px;
    position: relative;

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        padding: 20px;
    }
}

.about-mayo-text {
    max-width: 500px;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        text-align: center;
    }
}

.learn-more-btn {
    display: inline-block;
    background-color: #a18f74;
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    font-weight: bold;
    margin-top: 15px;

    &:hover {
        color: #fff !important;
    }
}

.about-mayo-image {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    max-width: 45%;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        position: relative;
        height: auto;
    }
}

.about-mayo-image img {
    width: auto;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 768px) {
        min-height: 300px;
    }
}
