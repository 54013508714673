.tender-container{
  background-color: #f3f5f7;
  padding-bottom: 50px;
}
.tender-list{
  .tender-item{
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    border: none;
    margin: 15px 0px;
    .title{
      margin-bottom: 1.8rem;
    }
    p{
      display: inline;
      margin: 0px;
      &.date{
        font-weight: 700;
        color: #967F65;
      }
    }
    img{
      height: auto;
    }

    .show-detail-btn{
      appearance: none;
      outline: none;
      border: none;

      margin-right: 10px;

      background-color: #edf1f7;
      padding: 5px 20px;
      color: #967F65;
      border-radius: 5px;

      font-weight: 700;

      cursor: pointer;
    }

    .apply-btn{
      appearance: none;
      outline: none;
      border: none;

      background-color: #967F65;
      padding: 5px 20px;
      color: #fff !important;
      border-radius: 5px;

      font-weight: 700;

      cursor: pointer;

      &:hover{
        color: white !important;
      }
      
    }
  }
}