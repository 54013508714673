.contact_us_hero_section{
    background-repeat: no-repeat;
    min-height: 400px;
    background-position: center top;
    background-size: cover;

    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: inherit;

        h1{
            color: black;
        }
    }
}

.contact_us_map_section{
    background-color: rgb(243, 245, 247);
    padding: 50px 0px;

    .container{
        background-image: url("./../img/Re0Zys.png");
        background-size: cover; 
        background-position: center; 
        background-repeat: no-repeat; 
        min-height: 70vh;

        .address_box{
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: inherit;
            padding-left: 20px;

            .map-informations {
                background-color: white;
                max-width: 400px;
                max-height: 400px;
                padding: 70px 70px 70px 50px;
                border-radius: 2rem;
                
    
                h4{
                    color: #967F65;
                }
    
                p{
                    color: black;
                    line-height: 1.1;
                    margin-bottom: 0px;
    
                }
    
            }
        }
    }
}

.contact_us_info_section{
    background-color: rgb(243, 245, 247);
    padding: 50px 0px;

    .container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 12px;
        justify-content: space-between;
        align-items: center;
    }
}

.contact_us_inquiry_section{
    background-color: rgb(243, 245, 247);
    padding: 50px 0px;

    .container{
        background-color: #fff;
        width: 80%;
        display: flex;
        padding-right: 0px;

        .inquiry_form{
            width: 80%;
            padding: 50px;

            h5{
                color: black;
                font-weight: 700;
            }

            #form_fullname{
                display: flex;
                flex-direction: column;
                width: 49%;
                background-color: #F5F5F5;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;
            }

            #form_contactNo{
                display: flex;
                flex-direction: column;
                width: 49%;
                background-color: #F5F5F5;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;
            }

            #form_nric{
                display: flex;
                flex-direction: column;
                width: 49%;
                background-color: #F5F5F5;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;

            }

            #form_countryOfResidence{
                display: flex;
                flex-direction: column;
                width: 49%;
                background-color: #dbdde4;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;
            }

            #form_email{
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: #F5F5F5;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;
            }

            #form_kpjHospital{
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: #dbdde4;
                border-radius: 5px;
                padding: 10px;
                margin-top: 10px;

                select{
                    -webkit-appearance: none;
                    -moz-appearance: none
                }
            }

            #form_existingpatient{
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 10px;
            }

            #form_inquiry{
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: transparent;
                border-radius: 5px;
                padding: 10px 10px 10px 0px;;
            }

        }

        .inquiry_picture{
            background-image: url("./../img/Rectangle.png");
            background-size: cover;
            background-position: right;
            min-width: 400px;

        }

    }
}

.contact_us_directory_section{
    background-color: rgb(243, 245, 247);
    padding: 50px 0px;

    .container{
        text-align: center;

        h2{
            color: black;
            margin-bottom: 30px;
        }

        .hospital_lists{
            text-align: left;

            #hospital_tag{
                border-bottom: 1px solid;

                p{
                    font-size: 16px;
                    color: black;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
            }
        }

        .centre_lists{
            text-align: left;
            margin-left: 80px;
            width: 405px;

            #centre_tag{
                border-bottom: 1px solid;

                p{
                    font-size: 16px;
                    color: black;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

.submit_inquiry_modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .container{
        padding: 30px 50px;
        background-color: white;
        border-radius: 20px;
        width: 350px;
        text-align: center;
        display: flex;
        flex-direction: column;

    }
}

@media (max-width: 768px) {
    .contact_us_inquiry_section .container .inquiry_picture {
        display: none;
    }

    .contact_us_inquiry_section .container .inquiry_form {
        width: 100%;
        padding: 20px;
    }

    .contact_us_info_section .container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: normal;
        column-gap: 20px;
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        position: relative;
    }

    .contact_us_info_section .container #p_general_line_mobile {
        width: 140px;
    }
}

@media (min-width:481px) {
    .contact_us_directory_section .cud_mobile {
        display: none;
    }
}

@media (max-width: 480px) {
    .contact_us_directory_section .cud_normal {
        display: none !important;
    }

    .contact_us_hero_section {
        background-repeat: no-repeat;
        min-height: 200px;
        background-position: center top;
        background-size: cover;
    }

    .contact_us_info_section .container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: normal;
        column-gap: 20px;

        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none; 

        position: relative;
        // padding-left: 5%;

        &::-webkit-scrollbar {
            display: none;
        }

        #h4_general_line_mobile{
            width: 100px;
        }

        #p_general_line_mobile {
            width: 104px;
        }
    }

    .contact_us_inquiry_section {
        padding: 0;

        .container {
            background-color: #fff;
            width: 100%;
            display: flex;
            padding-right: 0px;
    
            .inquiry_form {
                width: 100%;
                padding: 20px;
    
                .contact_us_form_mobile {
                    flex-direction: column !important;
                }
    
                #form_inquiry, #form_nric, #form_countryOfResidence, #form_fullname, #form_contactNo {
                    width: 100%;
                }
            }
    
            .inquiry_picture {
                display: none;
            }
        }
    }
    

    .contact_us_directory_section {
        .container {
            text-align: left;

            .hospital_lists #hospital_tag {
                border-bottom: 1px solid;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
            }
    
            .centre_lists #centre_tag {
                border-bottom: 1px solid;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
            }
        }  
    } 
}