.our-hospital-speciality-centre-hero-area {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    
    .container{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 35vh;

        #our-speciality-centre-title{

            h1{
                width: max-content;
            }
        }
    }
}

.eye-specialist-area .sticky-section {
    position: sticky;
    top: 50px;
    height: fit-content;
}

.eye-specialist-area, .eye-specialist-area-mv{
    padding-top: 70px;
    background-color: #F3F5F7;

    .container{
        display: flex;
        flex-direction: row;

        .eye-specialist-title-subtitle{
            margin-right: 50px;
            
            #eye-specialist-title{
                padding-top: 40px;
                margin-bottom: 40px;

                h2{
                    font-size: 40px;
                    color: black;
                    font-weight: 700;
                }
            }
    
            #eye-specialist-subtitle {
                width: 305px;
                p{
                    line-height: 1.5;
                    text-align: justify;
                }
            }
        }

        .eye-specialist-services{
            padding: 20px;

        }

    }
}

.eye-specialist-service-card{
    margin-bottom: 40px;

    .card-image{
        position: relative;
        margin-bottom: 20px;

        img{
            width: 100%;
            border-radius: 30px;
        }

        .title-hover{
            opacity: 0;
            transition: 0s;
            position: absolute;
            left: 7%;
            top: 6%;


            h2{
                color: white;
                font-weight: 500;
                font-size: 32px;
                line-height: 1.25;
            }
        }

        .icon-hover{
            opacity: 0;
            transition: 0s;
            position: absolute;
            left: 40%;
            top: 40%;
            color: black;
            background-color: white;
            border-radius: 50%;
        }

        &:hover {
            background-color: #003C50;
            border-radius: 30px;
            cursor: pointer;

            .image{
                opacity: 0.3;
                transition: 0s;
                backface-visibility: hidden;
            }

            .title-hover{
                opacity: 1;
            }

            .icon-hover{
                opacity: 1;
            }

        }


    }

    .card-title, .card-title-mv{

        p{
            font-size: 20px;
            font-weight: 500;
            color: #003c50;
        }

    }
}

@media (max-width: 1280px) {
    // .our-hospital-speciality-centre-hero-area .container {
    //     height: 355px;
    // }    
}

@media (min-width: 481px) {
    .eye-specialist-area-mv{
        display: none;
    }

    .eye-specialist-service-card .show-more-mv{
        display: none;
    }
}

@media (max-width: 480px) {

    .our-hospital-speciality-centre-hero-area {
        background-size: cover;

        .container {
            height: 200px;

            #our-speciality-centre-title h1 {
                color: black;
                font-size: 25px !important;
                font-weight: 700;
                width: 169px;
                margin-left: 30px;
            }
        }
    }
    
    
    .eye-specialist-area{
        display: none;
    }

    .eye-specialist-service-card .card-title p {
        display: none;
    }

    .eye-specialist-area-mv {
        padding-top: 10px;
    }

    .eye-specialist-service-card .card-title-mv {
        display: flex;
        justify-content: space-between;
    }

    .eye-specialist-service-card .card-title-mv p {
        font-size: 21px;
        font-weight: 400;
        color: #003c50;
        margin-bottom: 0;
    }


}