.banner{
  position: relative;
  .container{
    height: 390px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    .main_title{
      color: #000
    }
  }
}

@media (max-width: 1024px) {
  .banner .container {
    height: 286px;
  }
} 

@media (max-width: 768px) {
  .banner .container {
    height: 214px;
  }
} 

@media (max-width: 425px) {
  .banner .container {
    height: 119px;
  }
} 