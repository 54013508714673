:root {
  --primary-color: #967F65;
  --second-color: #eaedf8;
  --third-color: #D5C8B9;
  --fourth-color: #f37574;
  --fifth-color: #f5f5f5;
  --bgm-color: #f3f5f7;
  --font-size-large: 24px;
  --active-button: #fbf3de;
  --button: #D5C8B9;
}

body {
  font-family: 'Flexo';
}

.MuiDialogContent-root {
  overflow: hidden;
}
.send_code {
  color: #39B5B2 !important;
  text-decoration: underline !important;
}

.ipc_application_form {
  .css-tj5bde-Svg,
  .css-8mmkcg {
    color: black;
  }
}
.gray-button {
  background-color: gray;
  color: white;
}

i.fas.fa-eye,
i.fas.fa-eye-slash {
  color: var(--primary-color);
  font-size: 18px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust this if needed */
  width: 100%;
}

div:where(.swal2-container).swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  border-radius: 32px;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: 15px;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em;
}

a:focus,
a:hover {
  color: var(--primary-color) !important;
  text-decoration: none;
}

.sticky_menu {
  z-index: 10;
}

.web {
  display: block;
}

.mob {
  display: none;
}

.button-custom {
  padding: 10px 80px !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  margin-top: 40px !important;
}
.swal2-container {
  z-index: 9999;
}
.header-profile {
  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid white;
  }

  .fa-angle-down {
    &:before {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      padding: 1px 3px 1px 5px;
      background: #6783d5;
    }
  }
  svg {
    color: #626262;
  }
  .header-profile-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    color: white;
    border-radius: 5px;
    position: absolute;
    width: 150px;
    left: -26%;
    opacity: 1;
    border-top: 3px solid;
    transition: 0.4s;
    z-index: 999;
    background: #fff;
    padding-bottom: 5px;
    padding-top: 4px;
    top: 152%;
    li {
      display: block;
      margin-bottom: 0;
      padding: 5px 10px;
      cursor: pointer;

      span {
        color: #626262;

        &:hover {
          color: black;
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 25%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent white transparent;
    }
  }
}

.bottom-application {
  button {
    margin: 5px;
    color: #000000;
  }
}

.application-second-button {
  background-color: #D5C8B9 !important;
}

.application-primary-button {
  background-color: var(--primary-color) !important;
  color: #ddd !important;
}

.find-doctor-section {
  .appointment-section {
    position: relative !important;
    margin-top: 10%;
    top: 0% !important;
    a#tab4-tab {
      display: none;
    }
    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
      display: none;
    }
    a#tab1-tab {
      display: none;
    }
    .tab-content {
      border-radius: 2rem;
    }
  }
}

.TeleconsultPopupContainer {
  button {
    background: #899dd8;
    height: 100%;
    position: relative;
    top: 1px;
    border: 0;
    border-radius: 0;
    left: -1px;
    box-shadow: none;
  }
}
.MuiButton-root {
  min-width: 250px;
}
.MuiButton-containedPrimary:hover {
  background-color: #899dd8 !important;
}

.loginregister-popup-section {
  .MuiDialog-paperWidthSm {
    width: 70%;
    height: fit-content;
    max-width: none;
    border-radius: 15px;
  }
  .two-columns-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .first-coloumn {
    margin: auto;
    width: 80%;

    .first-coloumn-title {
      font-size: 38px;
      font-weight: 700;
    }
    img {
      width: 30px;
    }
    .social-media-group {
      margin-top: 30px;

      div {
        border: 1px solid gray;
        border-radius: 10px;
        padding: 10px;
        text-align: center;
        margin: 5px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .signin-or {
      text-align: center;
      letter-spacing: 1px;
      font-size: 20px;
      margin: 20px;
      color: gray;
    }

    .login-emailpassword-group > div {
      border: none;
      background: white;
      width: 100%;
      padding: 8px;
      margin: 5px;
      border-radius: 10px;
    }

    .login-emailpassword-group {
      input {
        border: none;
      }
      label {
        color: black;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .remember-me-group {
      margin-left: 5px;
      display: flex;
      margin-top: 14px;
      justify-content: space-between;
    }

    .forgot-password {
      span {
        color: var(--primary-color);
        text-decoration: underline;
        font-weight: 500;
      }
    }

    .passwordinput {
      display: flex;
      justify-content: space-between;
      position: relative;
      button {
        border: 0;
        background: none;
        position: absolute;
        transform: translateY(-50%);
        right: 10px;
        i {
          color: var(--primary-color);
          font-size: 18px;
        }
      }
    }
    .login-button {
      margin-top: 20px;
      margin-bottom: 5px;

      button {
        width: 100%;
        background-color: var(--primary-color);
        border-radius: 20px;
        color: white;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
      }
    }
    .login-des {
      text-align: center;
      color: gray;
      font-size: 13px;

      span {
        color: var(--primary-color);
        margin-left: 5px;
      }
    }
  }
  .second-coloumn {
    // background-size: contain;
    // background-position: center;
    // background-repeat: no-repeat;
  }
  .MuiDialogContent-root {
    background: #f2f4f6;
    padding: 0 !important;
    // overflow-y: hidden;
  }
}

.login-popup-section {

  // .MuiDialog-paperScrollPaper {
  //   display: flex;
  //   max-height: none;
  //   flex-direction: column;
  // }
  .MuiDialog-paperWidthSm {
    width: 70%;
    height: fit-content;
    max-width: none;
    border-radius: 15px;
  }
  .two-columns-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // height: 100%;
  }
  .first-coloumn {
    // padding: 50px;
    .first-coloumn-title {
      font-size: 2rem;
      font-weight: 700;
    }
    img {
      width: 30px;
    }
    .social-media-group {
      div {
        border: 1px solid #80808066;
        border-radius: 10px;
        padding: 10px;
        text-align: center;
        margin: 5px;
        font-size: 16px;
      }
    }
    .login-emailpassword-group {
      .textbox,
      .textbox-email,
      .textbox-password {
        border: none;
        background: white;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        margin: 5px;
      }
      input {
        border: none;
      }
      label {
        color: black;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .signin-or {
      text-align: center;
      margin: 30px;
    }
    .social-media-group {
      margin-top: 50px;
    }
    .passwordinput {
      .tooltip-container {
        position: relative;
      }

      .custom-tooltip {
        position: absolute;
        background-color: white;
        color: #333;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        z-index: 999;
        bottom: 100%; /* Position above the hover text */
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
      }

      .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: white transparent transparent transparent;
        top: 100%;
        left: 10%;
        transform: translateX(-50%);
      }

      display: flex;
      justify-content: space-between;
      position: relative;

      button {
        border: 0;
        background: none;
        position: absolute;
        transform: translateY(-50%);
        right: 10px;

        i {
          color: var(--primary-color);
          font-size: 18px;
        }
      }
    }
    .remember-me-group {
      display: flex;
      margin-top: 2%;
      justify-content: space-between;
    }
    .login-button {
      button {
        width: 100%;
        background-color: var(--primary-color);
        border-radius: 20px;
        color: white;
        // margin: 5% 0;
        border: 0;
        padding: 7px;
      }
    }
    .login-des {
      text-align: center;
      span {
        color: var(--primary-color);
        margin-left: 5px;
      }
      button {
        background: none;
        box-shadow: none;
      }
      .LoginRegisterPopupContainer {
        display: inline-block;
        cursor: pointer;
      }
      .fas {
        display: none;
      }
    }
    .forgot-password {
      span {
        color: var(--primary-color);
        text-decoration: underline;
      }
    }

    .type_phone_number {
      background-color: #fff;
      padding: 10px;
      margin: 5px;
      border-radius: 5px;
      width: 100%;

      select,
      input {
        background: transparent;
        border: 0;
      }

      .country_no {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      i.fas.fa-check {
        color: white;
        border-radius: 10px;
        background: #79dd79;
        padding: 3px;
        font-size: 10px;
      }
    }

    .otp_number {
      display: flex;

      #otp {
        width: 50px;
        height: 50px;
        margin: 5px;
        font-size: 14px;
        text-align: center;
        border: 0;
        border-radius: 5px;
      }
    }

    .phone_number_code {
      .resend_code {
        color: #39B5B2;
      }
      margin: 5px;

      span:last-child {
        color: #39B5B2;
      }
    }

    .phone_number_button {
      button {
        border-radius: 20px;
        border: 1px solid #39B5B2;
        background: #39B5B2;
        /* width: 100%; */
        padding: 1% 7%;
        color: white;
        margin: 4% 0;
      }
    }
  }
  .second-coloumn {
    // background-size: 100%;
    // background-position: center;
    // background-repeat: no-repeat;
  }
  .MuiDialogContent-root {
    background: #f2f4f6;
    padding: 0 !important;
    // overflow-y: scroll;
  }
}

.RegisterPopupContainer {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 25px;
  }
  h1 {
    font-size: 30px !important;
  }
  .header-menu .active-tab-container {
    display: none;
  }
}

@media screen and (min-width: 481px) {
  .header-menu .active_tab_btn {
    display: none;
  }
}

.active_tab_btn {
  border: 0;
  background: transparent;
  color: white;
}

.speciality-centre {
  @media screen and (max-width: 768px) {
    .flex-container {
      div {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    .speciality-centre-bg {
      height: 1500px;
      .flex-container {
        width: 100%;
      }
      .column-container.column-0,
      .column-container.column-1,
      .column-container.column-2 {
        width: 90%;
        margin: auto;
      }
      .column-container.column-1 {
        .learn_more_button {
          width: 50%;
        }
      }
    }
  }
}

.health-package-area {
  padding-top: 40px;
  .title-subtitle-box {
    text-align: center;
    .title {
      p {
        font-size: 39px;
        color: black;
        font-weight: bold;
      }
    }
    .subtitle {
      p {
        width: 64%;
        margin: 30px auto;
        line-height: 1.5;
        font-size: 20px;
      }
    }
  }
  .health-package-button-container {
    text-align: center;
    margin-bottom: 40px;
    font-size: 18px;

    // a {
    //   color: #5775d1 !important;
    //   font-weight: 700;
    // }
  }
  .product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  .product-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px;
    img {
      width: 100%;
      height: auto;
      border-radius: 20px;
    }
    .product-details {
      flex-grow: 1;
      h3 {
        margin: 10px 0;
      }
      p {
        font-weight: bold;
      }
    }
    .add-to-cart-button {
      background-color: white;
      color: #5675d0;
      padding: 10px 20px;
      border: 1px solid #5675d0;
      cursor: pointer;
      border-radius: 20px;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 768px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.AppointmentPopupContainer {
  .MuiButton-containedPrimary {
    background-color: #003C50 !important;
    height: 100%;
    position: relative;
    top: 1px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 16px;
  }
}

.appointment-popup-section {

  #formContainer {
    height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 24px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #967F65;
        border-radius: 30px;
        border: 10px solid white;
    }

  }
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .appointment-popup {
    .selection-dropdown select option {
      color: black;
    }

    .MuiTypography-h6 {
      font-size: 2rem;
      font-weight: 800;
    }
    .selection-dropdown-container {
      background: var(--second-color);
      width: 275px !important;
      margin: 5px;
      display: block;
      label {
        color: black;
      }
    }
  }

  .select_date_time {
    padding: 0 0 3% 0;
    border-bottom: 1px solid #9e9e9e;
  }
  
  .patients_details {
    .patients_details_input {
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      width: 47% !important;
      margin: 0 10px;
    }

    .patients_details_input_email {
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 0.5rem;
      width: 100% !important;
      margin: 0;
    }

    .patients_details_input_phone_number {
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 0.5rem;
      width: 100% !important;
      margin: 0;
      height: 100%;
    }
  }

  .MuiDialogContent-root:first-child {
    padding-top: 10px;
  }

  .css-e784if-MuiTypography-root {
    margin: 0;
    font-family: 'Flexo';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: black;
  }

  .css-1ghilo0-MuiStack-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    padding-top: 0px;
  }

  .css-1pduc5x-MuiStack-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    padding-top: 0px;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: 'Flexo';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding-right: 14px;
    height: 40px;
    overflow: hidden;
  }

  select {
    background: transparent;
    border-radius: 5px;
  }
  input {
    border: 0;
  }
  .MuiDialogActions-root {
    // padding-inline: 24px;

    button {
      justify-content: center;
      border-radius: 30px;
      width: 100%;
      padding: 0;
    }
    .make_an_appointment {
      background: var(--primary-color) !important;
      color: white !important;
    }
    button:first-child {
      border: 1px solid var(--second-color);
      background: var(--second-color);
    }
    button:last-child {
      border: 1px solid var(--third-color);
      background: var(--third-color);
    }
  }
  .MuiButton-textPrimary {
    color: black;
    font-weight: 700;
  }
  form {
    height: 100%;
    display: flex;
    width: 100%;
    fieldset {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }
  .confirm_appointment_details {
    .row {
      padding: 3% 0;
    }
    
  }
  .swal2-container {
    .swal2-center {
      .swal2-backdrop-show {
        z-index: 99999;
      }
    }
  }
}

.teleconsult-popup-section {
  font-family: 'Flexo' !important;
  .MuiButton-textPrimary {
    color: black;
    font-weight: 700;
    width: 100%;
    /* background: var(--primary-color); */
    margin: 1%;
    border-radius: 25px;
    padding: 20px;
    background: #f5f5f5;
  }
  .MuiTypography-h6 {
    font-size: 2rem;
    font-weight: 700;
    font-family: 'Flexo' !important;
  }
  #formContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .teleconsult-popup {
    .MuiTypography-h6 {
      font-size: 1rem;
      font-family: 'Flexo' !important;
    }
    .selection-dropdown-container {
      background: var(--second-color);
      width: 275px !important;
      margin: 5px;
      display: block;
      label {
        color: black;
        font-family: 'Flexo' !important;
      }
      //select {
      //        color: #7e7e7e;
      //    }
    }
  }
  .MuiDialog-paperWidthSm {
    max-width: 600px;
    min-width: 600px;
    padding: 10px;
    border-radius: 20px;
    min-height: 600px;
    max-height: 700px;
    height: 100%;
  }
  .MuiButton-textPrimary:hover {
    background-color: var(--primary-color);
    color: white;
  }
  h3 {
    font-size: 18px;
  }

  .select_date_time {
    padding: 3% 0;
    border-bottom: 1px solid #9e9e9e;
  }
  .patients_details {
    padding: 3% 0;
    .patients_details_input {
      div:first-child {
        background: #f5f5f5;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      div:last-child {
        background: #f5f5f5;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .row {
      padding: 3% 0;
    }
    label {
      padding: 6px 6px 0px 6px;
    }
    input {
      padding: 0px 6px 6px 6px;
      background: #f5f5f5;
    }
  }
  .selection-dropdown {
    background: var(--second-color);
    width: 275px !important;
    margin: 0;
    display: block;
    border-radius: 5px;
    padding: 6px;
  }
  select {
    background: var(--second-color);
    border-radius: 5px;
  }
  input {
    border: 0;
  }
  .MuiDialogActions-root {
    padding: 16px;
    button {
      justify-content: center;
      border-radius: 20px;
      width: 100%;
    }
    .make_an_appointment {
      background: var(--primary-color) !important;
      color: white !important;
    }
    button:first-child {
      border: 1px solid var(--second-color);
      background: var(--second-color);
    }
    button:last-child {
      border: 1px solid var(--third-color);
      background: var(--third-color);
    }
  }
  .MuiButton-textPrimary {
    color: black;
    font-weight: 700;
  }
  form {
    height: 100%;
    display: flex;
    width: 100%;
    fieldset {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }
  .confirm_appointment_details {
    .row {
      padding: 3% 0;
    }
    span {
      font-weight: 600;
    }
  }
  .swal2-container {
    .swal2-center {
      .swal2-backdrop-show {
        z-index: 99999;
      }
    }
  }
}

.login-emailpassword-group {
  .verificationComponent {
    .type_phone_number {
      div:first-child {
        background: white !important;
      }
      // div:last-child {
      //   background: white !important;
      // }
      input {
        background: white !important;
      }
      .css-1xc3v61-indicatorContainer {
        padding: 0;
      }
      span {
        font-weight: 600;
      }

      .send_code {
        font-weight: 100 !important;
        font-size: 14px;
        text-decoration: underline;
        color: var(--primary-color);
      }
      .phone_number_button {
        input {
          width: 150px;
        }
      }
    }
    .phone_number_otp {
      input {
        margin: 4px;
        text-align: center;
      }
    }
  }
}

i.fas.fa-check {
  color: white;
  border-radius: 10px;
  background: #79dd79;
  padding: 3px;
  font-size: 10px;
}

.verificationComponent {
  .type_phone_number {
    div:first-child {
      background: #f5f5f5;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .css-myy8y4-control, .css-medpb7-control{
      background: #fff;
    }
    // div:last-child {
    //   background: #f5f5f5;
    //   border-bottom-left-radius: 5px;
    //   border-bottom-right-radius: 5px;
    // }
    input {
      padding: 0px 6px 6px 6px;
      background: #f5f5f5;
    }
    label {
      padding: 6px 6px 0px 6px;
    }
    .country_no {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px;
      background-color: white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    select {
      background: transparent;
      border: 0;
    }
  }
  .phone_number_otp {
    // padding: 3% 0;

    input {
      border: 1px solid #808080a8 !important;
      width: 50px;
      height: 50px;
      border-radius: 10px;
      background: transparent;
      margin: 3px;
      text-align: center;
    }

    div {
      display: inline-block;
      padding: 2%;
    }
  }
  .phone_number_button {
    button {
      border-radius: 20px;
      border: 1px solid #39B5B2;
      background: #39B5B2;
      /* width: 100%; */
      padding: 1% 7%;
      color: white;
      margin: 4% 0;
    }
  }
  .phone_number_code {
    span:last-child {
      color: var(--primary-color);
    }
  }
}

.MuiDialog-root {
  .headermenu-popup-secion {
    z-index: 99999999;
  }
}

.platinum-plus-group {
  background: white;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 50px 0;
  .title-subtitle-box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: flex-start;
    .title {
      p {
        font-size: 30px;
        color: black;
        font-weight: bold;
      }
    }
    .subtitle {
      width: 100%;
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      p {
        font-size: 15px;
      }
      .learn {
        p {
          color: #967F65 !important;
          font-weight: 800;
        }
      }
    }
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    gap: 10px;
    .grid-item {
      width: 100%;
      height: 180px;
      display: flex;
      border-radius: 20px;
      padding-bottom: 0;
      background-color: #ddd;
      align-items: center;
      justify-content: center;
      padding: 5%;
    }
    .img-include {
      background-image: url(./../img/blog/details/author.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .blank {
      background-color: white !important;
    }
  }
}

.caresApp-banner-group {
  position: relative;
  width: 100%;
  background: #f3f5f7;
  .banner {
    img {
      border-radius: 25px;
    }
    position: relative;
  }
  .text_button {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    span {
      font-size: 35px;
      font-weight: 900;
      color: black;
    }
    button {
      padding: 10px 50px;
      border-radius: 20px;
      background: black;
      color: white;
    }
  }
}

.screening-package-group {
  background: rgb(243, 245, 247);
  .container {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;
    .title-subtitle-box {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      flex-direction: column;
      align-items: center;
      .title {
        p {
          font-size: 30px;
          color: black;
          font-weight: bold;
        }
      }
      .subtitle {
        width: 39%;
        margin: 20px 0;
        p {
          font-size: 15px;
          text-align: center;
        }
      }
      .learn {
        p {
          color: #967F65 !important;
          font-weight: 800;
        }
      }
    }
    .bottom_tnc {
      text-align: center;
      padding: 20px 0;
    }
  }
  .screening-package-grid {
    .product-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
    .product-item {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 15px;
      background: white;
      border-radius: 20px;
      margin: 3%;
      align-items: center;
      img {
        width: 100%;
      }
      .product-details {
        flex-grow: 1;
        padding: 20px;
        h3 {
          margin: 10px 0;
        }
        p {
          font-weight: bold;
        }
        ul {
          li {
            list-style: unset;
            text-align: left;
          }
          ul {
            padding: revert;
            li {
              list-style: lower-alpha;
            }
          }
        }
      }
      .add-to-cart-button {
        background-color: white;
        color: #5675d0;
        padding: 10px 20px;
        border: 1px solid #5675d0;
        cursor: pointer;
        border-radius: 20px;
        font-weight: 700;
        width: 100%;
      }
    }
  }
}

.caresapp-text {
  //padding: 50px 0;
  font-size: 1rem;
  p {
    font-size: 1rem;
  }
}

.campaign-group {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .product-item {
      display: flex;
      padding: 10px;
      background: white;
      border-radius: 15px;
      img {
        border-radius: 10px;
      }
      .product-details {
        width: 100%;
        text-align: center;
        padding: 4% 3%;
        display: flex;
        flex-direction: column;
        h5 {
          font-size: 18px;
          font-weight: 600;
          color: black;
        }
        button {
          border: 0;
          background: transparent;
          color: var(--primary-color);
        }
        p {
          text-align: left;
          margin: 0;
          color: black;
        }
        .valid_date {
          height: 100%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }
  }
}

.pagination {
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .page-number {
      display: inline-block;
      background-color: white;
      border-radius: 10px;
      padding: 15px;
      margin: 50px;
      box-shadow: 0px 4px 10px 0px #B7BBCA5C;


      a {
        color: black;
        padding: 5px 8px;
        margin-right: 5px;

        &:hover {
          background-color: rgb(243, 245, 247);
          border-radius: 5px;
        }
      }

      a.active {
        background-color: #FCB11D;
        color: white;
        border-radius: 5px;
      }
    }
    .page-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      p {
        margin-right: 10px;
        font-size: 15px;
      }
      input {
        margin-top: -15px;
        margin-right: 10px;
        width: 60px;
      }
    }
  }
}

.individual-video-other-section {
  background-color: #ebedef;
  // padding: 50px 80px;
  .title {
    display: flex;
    justify-content: space-between;
    p {
      color: var(--primary-color);
      font-weight: 700;
    }
    // a {
    //   position: relative;
    //   right: 7%;
    // }
  }
}

.slide-with-dots {
  position: relative;
  .slider {
    display: flex;
    overflow: hidden;
    position: relative;
    .slide {
      display: none;
      flex-shrink: 0;
      width: 100%;
      transition: transform 0.3s ease-in-out;
      img {
        width: 100%;
        height: 300px;
      }
      &.active {
        display: block;
      }
    }
  }
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #919090;
      margin: 0 5px;
      cursor: pointer;
      &.active {
        height: 15px;
        width: 15px;
        background-color: #d0d0d0;
      }
    }
  }
}

.article-information-content-2 {
  background: white;
  padding: 35px;
  height: 100%;
  margin: 25px 0;
  .article-information-content-2-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .article-popular-content {
    li {
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
    }
    p.article_title {
      color: black;
    }
  }
  .doctor_profile {
    img {
      border-radius: 50%;
    }
  }
  .doctor_des {
    padding: 20px 0;
    button {
      border: 0;
      background: none;
      color: var(--primary-color);
      font-weight: 600;
    }
  }
}

.tab-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    background: white;
    margin: 10px;
    border-radius: 15px;
    padding: 10px;
  }
}

//mobile view
@media screen and (max-width: 768px) {
  h4 {
    font-size: 15px;
  }
  h3 {
    font-size: 18px;
  }

  .membertier-ranking-group {
    .container {
      .title-subtitle-box {
        .subtitle {
          width: 80%;
        }
        .title {
          p {
            font-size: 25px;
          }
        }
      }
    }
    .tierGridList {
      margin-top: 0px;
      .product-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 60px;
      }
      .product-item {
        .product-image {
          width: 120px;
          height: 100px;
        }
        h3 {
          font-size: 16px;
        }
      }
    }
  }
  .platinum-plus-group {
    .grid-container {
      grid-template-columns: repeat(3, 1fr);
      .grid-item {
        height: 80px;
        border-radius: 20px;
      }
    }
  }
  .caresApp-banner-group {
    .text_button {
      span {
        font-size: 20px;
        font-weight: 900;
        color: black;
      }
      button {
        padding: 5px 40px;
      }
    }
  }

  .screening-package-group {
    .screening-package-grid {
      .product-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
      }
      .product-item {
        img {
          width: 80%;
        }
        p {
          font-size: 10px;
        }
      }
    }
    .container {
      .title-subtitle-box {
        .subtitle {
          width: 80%;
        }
      }
    }
  }

  .campaign-group {
    .product-grid {
      grid-template-columns: repeat(1, 1fr);
      .product-item {
        img {
          width: 40%;
        }
      }
    }
  }
  .individual-campaignspromotions-information-section {
    padding: 50px 0;
  }
  
  .individual-video-information-section {
    .video-content-1 {
      padding-top: 4%;
    }
    .video-content-2 {
      button {
        margin-bottom: 10px;
      }
    }
  }
  .individual-health-package-other-section {
    padding: 40px;
    .title,
    .health-package-card-group {
      left: 0;
      overflow: auto !important;
    }
  }
  
  .individual-video-other-section {
    padding: 40px;
    .title,
    .video-other-group {
      left: 0;
      overflow: auto !important;
    }
  }

  .individual-events-information-section {
    padding-top: 20px;
  }

  .appointment-section {
    .tab-content {
      border-radius: 0;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      background-color: #0d6efd;
    }
  }
}

 //make appointment mobile

 @media screen and (min-width: 481px) {
  .loginregister-popup-section .login_mob_view {
    display: none !important;
  }

  .login-popup-section .login_mob_view {
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .loginregister-popup-section .login_web_view {
    display: none !important;
  }

  //mobile login screen
  .loginregister-popup-section {
    .login_web_view {
      display: none;
    }

    .MuiDialog-paperWidthSm {
      width: 100%;
      height: 100%;
      max-width: none;
      border-radius: 0;
    }

    .MuiDialog-paper {
      margin: 0;
      position: relative;
      overflow-y: auto;
    }

    .MuiDialog-paperScrollPaper {
      display: flex;
      max-height: none;
      flex-direction: column;
    }
  }

  //mobile register screen
  .login-popup-section {
    .MuiDialogContent-root {
      overflow-y: auto;
  }
    .login_web_view {
      display: none;
    }

    .MuiDialog-paperWidthSm {
      width: 100%;
      height: 100%;
      max-width: none;
      border-radius: 0;
    }

    .MuiDialog-paper {
      margin: 0;
      position: relative;
      overflow-y: auto;
    }

    .MuiDialog-paperScrollPaper {
      display: flex;
      max-height: none;
      flex-direction: column;
    }
  }

  .login-emailpassword-group
    .verificationComponent
    .type_phone_number
    div:first-child {
    background: white !important;
    text-align: left;
  }

  .verificationComponent {
    .phone_number_otp {
      padding: 5% 0;
      text-align: left;
    }

    .phone_number_code,
    .phone_number_button {
      text-align: left;
    }
  }

  //forgot password mobile
  .forgot_password_modal .container {
    width: 100%;
  }
 
}

@media screen and (max-width: 425px) {
  .web {
    display: none;
  }
  .mob {
    display: block;
  }
  .membertier-ranking-group {
    .tierGridList {
      margin-top: 0px;
      .product-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 60px;
      }
      .product-item {
        .product-image {
          width: 120px;
          height: 100px;
        }
        h3 {
          font-size: 16px;
        }
      }
    }
  }
  .platinum-plus-group {
    .title-subtitle-box {
      .subtitle {
        width: 100%;
        margin: 20px 0;
      }
    }
  }
  .add-to-cart-button {
    padding: 5px 10px;
  }
  .product-item {
    padding: 10px;
    h3 {
      margin: 10px 0;
      font-size: 12px;
    }
  }
  .text_button {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    span {
      font-size: 35px;
      font-weight: 900;
      color: black;
    }
    button {
      padding: 10px 50px;
      border-radius: 20px;
      background: black;
      color: white;
    }
  }

  
  .individual-doctor-other-section {
    padding: 20px;
  }
  .health-package-card-group,
  .video-other-group,
  .doctor-group {
    overflow: auto !important;
  }
  .individual-podcast-information-section {
    .podcast-content-2 {
      button {
        background: var(--button);
        padding: 5px 20px;
        border-radius: 20px;
        border: 0;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }

  .teleconsult-popup-section {
    .MuiDialog-paperWidthSm {
      min-width: 100%;

      .appointment-popup-section .MuiDialog-paperWidthSm {
        max-width: none;
        min-width: 50%;
        padding: 10px;
        border-radius: 0;
        min-height: 100%;
        max-height: 100%;
      }

      .appointment-popup-section {
        .selection-dropdown {
          width: 100% !important;
          margin: 10px 0;
        }
      }
    }
    .appointment-popup-section {
      .MuiDialog-paperWidthSm {
        min-width: 100% !important;
        height: fit-content;
        overflow-y: scroll !important;
      }
    }
    .appointment-popup-section {
      .selection-dropdown {
        width: 100% !important;
        margin: 10px 0;
      }
    }
    .patients_details_input {
      margin: 10px 0;
    }
    .appointment-section {
      .nav-tabs {
        font-size: 20px;
        border-bottom: 0px solid transparent;
        text-align: center;
      }
    }

    .appointment-popup-section .selection-dropdown-date-time {
      background: #f7f9ff;
      width: 100% !important;
      margin: 0;
      display: block;
      border-radius: 5px;
      padding: 10px;
    } 

    .dialog-slide-in {
      animation: slide-in 0.5s forwards;
    }

    @keyframes slide-in {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }

    .dialog-slide-out {
      animation: slide-out 0.5s forwards;
    }

    @keyframes slide-out {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-100%);
      }
    }
  }
}

//slider dots
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #967F65;
}

// swal custom css
.swal2-popup{
  font-family: "Flexo";
}
.swal2-actions{
  width: 80%;
  button{
    background-color: #967F65 !important;
    width: 100%;
  }
}