.thank_you_modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .container{
        padding: 30px 50px;
        background-color: white;
        border-radius: 20px;
        width: 350px;
        text-align: center;
        display: flex;
        flex-direction: column;

    }
}