@media (max-width: 480px) {
    .consultant-mobile {
        display: flex;
        flex-direction: row;

        @media (max-width: 480px) {
            .career_form_web {
                display: flex !important;
                flex-direction: column-reverse;

                .form-container {
                    width: 100%;
                }

                .col-3 {
                    height: 300px;
                    width: 100%;
                }
            }
        }
    }
}
