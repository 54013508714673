.caresKPJ-banner-group {
    background-size: cover;
    height: 660px;
    background-position: center;
    background-repeat: no-repeat;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: inherit;

        .card-box{
            display: flex;
            column-gap: 20px;
            margin-top: 30px;

            .individual-card{
                background-color: white;
                width: 335px;
                height: 170px;
                border-radius: 20px;
                padding: 35px;
                box-shadow: 4px 0px 16px 1px rgba(183, 188, 202, 0.36);

                p{
                    margin-bottom: 0px;
                    font-weight: 600;
                    font-size: 18px;
                    margin-top: 15px;
                    line-height: 1.2;
                    color: black;
                }
            }
        }
    } 
}

.individual-card:hover {
    border: 0.95px solid #967F65;
    padding-inline: 50px !important;
}

.membertier-ranking-group {
    background: rgb(243, 245, 247);

    .container {
        display: flex;
        flex-direction: column;
        padding-top: 60px;
        padding-bottom: 60px;
        align-items: center;

        .title-subtitle-box {
            display: flex;
            justify-content: space-between;
            padding-bottom: 30px;
            flex-direction: column;
            align-items: center;

            .title {
                p {
                    font-size: 30px;
                    color: black;
                    font-weight: bold;
                }
            }

            .subtitle {
                text-align: center;
                max-width: 650px;
                margin-top: 30px;
                margin-bottom: 60px;

                p {
                    line-height: 1.5;
                }
            }
        }

        .grid-container-cares{
            display: grid;
            grid-template-columns: repeat(4, 1fr); 
            grid-gap: 20px;
        }

        .loyalty-app-banner{
            // padding: 70px;
            border-radius: 30px;
            margin-top: 60px;
            display: flex;
            justify-content: flex-end;
            width: 80vw;
            flex-wrap: wrap;
            align-content: center;

            h2{
                color: black;
                max-width: 560px;
            }

            button{
                border: 0;
                padding: 10px 20px;
                background: black;
                border-radius: 30px;
                width: 180px;
                font-weight: 600;
                font-size: 16px;
                margin-top: 20px;
            }
        }

        .kpj-cares-benefits-container{
            margin-top: 130px;
            position: relative;
            width: 85vw;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .content-container{
                // position: absolute;
                // top: 250px;
                // right: 0;
                max-width: 375px;
                margin-inline: 10px;

                h2{
                    color: black !important;
                }

                p{
                    margin-top: 30px;
                    line-height: 1.5;
                    text-align: justify;
                }
            }
        }
    }

    .tierGridList {
        margin-top: 100px;
        .product-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
        .product-item:last-child {
            .product-details {
                h3 {
                    font-size: 18px;
                }
            }
        }
        .product-item {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 15px;
            background: white;
            border-radius: 20px;
            margin: 3%;
            align-items: center;
            .product-image {
                position: relative;
                width: 230px;
                height: 170px;
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 20px;
                    position: absolute;
                    top: -50%;
                    left: 0;
                }
            }
            .product-details {
                flex-grow: 1;
                padding: 20px;
                h3 {
                    margin: 10px 0;
                }
                p {
                    font-weight: bold;
                }
                ul {
                    li {
                        list-style: unset;
                        text-align: left;
                    }
                    ul {
                        padding: revert;
                        li {
                            list-style: lower-alpha;
                        }
                    }
                }
            }
            .add-to-cart-button {
                background-color: white;
                color: #5675d0;
                padding: 10px 20px;
                border: 1px solid #5675d0;
                cursor: pointer;
                border-radius: 20px;
                font-weight: 700;
            }
        }
    }
}

.membership-tier-ranking-page{
    .page-header{
        height: 40vh;
        background-size: cover;

        .container{
            display: flex;
            flex-direction: column;
            height: inherit;
            justify-content: center;
            padding: 0;

            h1{
                font-size: 36px;
                color: black;
            }
        }
    }

    .page-content{
        background: rgb(243, 245, 247);

        .container{
            padding: 70px 0 ;

            .accordion-item {
                margin-bottom: 5px;
                background-color: transparent;
                color: white;
                border: 0px;
                border-bottom: 1px solid black;
            }
        }
    }
}

.platinum-plus-group {
    background: white;
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 50px 0;
    .title-subtitle-box {
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        flex-direction: column;
        align-items: flex-start;
        .title {
            p {
                font-size: 30px;
                color: black;
                font-weight: bold;
            }
        }
        .subtitle {
            width: 100%;
            margin: 20px 0;
            display: flex;
            justify-content: space-between;
            p {
                font-size: 15px;
            }
            .learn {
                p {
                    color: #967F65 !important;
                    font-weight: 800;
                }
            }
        }
    }
    .grid-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 10px;
        gap: 10px;
        .grid-item {
            width: 100%;
            height: 180px;
            display: flex;
            border-radius: 20px;
            padding-bottom: 0;
            background-color: #ddd;
            align-items: center;
            justify-content: center;
            padding: 5%;
        }
        .img-include {
            background-image: url(./../img/blog/details/author.png);
            background-repeat: no-repeat;
            background-size: cover;
        }
        .blank {
            background-color: white !important;
        }
    }
}

.caresApp-banner-group {
    position: relative;
    width: 100%;
    height: 300px;
    background: white;
    .banner {
        img {
            border-radius: 25px;
        }
        position: relative;
    }
    .text_button {
        display: flex;
        position: absolute;
        flex-direction: column;
        top: 50%;
        left: 60%;
        transform: translate(-50%, -50%);
        span {
            font-size: 35px;
            font-weight: 900;
            color: black;
        }
        button {
            padding: 10px 50px;
            border-radius: 20px;
            background: black;
            color: white;
        }
    }
}

.care-applicationform-section {
    padding: 70px 0;
    background: #f3f5f7;

    .two-columns-grid {
        display: flex;
        flex-direction: row;
        background: white;
    }
    .first-coloumn {
        padding: 40px;

        input {
            border: none;
            background: #F5F5F5;
            padding: 0 8px;
            padding-bottom: 8px;
            width: 100%;
        }

        .first-coloumn-title {
            font-size: 25px;
            font-weight: 600;
            color: black;
            margin-bottom: 20px;
        }

        img {
            width: 30px
        }

        .application_details {
            padding-top: 10px;
            padding-bottom: 20px;

            .col-xl-6>div {
                background: #F5F5F5;
            }
            label {
                color: black;
                font-size: 12px;
                padding: 0px 8px;
                padding-top: 8px;
            }
        }

        .operation-hr {
            padding: 20px 0;
        }

        .message {
            textarea {
                width: 100%
            }
            label {
                color: black;
                padding-bottom: 5px;
            }
        }

        .form_content {
            display: flex;
            flex-direction: column;
            height: 100%;

            .content_des .des p{
                line-height: 1.5;
                color: rgb(96, 96, 96);
                font-size: 15px;
                text-align: justify;
            }
        }
    }


    .MuiDialogContent-root {
        background: #F2F4F6;
        padding: 0 !important;
    }
}
.screening-package-group {
    background: rgb(243, 245, 247);
    .container {
        display: flex;
        flex-direction: column;
        padding-top: 60px;
        padding-bottom: 60px;
        .title-subtitle-box {
            display: flex;
            justify-content: space-between;
            padding-bottom: 30px;
            flex-direction: column;
            align-items: center;
            .title {
                p {
                    font-size: 30px;
                    color: black;
                    font-weight: bold;
                }
            }
            .subtitle {
                width: 39%;
                margin: 20px 0;
                p {
                    font-size: 15px;
                    text-align: center;
                }
            }
            .learn {
                p {
                    color: #967F65 !important;
                    font-weight: 800;
                }
            }
        }
        .bottom_tnc {
            text-align: center;
            padding: 20px 0;
        }
    }
    .screening-package-grid {
        .product-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
        .product-item {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 15px;
            background: white;
            border-radius: 20px;
            margin: 3%;
            align-items: center;
            img {
                width: 100%;
            }
            .product-details {
                flex-grow: 1;
                padding: 20px;
                h3 {
                    margin: 10px 0;
                }
                p {
                    font-weight: bold;
                }
                ul {
                    li {
                        list-style: unset;
                        text-align: left;
                    }
                    ul {
                        padding: revert;
                        li {
                            list-style: lower-alpha;
                        }
                    }
                }
            }
            .add-to-cart-button {
                background-color: white;
                color: #5675d0;
                padding: 10px 20px;
                border: 1px solid #5675d0;
                cursor: pointer;
                border-radius: 20px;
                font-weight: 700;
                width: 100%;
            }
        }
    }
}

.caresApp-bottom-group {
    display: flex;
    justify-content: flex-start;

    .TnC {
        display: flex;
        position: relative;
        background-repeat: no-repeat;
        width: 50%;
        align-items: center;
        justify-content: center;

        a {
            font-size: 20px;
            font-weight: 700;
            z-index: 1;
            display: flex;
            align-items: center;
            height: 70px;
        }

        .overlay {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #FFFFFFBD;
            opacity: 0.74; 
            transition: background 0.3s; 
        }
    }

    .TnC:hover .overlay {
        background: #967F6580; 
    }
    
    .TnC:hover a {
        color: white; 
    }      


    .FAQ {
        display: flex;
        position: relative;
        background-repeat: no-repeat;
        width: 50%;
        align-items: center;
        justify-content: center;

        a {
            font-size: 20px;
            font-weight: 700;
            z-index: 1;
            display: flex;
            align-items: center;
            height: 70px;

        }

        .overlay {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #FFFFFFBD;
            opacity: 0.74; 
            transition: background 0.3s; 
        }
    }

    .FAQ:hover .overlay {
        background: #967F6580; 
    }
    
    .FAQ:hover a {
        color: white; 
    }

}

.accordion-item-cares {
    margin-bottom: 5px;
    background-color: transparent;
    color: white;
    border: 0px;
    border-bottom: 1px solid black;


    .accordion-header {
        display: flex;
        width: 100%;
        padding: 10px;
        text-align: left;
        cursor: pointer;

        span.btn-container{
            width: 30px;
            img{
                width: 15px;
                height: 15px;
            }
        }
        p {
            font-size: 20px;
            font-weight: 700;
            margin-left: 10px;
            margin-bottom: 0;
            color: #ffffff;
        }
    }

    .accordion-content {
        background-color: transparent;
        color: white;
        padding: 10px;

        p{
            color: #606060;
            margin-left: 14px;
            font-size: 15px;
        }

        a {
            color: white;
        }
    }
}

.caresKPJ-banner-group {
    position: relative;
    width: 100%;
    .button-img {
        position: absolute;
        width: 400px;
        bottom: 0;
        left: 5%;
        border: 1px solid black;
        height: 100px;
        //background-image: url('./../img/team/member3.png');
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: left;
        background-color: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        .hospital_link {
            float: right;
            width: 65%;
            background: black;
            height: 100%;
            color: white;
            font-weight: 600;
            display: flex;
            font-size: 19px;
            align-items: center;
            justify-content: center;
            transition: transform 0.8s ease;
            cursor: pointer;
        }
        .hospital_link:hover {
            transform: translateX(7%);
        }
    }
}

.caresapp-banner-group {
    height: 237px;
    background-size: 100% !important;
    background-repeat: no-repeat !important;

    .container {
        display: flex;
        flex-direction: column;
        height: inherit;
        justify-content: center;

        h2 {
            color: black;
        }

        h3 {
            color: #5775D3;
        }
    }
}

.caresapp-image-group {
    height: 600px;
    background-size: cover !important;
    background-position: center !important;
    margin: 40px;
}

@media (max-width: 1280px) {
    .caresKPJ-banner-group {
        height: 600px;
    }
}

@media (max-width: 1024px) {
    .caresKPJ-banner-group {
        height: 480px;
    }

    .membertier-ranking-group .container .kpj-cares-benefits-container .content-container {
        top: 110px;
        width: 280px;
    }

    .caresapp-banner-group {
        height: 20vh;
        background-size: cover !important;
    }

    .caresapp-image-group {
        height: 600px;
        background-size: cover !important;
        background-position: center !important;
        margin: 40px;
    }
}

@media screen and (max-width: 768px) {

    .caresKPJ-banner-group {
        background-size: cover;

        .button-img {
            width: 150px;
            height: 40px;
            .hospital_link {
                font-size: 10px;
            }
        }
    }

    .caresKPJ-banner-group .container .card-box .individual-card {
        padding: 20px;
    }

    .membertier-ranking-group .container .kpj-cares-benefits-container .content-container {
        top: 60px;
        width: 200px;
    }

    .screening-package-group {
        .screening-package-grid {
            .product-grid {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 10px;
            }
            .product-item {
                img {
                    width: 80%;
                }
                p {
                    font-size: 10px;
                }
            }
        }
        .container {
            .title-subtitle-box {
                .subtitle {
                    width: 80%;
                }
            }
        }
    }

    .membertier-ranking-group {
        .container {
            .title-subtitle-box {
                .subtitle {
                    width: 80%;
                }
                .title {
                    p {
                        font-size: 25px;
                    }
                }
            }
        }
        .tierGridList {
            margin-top: 0px;
            .product-grid {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 60px;
            }
            .product-item {
                .product-image {
                    width: 120px;
                    height: 100px;
                }
                h3 {
                    font-size: 16px;
                }
            }
        }
    }
    .platinum-plus-group {
        .grid-container {
            grid-template-columns: repeat(3, 1fr);
            .grid-item {
                height: 80px;
                border-radius: 20px;
            }
        }
    }
    .caresApp-banner-group {
        .text_button {
            span {
                font-size: 20px;
                font-weight: 900;
                color: black;
            }
            button {
                padding: 5px 40px;
            }
        }
    }
    .care-applicationform-section {
        .second-coloumn {
            display: block;
        }
        .two-columns-grid {
            grid-template-columns: 100%;
        }
    }

    .caresApp-bottom-group {
        display: flex;
        flex-direction: column;

         .FAQ {
           
            width: 100%;
        }

       .TnC {
      
            width: 100%;
        }

        .caresApp-bottom-group .TnC a {
            left: 50%;
        }
    }

    .bottom-application {
        margin-bottom: 30px;
        text-align: center;
    }

    .membership-tier-ranking-page .page-header {
        background-size: cover;
    }
}


@media screen and (max-width: 425px) {
    .web {
        display: none!important;
    }
    .mob {
        display: block!important;
    }
}

@media (min-width: 481px) {
    .care-applicationform-section .kpj_cares_form_mob_view {
        display: none!important;
    }

    .membertier-ranking-group .loyalty_mob_view {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .care-applicationform-section .kpj_cares_form_web_view {
        display: none!important;
    }

    .membertier-ranking-group .loyalty_web_view {
        display: none !important;
    }

    .caresKPJ-banner-group {
        background-size: 100%;
        height: 465px;
        background-position: center;
        background-repeat: no-repeat;

        .container {
            align-items: center;
            justify-content: normal;
            padding-top: 40px;

            h1{
                font-family: 'Flexo';
                font-size: 32px;
                font-weight: 700;
                line-height: 37px;
                letter-spacing: 0em;
                text-align: center;
            }

            p{
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;
                color: #606060 !important;
                width: 326px !important;
            }

            .card-box {
                margin-top: 35%;
                position: relative;
                padding: 10px;
                left: -2%;

                overflow-x: auto;
                overflow-y: hidden;
                -ms-overflow-style: none; 
                width: 100%;

                &::-webkit-scrollbar {
                    display: none;
                }

                .individual-card {
                    background-color: white;
                    width: 213px;
                    height: 103px;
                    border-radius: 20px;
                    padding: 20px;
                    box-shadow: 1.9715622663497925px 0px 7.88624906539917px 0.4928905665874481px #B7BBCA5C;
    
                    img{
                        width: 24px !important;
                    }

                    p {
                        font-family: 'Flexo' !important;
                        font-size: 11px !important;
                        font-weight: 700 !important;
                        line-height: 14px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #000 !important;
                        width: 128px !important;
                        margin-top: 0 !important;
                    }
                }

            }
            
        }
    }

    .membertier-ranking-group {
        .container {
            .title-subtitle-box {
                .title {
                    h2 {
                        color: #070707 !important;
                        font-size: 25px !important;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
                .subtitle {
                    p {
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: center;
                    }
                }
            }

            .grid-container-cares {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 0px;
            }

            .loyalty-app-banner {
                border-radius: 30px;
                margin-top: 60px;
                display: flex;
                justify-content: flex-end;
                width: -webkit-fill-available;
                flex-wrap: wrap;
                align-content: center;
                background-size: cover !important;

                .lab_mobile{
                    padding-right: 20px !important;
                }

                h2 {
                    color: black;
                    font-size: 17px !important;
                    font-weight: 700;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;
                    width: 151px !important;

                }

                button {
                    border: 0;
                    padding: 4px 2px;
                    background: black;
                    border-radius: 30px;
                    width: 120px;
                    font-weight: 600;
                    font-size: 13px;
                    margin-top: 20px;
                }
            }

            .kpj-cares-benefits-container {
                width: 100vw !important;
                display: flex;
                flex-direction: column-reverse;
                margin-top: 60px;

                img {
                    width: 100% !important;
                    padding: 10px;
                }

                .content-container {
                    position: unset;
                    width: unset !important;
    
                    h2 {
                        color: #070707 !important;
                        font-family: 'Flexo';
                        font-size: 25px !important;
                        font-weight: 700;
                        line-height: 29px;
                        letter-spacing: 0em;
                        text-align: center;
                    }
    
                    p {
                        font-size: 13px !important;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #606060;
                        padding: 0 10px;
    
                    }
    
                }
            }    
        }
    }

    .membership-tier-ranking-page {
        .page-header {
            height: 200px;
            background-size: cover;
            // background-position: center;
            background-position-x: 62.5%;
    
            .container {
                padding-left: 12px;
                padding-right: 12px;
                h1 {
                    font-size: 25px !important;
                    font-weight: 700;
                    line-height: 29px;
                    color: black;
                    width: 152px;
                }
            }
        }

        .page-content {
            .container {
                padding: 40px 0;

                p {
                    font-size: 13px !important;
                    font-weight: 400;
                    line-height: 16px;
                    padding: 0 24px;
                }
            }
        }

    } 

    .caresapp-banner-group {
        height: 200px;
        background-size: cover !important;
        // background-position: center !important;
        background-position-x: 62.5% !important;
        .container {
            display: flex;
            flex-direction: column;
            height: inherit;
            justify-content: center;
        }
    }

    .care-applicationform-section {
        padding: 0 !important;

        .container {
            width: 100% !important;
            padding: 0;
        }

        .two-columns-grid {
            height: fit-content !important;
        }

        .second-coloumn {
            display: block;
        }

        .first-coloumn {
            padding: 27px;

            .first-coloumn-title {
                font-size: 21px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                color: #070707;

            }

            .form_content {
                .content_des {
                    .des {
                        p {
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 18px !important;
                            letter-spacing: 0em;
                            color: #606060 !important;
                            width: auto !important;
                        }
                    }
                }

                .bottom-application {
                    margin-bottom: 30px;
                    text-align: left;
                }
            }
        }
    }

    .caresApp-bottom-group {
        display: flex;
        flex-direction: column;
        background-color: #F3F5F7;

        .TnC a {
            font-size: 15px;
            font-weight: 700;
            color: black;
            position: relative;
            display: flex;
            align-items: center;
            min-height: inherit;
            padding: 10px;
            top: 0;
            left: 0;
            transform: unset;
        }

        .FAQ a {
            font-size: 15px;
            font-weight: 700;
            color: black;
            position: relative;
            display: flex;
            align-items: center;
            min-height: inherit;
            padding: 10px;
            top: 0;
            left: 0;
            transform: unset;
        }
    }

    .caresapp-image-group {
        height: 200px;
        background-size: cover !important;
        background-position: center !important;
        margin: 10px;
    }
}